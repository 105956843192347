export const colors = {
  primary: {
    50: "hsla(256, 100%, 98%, 1)",
    100: "hsla(255, 100%, 95%, 1)",
    200: "hsla(255, 100%, 92%, 1)",
    300: "hsla(252, 100%, 84%, 1)",
    400: "hsla(250, 100%, 76%, 1)",
    500: "hsla(248, 100%, 68%, 1)",
    600: "hsla(246, 66%, 56%, 1)",
    700: "hsla(244, 59%, 44%, 1)",
    800: "hsla(242, 66%, 33%, 1)",
    900: "hsla(240, 67%, 24%, 1)",
  },
  success: {
    50: "hsla(138, 76%, 97%, 1)",
    100: "hsla(141, 84%, 93%, 1)",
    200: "hsla(141, 79%, 85%, 1)",
    300: "hsla(142, 77%, 73%, 1)",
    400: "hsla(142, 69%, 58%, 1)",
    500: "hsla(142, 71%, 45%, 1)",
    600: "hsla(142, 76%, 36%, 1)",
    700: "hsla(142, 72%, 29%, 1)",
    800: "hsla(143, 64%, 24%, 1)",
    900: "hsla(144, 61%, 20%, 1)",
  },
  warning: {
    50: "hsla(48, 100%, 96%, 1)",
    100: "hsla(48, 96%, 89%, 1)",
    200: "hsla(48, 97%, 77%, 1)",
    300: "hsla(46, 97%, 65%, 1)",
    400: "hsla(43, 96%, 56%, 1)",
    500: "hsla(38, 92%, 50%, 1)",
    600: "hsla(32, 95%, 44%, 1)",
    700: "hsla(26, 90%, 37%, 1)",
    800: "hsla(23, 82%, 31%, 1)",
    900: "hsla(22, 78%, 26%, 1)",
  },
  destructive: {
    50: "hsla(0, 86%, 97%, 1)",
    100: "hsla(0, 93%, 94%, 1)",
    200: "hsla(0, 96%, 89%, 1)",
    300: "hsla(0, 94%, 82%, 1)",
    400: "hsla(0, 91%, 71%, 1)",
    500: "hsla(0, 84%, 60%, 1)",
    600: "hsla(0, 72%, 51%, 1)",
    700: "hsla(0, 74%, 42%, 1)",
    800: "hsla(0, 70%, 35%, 1)",
    900: "hsla(0, 63%, 31%, 1)",
  },
  neutral: {
    50: "hsla(210, 20%, 98%, 1)",
    100: "hsla(220, 14%, 96%, 1)",
    200: "hsla(220, 13%, 91%, 1)",
    300: "hsla(216, 12%, 84%, 1)",
    400: "hsla(218, 11%, 65%, 1)",
    500: "hsla(220, 9%, 46%, 1)",
    600: "hsla(215, 14%, 34%, 1)",
    700: "hsla(217, 19%, 27%, 1)",
    800: "hsla(215, 28%, 17%, 1)",
    900: "hsla(221, 39%, 11%, 1)",
  },
  yellow: {
    50: "#FFFFF0",
    100: "#FEFCBF",
    200: "#FAF089",
    300: "#F6E05E",
    400: "#ECC94B",
    500: "#D69E2E",
    600: "#B7791F",
    700: "#975A16",
    800: "#744210",
    900: "#5F370E",
  },
  purple: {
    50: "#F4F0FF",
    100: "#EBE5FF",
    200: "#E0D6FF",
    300: "#BEADFF",
    400: "#9985FF",
    500: "#725BFF",
    600: "#5243D9",
    700: "#372EB3",
    800: "#141466",
    900: "#0E103C",
  },
  red: {
    50: "#FFF0F0",
    100: "#FFE6E7",
    200: "#FFBDC2",
    300: "#FF94A0",
    400: "#FF6B81",
    500: "#F44061",
    600: "#CF2B4F",
    700: "#A81B3E",
    800: "#820E2F",
    900: "#5C0923",
  },
  teal: {
    50: "#D9FFFB",
    100: "#A3FFE8",
    200: "#7AFFE2",
    300: "#52FFDF",
    400: "#27F2D4",
    500: "#00E6CD",
    600: "#00BFAF",
    700: "#009991",
    800: "#007371",
    900: "#004B4D",
  },
  gray: {
    50: "#FCFCFD",
    100: "#F2F4F7",
    200: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
    bg: "#fafafa",
  },
  green: {
    50: "#F0FFF9",
    100: "#CFFCEB",
    200: "#9EF0D3",
    300: "#71E3BF",
    400: "#49D6AE",
    500: "#25C9A1",
    600: "#15A384",
    700: "#0A7D68",
    800: "#03574A",
    900: "#01302B",
  },
  external: {
    twitter: "#1DA1F2",
    twitterHover: "#158BD4",
  },
}
