import React from "react"
import { createIcon } from "@chakra-ui/icons"

const Check = createIcon({
  displayName: "Check",
  viewBox: "0 0 512 512",
  path: (
    <path
      d="M475.3 123.3l-272 272c-3.1 3.1-7.2 4.7-11.3 4.7s-8.188-1.562-11.31-4.688l-144-144c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L192 361.4l260.7-260.7c6.25-6.25 16.38-6.25 22.62 0s6.28 16.4-.02 22.6z"
      fill="currentColor"
    />
  ),
})

export default Check
