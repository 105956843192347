import type { FC, ReactNode } from "react"
import React, { useState, createContext, useEffect, useContext } from "react"

import { getSessionToken } from "common/helpers/cookies"

type SessionProviderValues = {
  isLoggedIn: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

// @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
const SessionContext = createContext<SessionProviderValues>({})

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    const sessionToken = getSessionToken()

    if (sessionToken) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  return (
    <SessionContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = (): SessionProviderValues => {
  const context = useContext(SessionContext)

  if (context == null) {
    throw new Error("useSession must be used within a SessionProvider")
  }

  return context
}
