import type { ReactElement } from "react"
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react"
import invariant from "tiny-invariant"
import { useToast } from "@chakra-ui/react"

import { Toast } from "common/hooks/useToast"
import MiningToast from "web3/components/toasts/MiningToast"
import { useTransaction } from "web3/providers/TransactionProvider"
import type {
  TransactionStatus,
  TransactionToastMessages,
} from "web3/types/transaction"
import { TransactionState } from "web3/types/transaction"
import type { MetamaskError } from "governance/types/metamask"
import { useModals } from "common/hooks/useModals"

const DEFAULT_OPTIONS = {
  descriptions: {
    [TransactionState.None]: undefined,
    [TransactionState.Fail]: undefined,
    [TransactionState.Mining]: "Please, wait while this action is processed",
    [TransactionState.Success]: undefined,
    [TransactionState.Exception]: undefined,
  },
  titles: {
    [TransactionState.None]: undefined,
    [TransactionState.Fail]: "Transaction failed",
    [TransactionState.Mining]: "Submitting transaction",
    [TransactionState.Success]: "Transaction succeeded",
    [TransactionState.Exception]: undefined,
  },
}

type Value = {
  composeMessages: (userOptions: Partial<TransactionToastMessages>) => void
}

const TransactionToastContext = createContext<Value>(
  // @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
  {},
)

export function TransactionToastProvider<Metadata>({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const { web3StatusModal } = useModals()
  const toastRef = useRef<string | number | undefined>()
  const [messages, setMessages] =
    useState<TransactionToastMessages>(DEFAULT_OPTIONS)

  const toast = useToast({
    duration: null,
    position: "bottom-right",
  })

  const { status } = useTransaction<Metadata>()

  const composeMessages = (nextMessages: Partial<TransactionToastMessages>) => {
    const getMessages = (
      defaultMessages: TransactionToastMessages,
      nextMessages: Partial<TransactionToastMessages>,
    ) => {
      const messages = {
        ...defaultMessages,
        ...nextMessages,
      }

      return messages
    }

    const messages = getMessages(DEFAULT_OPTIONS, nextMessages)

    setMessages(messages)
  }

  useEffect(() => {
    const renderToast = (children: ReactElement) => {
      toastRef.current = toast({
        render: () => <>{children}</>,
      })
    }

    const updateToast = (
      status: TransactionStatus<Metadata>,
      messages: TransactionToastMessages,
    ): void => {
      toast.closeAll()

      const { titles } = messages
      const { state } = status

      switch (state) {
        case TransactionState.Mining:
          renderToast(
            <MiningToast
              description={DEFAULT_OPTIONS.descriptions[state]}
              title={titles[state]}
            />,
          )

          invariant(
            status.state === TransactionState.Mining,
            "Transaction provider => state should be mining",
          )

          return
        case TransactionState.Success:
          toastRef.current = toast({
            duration: 5000,
            render: ({ onClose }) => (
              <Toast
                description={DEFAULT_OPTIONS.descriptions[state]}
                status="success"
                title={titles[state]}
                onClose={onClose}
              />
            ),
          })

          invariant(
            status.state === TransactionState.Success,
            "Transaction provider => state should be success",
          )

          setTimeout(() => {
            toast.closeAll()
          }, 3000)

          return
        case TransactionState.Fail:
          if (!status.error) return

          const getError = (error: string | MetamaskError): string => {
            if (error) {
              if (
                typeof error === "string" &&
                error.toLowerCase().includes("execution reverted:")
              ) {
                return `Reason: ${error
                  .replace("execution reverted:", "")
                  .trim()}`
              }

              if (typeof error !== "string") {
                return error?.message ?? ""
              }
            }

            return error
          }

          const description = getError(status.error)

          if (description.toLowerCase().includes("user rejected")) return

          toastRef.current = toast({
            duration: 5000,
            render: ({ onClose }) => (
              <Toast
                description={description}
                status="error"
                title={titles[state]}
                onClose={onClose}
              />
            ),
          })

          setTimeout(() => {
            toast.closeAll()
          }, 5000)

          return
        default:
          break
      }
    }

    // If Web3StatusModal is open, then don't display new toast
    if (web3StatusModal.isOpen) return

    updateToast(status, messages)
  }, [messages, status, toast, web3StatusModal.isOpen])

  return (
    <TransactionToastContext.Provider value={{ composeMessages }}>
      {children}
    </TransactionToastContext.Provider>
  )
}

export const useTransactionToast = ({
  messages,
}: {
  messages?: Partial<TransactionToastMessages>
} = {}): void => {
  const mounted = useRef<boolean>(false)
  const { composeMessages } = useContext(TransactionToastContext)

  useEffect(() => {
    if (!messages) return

    if (!mounted.current) {
      composeMessages(messages)

      mounted.current = true
    }
  }, [composeMessages, messages, mounted])
}
