import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

import type { Dict } from "common/types/object"

const parts = ["root", "tablist", "tab", "tabpanels", "tabpanel", "indicator"]

const tabs: ComponentMultiStyleConfig = {
  parts,
  variants: {
    line: ({ colorScheme: c }: Dict) => ({
      tablist: {
        borderBottomWidth: "1px",
      },
      tab: {
        _selected: {
          color: `${c as string}.500`,
          borderColor: `${c as string}.500`,
          fontWeight: "bold",
          textStyle: "md",
          borderBottomWidth: "3px",
        },
        py: 2,
        px: 3,
        color: "gray.700",
        textStyle: "md",
        lineHeight: "1.313rem",
      },
    }),
  },
}

export default tabs
