import { createClient } from "wagmi"
import { configureChains } from "@wagmi/core"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"
import { WalletConnectConnector } from "wagmi/connectors/walletConnect"
import { InjectedConnector } from "wagmi/connectors/injected"

import { WAGMI_CHAINS, WAGMI_PROVIDERS } from "web3/constants/rpc"

// @ts-expect-error nothing here
const { chains, provider } = configureChains(WAGMI_CHAINS, WAGMI_PROVIDERS)

export const wagmiClient = createClient({
  storage: undefined,
  autoConnect: true,
  provider,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      // https://github.com/wevm/wagmi/issues/3012#issuecomment-1721744364
      // tl;dr support `eth_signTypedData_v4` for wallet connect by removing
      // from `configureChains`
      chains: WAGMI_CHAINS,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        showQrModal: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Tally",
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: (detectedName: string | string[]) =>
          typeof detectedName === "string"
            ? detectedName
            : detectedName.join(", "),
        shimDisconnect: true,
      },
    }),
  ],
})
