import { EXTERNAL_ENDPOINTS } from "common/constants/endpoints"
import { fetcher } from "common/helpers/fetcher"

// eslint-disable-next-line import/no-unused-modules
export const useFetchData = <Data, Variables extends Record<string, unknown>>(
  query: string,
): ((variables?: Variables) => Promise<Data>) => {
  return (variables?: Variables) =>
    // if there is an error on a react-query "queryFn", then it's going to
    // be bubbled to the next error boundary, and will set the data automatically
    // to "undefined"

    // react-query isn't intelligent enough to understand that "null" is a possibility
    // that should be kept in mind. Nonetheless, under the hood it seems to be handled
    // and finally returned as "undefined"
    fetcher.gql<Data, Variables>({ query, variables }) as Promise<Data>
}

// eslint-disable-next-line import/no-unused-modules
export const useUniFetchData = <
  Data,
  Variables extends Record<string, unknown>,
>(
  query: string,
): ((variables?: Variables) => Promise<Data>) => {
  return (variables?: Variables) =>
    // if there is an error on a react-query "queryFn", then it's going to
    // be bubbled to the next error boundary, and will set the data automatically
    // to "undefined"

    // react-query isn't intelligent enough to understand that "null" is a possibility
    // that should be kept in mind. Nonetheless, under the hood it seems to be handled
    // and finally returned as "undefined"
    fetcher.gql<Data, Variables>({
      query,
      variables,
      endpoint: EXTERNAL_ENDPOINTS.uniswap(),
    }) as Promise<Data>
}
