import { isAddress } from "@ethersproject/address"
import { AccountId, AccountIdParams } from "caip"

import { isValidChainId } from "web3/helpers/chainId"

export const isSameAccountId = (aAccountId: string | undefined, bAccountId: string | undefined): boolean => {
  return aAccountId?.toLocaleLowerCase() === bAccountId?.toLocaleLowerCase()
}

export const getAccountId = ({ address, chainId }: AccountIdParams): string => {
  const accountId = AccountId.format({ address, chainId })

  return accountId
}

export const getAccountIdParams = (accountId: string): {address: string, chainId: string } => {

  const { address, chainId: accountChainId } = AccountId.parse(accountId)

  const chainId =
    typeof accountChainId === "string"
      ? accountChainId
      : `${accountChainId.namespace}:${accountChainId.reference}`

  return { address, chainId }
}

export const getAccountAddress = (accountId: string) : string => {
  const parts = accountId.split(":")

  return parts[parts.length - 1]
}


export const isValidAccountId = (accountId: string): boolean => {
  const getAccountAddress = (accountId: string) : string => {
    const parts = accountId.split(":")

    return parts[parts.length - 1]
  }

  const getParts = (accountId: string): string[] => {
    const lastDoubleDot = accountId.lastIndexOf(":")
    const chainId = accountId.slice(0, lastDoubleDot)

    const accountAddress = getAccountAddress(accountId)

    return [chainId, accountAddress]
  }

  const [chainId, accountAddress]= getParts(accountId)

  if (!isValidChainId(chainId) || !isAddress(accountAddress) ) {
    return false
  }

  return true

}
