import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import type { Chain } from "@wagmi/core"

import type { Chain as schemaChain } from "query/graphql/schema"
import chains from "web3/constants/chains"
import { getChainByReference } from "web3/helpers/findChain"
import { getChainIdParams } from "web3/helpers/chainId"

// generate a wagmi chain
const chainToWagmiChain = (chain: schemaChain) => {
  const { reference } = getChainIdParams(chain.id)

  // TODO , this needs the public rpc url, don't know why.
  const rpcArg: string =
    chain && chain.envRPCArg && process.env[chain.envRPCArg] !== undefined
      ? (process.env[chain.envRPCArg] as string)
      : ""

  return {
    id: reference,
    name: chain.name,
    network: chain.mediumName.trim().replace(/\s+/g, "-").toLowerCase(),
    nativeCurrency: chain.nativeCurrency,
    rpcUrls: {
      default: {
        http: [rpcArg],
      },
      public: {
        http: [rpcArg],
      },
    },
    blockExplorers: {
      default: {
        name: `${chain.name} Explorer`,
        url: chain?.blockExplorer?.url ?? "",
      },
    },
    testnet: chain.isTestnet,
  } as const satisfies Chain
}

// Generates wagmi configuration for all our chains
export const WAGMI_CHAINS = Object.values(chains)
  .filter((chain) => !chain.isDeprecated)
  .filter((chain) => chain.chain !== "solana")
  .map((chain) => {
    return chainToWagmiChain(chain)
  })

export const WAGMI_PROVIDERS = [
  jsonRpcProvider({
    rpc: (chain) => {
      const foundChain = getChainByReference(chain.id)

      if (
        foundChain &&
        foundChain.envRPCArg &&
        process.env[foundChain.envRPCArg]
      ) {
        // make sure to have all the env variables the backend asks for set in your.env.local
        const rpcArg = process.env[foundChain.envRPCArg] as string

        return { http: rpcArg }
      }

      return null
    },
  }),
]
