import { destroyCookie } from "nookies"

import { useRouter } from "common/hooks/useRouter"
import { useSession } from "session/providers/SessionProvider"

export const useLogout = () => {
  const { setIsLoggedIn } = useSession()
  const { push } = useRouter()

  const logout = (redirectTo?: string) => {
    destroyCookie(null, "token", { path: "/" })
    setIsLoggedIn(false)

    if (redirectTo) push(redirectTo)
  }

  return { logout }
}
