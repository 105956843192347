import { toFormattedString } from "common/components/ErrorBoundary"
import { handleParsingError } from "common/helpers/error"

export const jsonParse = (
  stringifiedJson: string,
  variables?: Record<string, unknown>,
): any => {
  try {
    return JSON.parse(stringifiedJson)
  } catch (error) {
    handleParsingError(error, {
      string: stringifiedJson,
      when: "parsing",
      type: typeof stringifiedJson,
      ...(variables ? variables : {}),
    })
  }
}

export const jsonStringify = (
  json: Record<string, unknown> | unknown[],
  variables?: Record<string, unknown> | null,
  space?: number,
): any => {
  try {
    return JSON.stringify(json, null, space)
  } catch (error) {
    handleParsingError(error, {
      json: toFormattedString(json),
      when: "stringifying",
      type: typeof json,
      ...(variables ? variables : {}),
    })
  }
}
