import type { FC } from "react"
import React from "react"
import { Text, Stack, HStack, Spinner, Flex } from "@chakra-ui/react"

type MiningToastProps = {
  title?: string
  description?: string
}

const MiningToast: FC<MiningToastProps> = ({ title, description }) => {
  const bg = "white"
  const color = "purple.900"

  return (
    <HStack
      alignItems="flex-start"
      bg={bg}
      color={color}
      minHeight="6.6875rem"
      minWidth="24rem"
      padding={4}
      position="relative"
      rounded="md"
      shadow="elevation.2"
      spacing={4}
    >
      <HStack align="flex-start" spacing={3}>
        <Flex align="center" justify="center" position="relative">
          <Spinner
            color="purple.500"
            emptyColor="gray.300"
            size="lg"
            speed="1s"
            thickness="3px"
          />
        </Flex>
        <Stack>
          {title ? (
            <Text color="gray.700" textStyle="lg">
              {title}
            </Text>
          ) : null}
          {description ? (
            <Text color="gray.500" textStyle="body.regular.md">
              {description}
            </Text>
          ) : null}
        </Stack>
      </HStack>
    </HStack>
  )
}

export default MiningToast
