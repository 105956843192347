export const getErrorMessage = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any,
): string => {
  // TODO(@niconiahi): recheck this as it seems to be highly opinionated
  if (error?.data?.originalMessage?.message) {
    return error.data.originalMessage.message
  }

  if (error?.data?.message) {
    return error.data.message
  }

  if (error?.error) {
    return error.error.message
  }

  if (error?.context?.error?.reason) {
    return error.context.error.reason
  }

  if (error?.context?.error) {
    return error.context.error
  }

  return error?.message
}
