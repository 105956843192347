import React from "react"
import { createIcon } from "@chakra-ui/icons"

const WarningTriangleIcon = createIcon({
  displayName: "WarningTriangeIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      d="M22.4539 21.1877L12.5834 2.85354C12.5513 2.79389 12.5037 2.74405 12.4455 2.70931C12.3874 2.67456 12.3209 2.65622 12.2532 2.65622C12.1855 2.65622 12.119 2.67456 12.0608 2.70931C12.0027 2.74405 11.955 2.79389 11.9229 2.85354L2.05061 21.1877C2.01987 21.2449 2.00448 21.309 2.00596 21.3739C2.00743 21.4388 2.02571 21.5022 2.05901 21.5579C2.09231 21.6136 2.1395 21.6597 2.19595 21.6916C2.2524 21.7236 2.31619 21.7404 2.38108 21.7404H22.1258C22.1904 21.74 22.2539 21.7229 22.3101 21.6908C22.3662 21.6586 22.4131 21.6125 22.4461 21.5569C22.4791 21.5012 22.4972 21.438 22.4986 21.3733C22.4999 21.3087 22.4845 21.2447 22.4539 21.1877ZM13.1904 19.4867H11.3155V17.6117H13.1904V19.4867ZM13.0029 16.4904H11.503L11.2217 8.99041H13.2842L13.0029 16.4904Z"
      fill="currentColor"
    />
  ),
})

export default WarningTriangleIcon
