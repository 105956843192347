import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetchData } from 'common/hooks/useFetchData';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountID: string;
  Address: string;
  Any: any;
  AssetID: string;
  BlockID: string;
  Bytes: string;
  Bytes32: string;
  ChainID: string;
  Date: any;
  Hash: any;
  HashID: string;
  IntID: string;
  JSON: any;
  Map: any;
  ProposalID: string;
  Timestamp: string;
  UUID: any;
  Uint256: string;
  Upload: any;
};

/** Key for use with this API.  See https://docs.tally.xyz/tally-api/welcome#request-an-api-key for how to request & use! */
export type ApiKey = {
  /** Last four characters of original generated key */
  lastFour: Scalars['String'];
  /** User generated name to differentiate keys */
  name: Scalars['String'];
};

export type AbiElement = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Account = {
  address: Scalars['Address'];
  apiKeys?: Maybe<Array<ApiKey>>;
  bio: Scalars['String'];
  email: Scalars['String'];
  ens?: Maybe<Scalars['String']>;
  features?: Maybe<Array<FeatureState>>;
  id: Scalars['ID'];
  isOFAC: Scalars['Boolean'];
  name: Scalars['String'];
  otherLinks?: Maybe<Array<OtherLink>>;
  picture?: Maybe<Scalars['String']>;
  proposalsCreatedCount: Scalars['Int'];
  safes?: Maybe<Array<Scalars['AccountID']>>;
  twitter?: Maybe<Scalars['String']>;
  type: AccountType;
  votes: Scalars['Uint256'];
};


export type AccountProposalsCreatedCountArgs = {
  input: ProposalsCreatedCountInput;
};


export type AccountVotesArgs = {
  governorId: Scalars['AccountID'];
};

export type AccountElectionMeta = {
  hasRegistered: Scalars['Boolean'];
  isContender: Scalars['Boolean'];
  /** The contender's statement, set during register as candidate flow. */
  statement?: Maybe<Scalars['String']>;
  /** The contender's title, set during register as candidate flow. */
  title?: Maybe<Scalars['String']>;
};

export enum AccountType {
  Eoa = 'EOA',
  Safe = 'SAFE'
}

/** Source of data: Hexagate. All the events/threats for an executable call, along with a result describing whether the simulation was successful i.e. was Hexagate able to successfully run this action */
export type ActionThreatData = {
  events?: Maybe<Array<EventDataPoint>>;
  result: Scalars['String'];
};

/** Security check for a bundle of actions (proposal-level) */
export type ActionsSecurityCheck = {
  metadata: ActionsSecurityCheckMetadata;
  simulations: Array<TransactionSimulationV2>;
};

/** Metadata for a bundle (proposal-level) security check */
export type ActionsSecurityCheckMetadata = {
  threatAnalysis?: Maybe<ThreatAnalysis>;
};

export type AddAdminInput = {
  address: Scalars['String'];
  role: OrganizationRole;
};

export type AddContractsToOrganizationInput = {
  governors?: InputMaybe<Array<CreateGovernorInputV2>>;
  id: Scalars['IntID'];
  tokens?: InputMaybe<Array<CreateTokenInput>>;
};

export type AddCouncilInput = {
  description: Scalars['String'];
  id: Scalars['AccountID'];
  memberGovernor: CreateGovernorInputV2;
  name: Scalars['String'];
  nomineeGovernor: CreateGovernorInputV2;
  slug: Scalars['String'];
  startBlock: Scalars['Int'];
  token: CreateTokenInput;
};

export type AddressInfo = {
  accounts: Array<Account>;
  address: Scalars['Address'];
  /** Account used for SIWE (auth). */
  ethAccount: Account;
};

/** Source of data: Hexagate. Action-level (executable call) analysis data point, the name is a label (e.g. SMARTCONTRACT_IMPLEMENTS_ANTI_SIMULATION_TECHNIQUES) and the result gives an indication whether or not it passed the check */
export type AnalysisDataPointV2 = {
  name: Scalars['String'];
  result: Scalars['Boolean'];
};

export type BalanceItem = {
  address: Scalars['String'];
  balance: Scalars['String'];
  balance24H: Scalars['String'];
  decimals: Scalars['Int'];
  logo: Scalars['String'];
  name: Scalars['String'];
  nativeToken: Scalars['Boolean'];
  quote?: Maybe<Scalars['Float']>;
  quote24H?: Maybe<Scalars['Float']>;
  quoteRate?: Maybe<Scalars['Float']>;
  quoteRate24H?: Maybe<Scalars['Float']>;
  symbol: Scalars['String'];
  type: Scalars['String'];
};

export type Block = {
  id: Scalars['BlockID'];
  number: Scalars['Int'];
  timestamp: Scalars['Timestamp'];
  ts: Scalars['Timestamp'];
};

export type BlockExplorer = {
  apiUrl: Scalars['String'];
  type: BlockExplorerType;
  url: Scalars['String'];
};

export enum BlockExplorerType {
  Blockscout = 'blockscout',
  Etherscan = 'etherscan',
  Other = 'other'
}

export type BlockIdInput = {
  blockNumber: Scalars['Int'];
  chain: Scalars['ChainID'];
};

export type BlockOrTimestamp = Block | BlocklessTimestamp;

export type BlocklessTimestamp = {
  timestamp: Scalars['Timestamp'];
};

export type Candidate = {
  account: Account;
  totalVoters: Scalars['Int'];
  totalVotes: Scalars['Uint256'];
  votes: Array<CandidateVote>;
};


export type CandidateVotesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type CandidateExport = {
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export enum CandidateSort {
  Alphabetical = 'ALPHABETICAL',
  Random = 'RANDOM',
  Votes = 'VOTES'
}

export type CandidateVote = {
  voter: Account;
  weight: Scalars['Uint256'];
};

export type CastVoteActionMetadata = {
  /** Address of the user casting the vote */
  address: Scalars['Address'];
  /** The amount of gas paid for the given meta transaction */
  gasPrice: Scalars['Uint256'];
  /** Address of the governor related to the vote */
  governorId: Scalars['AccountID'];
  /** ID of the proposal related to the dao */
  proposalId: Scalars['ID'];
  /** The vote support as FOR, AGAINST or ABSTAIN */
  support?: Maybe<SupportType>;
  /** Executor's generated transaction id (not the same as chain transaction id) */
  transactionID: Scalars['String'];
  /** Executor's given end date validaty of the transaction */
  validUntil: Scalars['Timestamp'];
};

/** Chain data in the models are only loaded on server startup. If changed please restart the api servers. */
export type Chain = {
  blockExplorer?: Maybe<BlockExplorer>;
  /** Average block time in seconds. */
  blockTime: Scalars['Float'];
  /** Chain as parameter found in the eip. */
  chain: Scalars['String'];
  /** Boolean true if Covalent supports this network in it's API. */
  covalentSupport: Scalars['Boolean'];
  /** Boolean true if Cowswap supports the chain, false if it doesn't. */
  cowswapSupport: Scalars['Boolean'];
  /** Env Explorer Arg, which can be nil, is the env arg name of the key that we will use in the FE */
  envExplorerArg?: Maybe<Scalars['String']>;
  /** Env RPC Arg, which can be nil, is the env arg name of the RPC endpoint that we will use in the FE */
  envRPCArg?: Maybe<Scalars['String']>;
  features?: Maybe<Array<FeatureState>>;
  /** gnosisServiceURL of the chain, can be empty or an string */
  gnosisServiceURL?: Maybe<Scalars['String']>;
  /** Boolean true if Hexagate supports this network in it's API. */
  hexagateAnalysisSupport: Scalars['Boolean'];
  /** The id in eip155:chain_id */
  id: Scalars['ChainID'];
  isDeprecated: Scalars['Boolean'];
  /** Boolean true if it is a testnet, false if it's not. */
  isTestnet: Scalars['Boolean'];
  /** If chain is an L2, the L1 id in format eip155:chain_id */
  layer1Id?: Maybe<Scalars['ChainID']>;
  /** Chain name with removed redundancy and unnecessary words. e.g.: Ethereum Rinkeby */
  mediumName: Scalars['String'];
  /** Contract address of Milkman (for Cowswap proposals). */
  milkmanContract?: Maybe<Scalars['AccountID']>;
  /** Chain name as found in eip lists. e.g.: Ethereum Testnet Rinkeby */
  name: Scalars['String'];
  /** Data from chain native currency. */
  nativeCurrency: NativeCurrency;
  /** Chain short name as found in eip lists. The Acronym of it. e.g.: rin */
  shortName: Scalars['String'];
  /** Icon SVG of the chain logo. */
  svg?: Maybe<Scalars['String']>;
  /** Boolean true if Tenderly supports simulations. */
  tenderlySupport: Scalars['Boolean'];
  /** Boolean true if L2 depends on L1 for voting period, false if it doesn't. */
  useLayer1VotingPeriod: Scalars['Boolean'];
};

export type ClaimAndDelegateAttempt = {
  createdAt: Scalars['Timestamp'];
  delegateeId: Scalars['AccountID'];
  delegatorId: Scalars['AccountID'];
  expiry: Scalars['Uint256'];
  parameterR: Scalars['Bytes32'];
  parameterS: Scalars['Bytes32'];
  parameterV: Scalars['Uint256'];
  proof?: Maybe<Array<Scalars['String']>>;
  tokenId: Scalars['AssetID'];
  txID: Scalars['HashID'];
};

export type CloudEvent = {
  data: Scalars['Map'];
  datacontenttype?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  source: Scalars['String'];
  specversion: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Timestamp']>;
  type: CloudEventType;
};

export type CloudEventInput = {
  /** CloudEvent data (i.e. JSON object) */
  data: Scalars['Map'];
  /** Content type of the CloudEvent data (i.e. application/json) */
  datacontenttype?: InputMaybe<Scalars['String']>;
  /** UUID of the CloudEvent */
  id: Scalars['UUID'];
  /** Source of the CloudEvent as URI reference (i.e. https://example.com or /example) */
  source: Scalars['String'];
  /** Version of the CloudEvent specification */
  specversion: Scalars['String'];
  /** Optional subject of the CloudEvent */
  subject?: InputMaybe<Scalars['String']>;
  /** RFC3339 timestamp of when the CloudEvent was created */
  time: Scalars['Timestamp'];
  /** Type of the CloudEvent */
  type: CloudEventType;
};

export enum CloudEventType {
  DelegationIsSeekingDelegationsToggle = 'delegationIsSeekingDelegationsToggle'
}

export type Collectible = {
  ID: Scalars['ID'];
  address: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageURI?: Maybe<Scalars['String']>;
  logoURI: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tokenName: Scalars['String'];
  tokenSymbol: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

export type CompetencyField = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CompetencyFieldsInput = {
  endorsementServiceId: Scalars['IntID'];
};

export type Confirmation = {
  owner: Account;
  signature: Scalars['Bytes'];
  signatureType: Scalars['String'];
  submissionDate: Scalars['Timestamp'];
};

export type Contact = {
  discord: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  twitter: Scalars['String'];
};

export type ContactInput = {
  discord: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  twitter: Scalars['String'];
};

export type Contender = Candidate & {
  account: Account;
  accountElectionMeta: AccountElectionMeta;
  id: Scalars['ID'];
  nominated: Scalars['Boolean'];
  rejected: Scalars['Boolean'];
  totalVoters: Scalars['Int'];
  totalVotes: Scalars['Uint256'];
  votes: Array<CandidateVote>;
};


export type ContenderVotesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export enum ContenderFilter {
  All = 'ALL',
  Qualified = 'QUALIFIED',
  SeekingVotes = 'SEEKING_VOTES'
}

export type ContractVerificationV2 = {
  isVerified: Scalars['Boolean'];
};

export type Contracts = {
  governor: GovernorContract;
  tokens: Array<TokenContract>;
};

export type Council = {
  cohortSize: Scalars['Int'];
  description: Scalars['String'];
  elections: Array<Election>;
  id: Scalars['IntID'];
  members: CouncilMembers;
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type CouncilElectionsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type CouncilMembers = {
  firstCohort: Array<Account>;
  secondCohort: Array<Account>;
};

export type CovalentData = {
  decimals: Scalars['Int'];
  logo: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  symbol: Scalars['String'];
};

export type CreateGovernorInput = {
  id: Scalars['AccountID'];
  kind?: InputMaybe<GovernorKind>;
  metadata?: InputMaybe<GovernorMetadataInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  /** The block height at which the Governor contract was originally deployed. */
  startBlock: Scalars['Int'];
  tokenId: Scalars['AssetID'];
  type: GovernorType;
};

export type CreateGovernorInputV2 = {
  hubGovernorAccountId?: InputMaybe<Scalars['AccountID']>;
  hubVotePoolAddress?: InputMaybe<Scalars['Address']>;
  id: Scalars['AccountID'];
  spokeMetadataCollectorAddress?: InputMaybe<Scalars['Address']>;
  startBlock: Scalars['Int'];
  token: CreateTokenInput;
  type: GovernorType;
};

export type CreateGovernorsInput = {
  governors: Array<CreateGovernorInput>;
  /** Organization is required when creating a new DAO with govenors and tokens */
  organization?: InputMaybe<CreateOrganizationInput>;
  /** Organization id is required when creating governors for an existing DAO */
  organizationId?: InputMaybe<Scalars['IntID']>;
  /** Tokens are required when creating a new DAO with govenors and tokens or when tokens for govenrors have not been created yet */
  tokens?: InputMaybe<Array<CreateTokenInput>>;
};

export type CreateOrganizationInput = {
  description: Scalars['String'];
  /** The governors that control the Organization. */
  governors?: InputMaybe<Array<CreateGovernorInputV2>>;
  name: Scalars['String'];
  /** For Organizations that have yet to deploy governors but have tokens, use this field. DO NOT use this field if governors are defined in the `governors` field. */
  tokens?: InputMaybe<Array<CreateTokenInput>>;
};

export type CreateProposalActionAttemptInput = {
  actor: Scalars['Address'];
  proposalId: Scalars['IntID'];
  txHash: Scalars['Hash'];
  type: ProposalActionType;
};

export type CreateProposalInput = {
  description: Scalars['String'];
  discourseURL?: InputMaybe<Scalars['String']>;
  executableCalls: Array<ExecutableCallInput>;
  governorId: Scalars['AccountID'];
  originalId?: InputMaybe<Scalars['IntID']>;
  simulationValue?: InputMaybe<Scalars['Uint256']>;
  skipHexagate?: InputMaybe<Scalars['Boolean']>;
  skipTenderly?: InputMaybe<Scalars['Boolean']>;
  snapshotURL?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  txHash?: InputMaybe<Scalars['Hash']>;
};

export type CreateSafeInput = {
  id: Scalars['AccountID'];
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['IntID'];
};

export type CreateTokenInput = {
  id: Scalars['AssetID'];
  /** The block height at which the Token contract was originally deployed. */
  startBlock: Scalars['Int'];
};

export type CreateUnistakerTransactionInput = {
  address: Scalars['Address'];
  beneficiary?: InputMaybe<Scalars['Address']>;
  delegatee?: InputMaybe<Scalars['Address']>;
  depositId?: InputMaybe<Scalars['String']>;
  id: Scalars['HashID'];
  newAmount?: InputMaybe<Scalars['Uint256']>;
  previousAmount?: InputMaybe<Scalars['Uint256']>;
  status: UnistakerTransactionStatus;
  type: UnistakerTransactionType;
};

export type CreateVoteAttemptInput = {
  proposalId: Scalars['IntID'];
  txHash: Scalars['Hash'];
  type: VoteType;
  voter: Scalars['Address'];
};

export type DataDecoded = {
  method: Scalars['String'];
  parameters: Array<Parameter>;
};

export type Delegate = {
  account: Account;
  chainId?: Maybe<Scalars['ChainID']>;
  delegatorsCount: Scalars['Int'];
  governor?: Maybe<Governor>;
  id: Scalars['IntID'];
  organization?: Maybe<Organization>;
  statement?: Maybe<DelegateStatement>;
  token?: Maybe<Token>;
  voteChanges: Array<VotingPowerChange>;
  votesCount: Scalars['Uint256'];
};


export type DelegateVotesCountArgs = {
  blockNumber?: InputMaybe<Scalars['Int']>;
};

export type DelegateActionMetadata = {
  /** Address of the user receiving the delegation */
  delegatee: Scalars['Address'];
  /** Address of the user delegating using a meta transaction action */
  from: Scalars['Address'];
  /** The amount of gas paid for the given meta transaction */
  gasPrice: Scalars['Uint256'];
  /** The DAO contract chain scoped information */
  tokenContractId: Scalars['AssetID'];
  /** Executor's generated transaction id (not the same as chain transaction id) */
  transactionId: Scalars['String'];
  /** Executor's given end date validaty of the transaction */
  validUntil: Scalars['Timestamp'];
};

export type DelegateInput = {
  address: Scalars['Address'];
  governorId?: InputMaybe<Scalars['AccountID']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type DelegateStatement = {
  address: Scalars['Address'];
  dataSource: DelegateStatementSource;
  dataSourceURL?: Maybe<Scalars['String']>;
  discourseProfileLink?: Maybe<Scalars['String']>;
  discourseUsername?: Maybe<Scalars['String']>;
  hideDisclaimer?: Maybe<Scalars['Boolean']>;
  id: Scalars['IntID'];
  isMember?: Maybe<Scalars['Boolean']>;
  isSeekingDelegation?: Maybe<Scalars['Boolean']>;
  issues?: Maybe<Array<Issue>>;
  organizationID: Scalars['IntID'];
  statement: Scalars['String'];
  statementSummary?: Maybe<Scalars['String']>;
};

export enum DelegateStatementSource {
  Script = 'script',
  User = 'user'
}

export type DelegatesFiltersInput = {
  /** `address` filter in combination with `organizationId` allows fetching delegate info of this address from each chain */
  address?: InputMaybe<Scalars['Address']>;
  governorId?: InputMaybe<Scalars['AccountID']>;
  hasDelegators?: InputMaybe<Scalars['Boolean']>;
  hasVotes?: InputMaybe<Scalars['Boolean']>;
  isSeekingDelegation?: InputMaybe<Scalars['Boolean']>;
  issueIds?: InputMaybe<Array<Scalars['IntID']>>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type DelegatesInput = {
  filters: DelegatesFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<DelegatesSortInput>;
};

export enum DelegatesSortBy {
  /** Sorts by total delegators. */
  Delegators = 'delegators',
  /** The default sorting method. It sorts by date. */
  Id = 'id',
  /** Sorts by DAO prioritization. */
  Prioritized = 'prioritized',
  /** Sorts by voting power. */
  Votes = 'votes'
}

export type DelegatesSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: DelegatesSortBy;
};

export type Delegation = {
  blockNumber: Scalars['Int'];
  blockTimestamp: Scalars['Timestamp'];
  chainId: Scalars['ChainID'];
  delegate: Account;
  delegator: Account;
  id: Scalars['IntID'];
  organization: Organization;
  token: Token;
  votes: Scalars['Uint256'];
};

export type DelegationAttempt = {
  createdAt: Scalars['Timestamp'];
  delegateeId: Scalars['AccountID'];
  delegatorId: Scalars['AccountID'];
  governanceId: Scalars['AccountID'];
  txID: Scalars['HashID'];
};

export type DelegationInput = {
  address: Scalars['Address'];
  tokenId: Scalars['AssetID'];
};

export type DelegationsFiltersInput = {
  address: Scalars['Address'];
  governorId?: InputMaybe<Scalars['AccountID']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type DelegationsInput = {
  filters: DelegationsFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<DelegationsSortInput>;
};

export enum DelegationsSortBy {
  /** The default sorting method. It sorts by date. */
  Id = 'id',
  /** Sorts by voting power. */
  Votes = 'votes'
}

export type DelegationsSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: DelegationsSortBy;
};

export type Election = {
  accountElectionMeta: AccountElectionMeta;
  councilId: Scalars['Int'];
  id: Scalars['ID'];
  /** 2nd round of election. */
  memberRound?: Maybe<MemberRound>;
  /** 1st round of election. */
  nominationRound: NominationRound;
  /** Election number, incremental. */
  number: Scalars['Int'];
  status: ElectionStatus;
};


export type ElectionAccountElectionMetaArgs = {
  address: Scalars['String'];
};

export enum ElectionStatus {
  Complete = 'COMPLETE',
  Grace = 'GRACE',
  Member = 'MEMBER',
  Nomination = 'NOMINATION'
}

export type Eligibility = {
  /** Amount the account can claim from this token */
  amount?: Maybe<Scalars['Uint256']>;
  proof?: Maybe<Array<Scalars['String']>>;
  /** Whether the account is eligible to claim */
  status: EligibilityStatus;
  tx?: Maybe<Scalars['HashID']>;
};

export enum EligibilityStatus {
  Claimed = 'CLAIMED',
  Eligible = 'ELIGIBLE',
  Noteligible = 'NOTELIGIBLE'
}

export type EndorsementService = {
  competencyFields: Array<CompetencyField>;
};

export type EndorsementServiceInput = {
  id?: InputMaybe<Scalars['IntID']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

/** Source of data: Hexagate. Proposal-level analysis data point (it is per executable call, but run with Hexagate Governance analysis), the name is a label (e.g. SMARTCONTRACT_IMPLEMENTS_ANTI_SIMULATION_TECHNIQUES) and the result gives an indication whether or not it passed the check */
export type EventDataPoint = {
  description: Scalars['String'];
  eventType: Scalars['String'];
  severity: Scalars['String'];
};

export type ExecutableCall = {
  calldata: Scalars['Bytes'];
  chainId: Scalars['ChainID'];
  index: Scalars['Int'];
  offchaindata?: Maybe<ExecutableCallOffchainData>;
  /** Target contract's function signature. */
  signature?: Maybe<Scalars['String']>;
  target: Scalars['Address'];
  type?: Maybe<ExecutableCallType>;
  value: Scalars['Uint256'];
};

export type ExecutableCallInput = {
  calldata: Scalars['Bytes'];
  offchaindata?: InputMaybe<Scalars['JSON']>;
  signature?: InputMaybe<Scalars['String']>;
  target: Scalars['Address'];
  type: ExecutableCallType;
  value: Scalars['Uint256'];
};

export type ExecutableCallOffchainData = ExecutableCallRewards | ExecutableCallSwap;

export type ExecutableCallRewards = {
  contributorFee: Scalars['Int'];
  recipients: Array<Scalars['String']>;
  tallyFee: Scalars['Int'];
};

export type ExecutableCallSwap = {
  /** Sell amount for the swap. */
  amountIn: Scalars['Uint256'];
  buyToken: TokenData;
  /** Tally fee */
  fee?: Maybe<Scalars['Uint256']>;
  /** Order if the proposal is executed. */
  order?: Maybe<SwapOrder>;
  priceChecker: PriceChecker;
  /** Quote if no order exists yet. */
  quote?: Maybe<SwapQuote>;
  sellToken: TokenData;
  to: Scalars['AccountID'];
};

export enum ExecutableCallType {
  Custom = 'custom',
  Empty = 'empty',
  Erc20transfer = 'erc20transfer',
  Erc20transferarbitrum = 'erc20transferarbitrum',
  Nativetransfer = 'nativetransfer',
  Orcamanagepod = 'orcamanagepod',
  Other = 'other',
  Reward = 'reward',
  Swap = 'swap'
}

export type FeatureState = {
  account?: Maybe<Account>;
  chainID?: Maybe<Scalars['ChainID']>;
  enabled: Scalars['Boolean'];
  governor?: Maybe<Governor>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
};

/** The `File` type, represents the response of uploading a file. */
export type File = {
  contentType: Scalars['String'];
  id: Scalars['String'];
  metadata: Image;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ForumActivity = {
  topics: Array<ForumTopic>;
};

export type ForumTopic = {
  bumpedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fancyTitle?: Maybe<Scalars['String']>;
  highestPostNumber?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastPostedAt: Scalars['String'];
  likeCount: Scalars['Int'];
  originalPosterAvatarTemplate?: Maybe<Scalars['String']>;
  originalPosterName?: Maybe<Scalars['String']>;
  originalPosterUsername?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  postsCount: Scalars['Int'];
  replyCount: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  views: Scalars['Int'];
};

export type GnosisSafe = {
  /** Values of all Tokens in this Gnosis Safe */
  balance?: Maybe<Treasury>;
  collectibles?: Maybe<Array<Maybe<Collectible>>>;
  /** GnosisSafe smart contract AccountID. */
  id: Scalars['AccountID'];
  /** GnosisSafe name to help distinguish it. */
  name?: Maybe<Scalars['String']>;
  /** A counter of the amount of transactions executed on the safe. */
  nonce?: Maybe<Scalars['Int']>;
  /** A list of owner Accounts.  The Account includes participations, but we haven't included gnosis safe owners or signers in the participations yet. */
  owners?: Maybe<Array<Account>>;
  /** The amount of confirmations (owner signatures) that are required to execute a transaction. */
  threshold?: Maybe<Scalars['Int']>;
  /** GnosisSafe smart contract version. */
  version?: Maybe<Scalars['String']>;
};

/** A transaction can be `SUBMITTED` or `EXECUTED`. An `EXECUTED` transaction will include a block and an on chain txHashID. */
export type GnosisSafeTransaction = {
  /** `Block` at which this safe transaction was executed. */
  block?: Maybe<Block>;
  /** All the owners that have signed the transaction. */
  confirmations: Array<Confirmation>;
  /** DataDecoded includes the method and parameters used in the transaction. */
  dataDecoded?: Maybe<DataDecoded>;
  /** Chain scoped safeTxHash- https://github.com/safe-global/safe-contracts/blob/da66b45ec87d2fb6da7dfd837b29eacdb9a604c5/contracts/GnosisSafe.sol#L353-L394. */
  id: Scalars['HashID'];
  /** Current counter of multisig transactions executed on this safe.  No two transactions on this contract will have the same `nonce`. */
  nonce?: Maybe<Scalars['Uint256']>;
  /** `GnosisSafe` smart contract AccountID. */
  safeID: Scalars['AccountID'];
  /** Chain scoped safeTxHash- https://github.com/safe-global/safe-contracts/blob/da66b45ec87d2fb6da7dfd837b29eacdb9a604c5/contracts/GnosisSafe.sol#L353-L394. */
  safeTxHashID?: Maybe<Scalars['HashID']>;
  /** Executed transaction verified signatures. */
  signatures?: Maybe<Scalars['Bytes']>;
  /** A list of all states the transaction has been through with a timestamp.  A transaction can be `SUBMITTED` or `EXECUTED`.  Similar to a governor proposal. */
  statusChanges: Array<GnosisStatusChange>;
  /** Ethereum transaction hash of the executed transaction. */
  txHashID?: Maybe<Scalars['HashID']>;
};

export type GnosisSafesInput = {
  organizationIds?: InputMaybe<Array<Scalars['IntID']>>;
};

export type GnosisStatusChange = {
  timestamp: Scalars['Timestamp'];
  type: GnosisStatusChangeType;
};

export enum GnosisStatusChangeType {
  Executed = 'EXECUTED',
  Submitted = 'SUBMITTED'
}

export type Governor = {
  chainId: Scalars['ChainID'];
  contracts: Contracts;
  delegatesCount: Scalars['Int'];
  delegatesVotesCount: Scalars['Uint256'];
  features?: Maybe<Array<FeatureState>>;
  id: Scalars['AccountID'];
  isBehind: Scalars['Boolean'];
  isIndexing: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  kind: GovernorKind;
  /** Last block that Tally has indexed.  Sometimes our indexer needs to catch up.  Our indexer is usually ~1min behind depending on chain so we don't serve data that might later be reorged. */
  lastIndexedBlock: Block;
  metadata?: Maybe<GovernorMetadata>;
  /** Tally name of the governor contract */
  name: Scalars['String'];
  organization: Organization;
  parameters: GovernorParameters;
  proposalStats: ProposalStats;
  /** The minumum amount of votes (total or for depending on type) that are currently required to pass a proposal. */
  quorum: Scalars['Uint256'];
  /** Tally slug used for this goverance: tally.xyz/gov/[slug] */
  slug: Scalars['String'];
  /** Chain scoped address of the timelock contract for this governor if it exists. */
  timelockId?: Maybe<Scalars['AccountID']>;
  token: Token;
  tokenId: Scalars['AssetID'];
  tokenOwnersCount: Scalars['Int'];
  type: GovernorType;
};

export type GovernorContract = {
  address: Scalars['Address'];
  lastBlock: Scalars['Int'];
  type: GovernorType;
};

export type GovernorInput = {
  id?: InputMaybe<Scalars['AccountID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum GovernorKind {
  Hub = 'hub',
  Multiother = 'multiother',
  Multiprimary = 'multiprimary',
  Multisecondary = 'multisecondary',
  Single = 'single',
  Spoke = 'spoke'
}

export type GovernorMetadata = {
  description?: Maybe<Scalars['String']>;
};

export type GovernorMetadataInput = {
  description?: InputMaybe<Scalars['String']>;
};

export type GovernorParameters = {
  clockMode?: Maybe<Scalars['String']>;
  fullWeightDuration?: Maybe<Scalars['Uint256']>;
  gracePeriod?: Maybe<Scalars['Uint256']>;
  nomineeVettingDuration?: Maybe<Scalars['Uint256']>;
  proposalThreshold?: Maybe<Scalars['Uint256']>;
  quorumDenominator?: Maybe<Scalars['Uint256']>;
  quorumNumerator?: Maybe<Scalars['Uint256']>;
  quorumVotes?: Maybe<Scalars['Uint256']>;
  votingDelay?: Maybe<Scalars['Uint256']>;
  votingPeriod?: Maybe<Scalars['Uint256']>;
};

export enum GovernorType {
  Aave = 'aave',
  Governoralpha = 'governoralpha',
  Governorbravo = 'governorbravo',
  Hub = 'hub',
  Memberelection = 'memberelection',
  Nomineeelection = 'nomineeelection',
  Nounsfork = 'nounsfork',
  Openzeppelingovernor = 'openzeppelingovernor',
  Spoke = 'spoke'
}

export type GovernorsFiltersInput = {
  excludeSecondary?: InputMaybe<Scalars['Boolean']>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['IntID'];
};

export type GovernorsInput = {
  filters: GovernorsFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<GovernorsSortInput>;
};

export enum GovernorsSortBy {
  /** The default sorting method. It sorts by date. */
  Id = 'id'
}

export type GovernorsSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: GovernorsSortBy;
};

export type IdentitiesInput = {
  twitter?: InputMaybe<TwitterIdentity>;
};

export type Image = {
  thumbnail?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Issue = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['IntID'];
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['IntID']>;
};

export type IssueInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type IssuesFiltersInput = {
  governanceId?: InputMaybe<Scalars['AccountID']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type IssuesInput = {
  filters?: InputMaybe<IssuesFiltersInput>;
};

export type JoinOrganizationInput = {
  id: Scalars['IntID'];
  password?: InputMaybe<Scalars['String']>;
};

export type LinkGovernorInput = {
  id: Scalars['AccountID'];
  organizationId: Scalars['IntID'];
};

export type Member = {
  account: Account;
  id: Scalars['ID'];
  organization: Organization;
  role: OrganizationRole;
};

export type MemberRound = Round & {
  availableVotes: Scalars['Uint256'];
  end: Block;
  fullWeightDuration: Scalars['Uint256'];
  id: Scalars['ProposalID'];
  nominees: Array<Nominee>;
  start: Block;
  status: RoundStatus;
  votesToWeight: Scalars['Uint256'];
};


export type MemberRoundAvailableVotesArgs = {
  address: Scalars['String'];
};


export type MemberRoundNomineesArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CandidateSort>;
};


export type MemberRoundVotesToWeightArgs = {
  votes: Scalars['Uint256'];
};

export type MetaTransaction = {
  action: MetaTransactionAction;
  address: Scalars['Address'];
  createdAt: Scalars['Timestamp'];
  governorId: Scalars['AccountID'];
  id: Scalars['ID'];
  metadata: MetaTransactionActionMetadata;
};

export enum MetaTransactionAction {
  CastVote = 'CAST_VOTE',
  Delegate = 'DELEGATE'
}

export type MetaTransactionActionMetadata = CastVoteActionMetadata | DelegateActionMetadata;

export type MetaTransactionSort = {
  field?: InputMaybe<MetaTransactionSortField>;
  order?: InputMaybe<SortOrder>;
};

export enum MetaTransactionSortField {
  Created = 'CREATED'
}

export type Mutation = {
  addContractsToOrganization: Organization;
  addCouncil: Scalars['Boolean'];
  addWhitelabelDomain: Scalars['Boolean'];
  analyticsBackfill: Scalars['Boolean'];
  archiveProposal: Scalars['Boolean'];
  /** Creates an API Key for the logged in User */
  createAPIKey: Scalars['String'];
  createCastVoteMetaTransaction: MetaTransaction;
  /** Creates a `ClaimAndDelegateAttempt` with the data called by the user. */
  createClaimAndDelegateAttempt: Scalars['Boolean'];
  createCloudEvent: CloudEvent;
  createDelegateMetaTransaction: MetaTransaction;
  /** Creates a `DelegationAttempt` with the user intended to delegate */
  createDelegationAttempt: Scalars['Boolean'];
  createIssue: Scalars['Boolean'];
  createOrganization: Organization;
  createProposal: Proposal;
  createProposalActionAttempt: Scalars['Boolean'];
  /** Much like governors we can add a safe to an existing DAO.  A DAO can have an unlimited amount of `GnosisSafe`s. */
  createSafe: Scalars['Boolean'];
  createSafeV2: Scalars['Boolean'];
  createUnistakerTransaction: Scalars['Boolean'];
  createVoteAttempt: Scalars['Boolean'];
  deleteIssue: Scalars['Boolean'];
  deleteSync: Scalars['Boolean'];
  disableWhitelabelDomain: Scalars['Boolean'];
  ingestOFACAddresses: Scalars['Boolean'];
  /** Adds the authenticated user to the organization. */
  joinOrganization: Scalars['Boolean'];
  linkGovernor: Governor;
  login: Scalars['String'];
  loginAsSafe: Scalars['String'];
  logout: Scalars['Boolean'];
  /** pauseSync, when pause syncing events from a contrat. */
  pauseSync: Scalars['Boolean'];
  registerAsContenderAttempt: Scalars['Boolean'];
  removeAccountENS: Scalars['Boolean'];
  removeAccountTwitter: Scalars['Boolean'];
  /** This mutation is used to remove an organization by its id. It will remove the organization and all its related data. */
  removeOrganization: Scalars['Boolean'];
  removeSuperAdmin: Scalars['Boolean'];
  removeTwitter: Scalars['Boolean'];
  /** Restores the provided proposal draft as the latest proposal version */
  restoreProposalDraft: Scalars['Boolean'];
  /** ResumeSync, resumes syncing an contract. */
  resumeSync: Scalars['Boolean'];
  setArbitrumProposalExecuted: Scalars['Boolean'];
  /** Unlinks a Safe from it's Organization for linking to other Organizations */
  unlinkGnosisSafe: Scalars['Boolean'];
  /** Updates tally stored `Account` metadata (name, bio, picture, email, identity providers, etc) */
  updateAccount: Scalars['Boolean'];
  /** Updates an Account for a user via their account id */
  updateAccountByID: Scalars['Boolean'];
  updateCandidateProfile: Scalars['Boolean'];
  updateChain: Chain;
  updateFeature: FeatureState;
  updateGovernor: Governor;
  updateOrganization: Organization;
  /** Updates the admins of organization. `remove` should be a list of member IDs. */
  updateOrganizationAdmins: Scalars['Boolean'];
  /** Updates the organization password. */
  updateOrganizationPassword: Scalars['Boolean'];
  /** Updates the voting parameters of organization. */
  updateOrganizationVotingParameters: Scalars['Boolean'];
  updateParametersOZ: Scalars['Boolean'];
  updateProposal: Proposal;
  /** We are able to use updateSafe to change a gnosis safe name. */
  updateSafe: Scalars['Boolean'];
  updateUnistakerTransaction: Scalars['Boolean'];
  upload: File;
  upsertDelegateProfile: DelegateStatement;
};


export type MutationAddContractsToOrganizationArgs = {
  input: AddContractsToOrganizationInput;
};


export type MutationAddCouncilArgs = {
  input: AddCouncilInput;
};


export type MutationAddWhitelabelDomainArgs = {
  domain: Scalars['String'];
};


export type MutationArchiveProposalArgs = {
  originalId: Scalars['IntID'];
};


export type MutationCreateApiKeyArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCastVoteMetaTransactionArgs = {
  address: Scalars['Address'];
  gasPrice: Scalars['Uint256'];
  governorId: Scalars['AccountID'];
  proposalId: Scalars['ID'];
  support: SupportType;
  transactionId: Scalars['String'];
  validUntil: Scalars['Timestamp'];
};


export type MutationCreateClaimAndDelegateAttemptArgs = {
  delegateeId: Scalars['AccountID'];
  delegatorId: Scalars['AccountID'];
  expiry: Scalars['Uint256'];
  parameterR: Scalars['Bytes32'];
  parameterS: Scalars['Bytes32'];
  parameterV: Scalars['Uint256'];
  proof?: InputMaybe<Array<Scalars['String']>>;
  tokenId: Scalars['AssetID'];
  txID: Scalars['HashID'];
};


export type MutationCreateCloudEventArgs = {
  cloudEventInput: CloudEventInput;
};


export type MutationCreateDelegateMetaTransactionArgs = {
  address: Scalars['Address'];
  delegatee: Scalars['Address'];
  from: Scalars['Address'];
  gasPrice: Scalars['Uint256'];
  governorId: Scalars['AccountID'];
  tokenContractId: Scalars['AssetID'];
  transactionId: Scalars['String'];
  validUntil: Scalars['Timestamp'];
};


export type MutationCreateDelegationAttemptArgs = {
  delegateeId: Scalars['AccountID'];
  delegatorId: Scalars['AccountID'];
  governanceId?: InputMaybe<Scalars['AccountID']>;
  tokenId?: InputMaybe<Scalars['AssetID']>;
  txID: Scalars['Bytes32'];
};


export type MutationCreateIssueArgs = {
  input: IssueInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateProposalArgs = {
  input: CreateProposalInput;
};


export type MutationCreateProposalActionAttemptArgs = {
  input: CreateProposalActionAttemptInput;
};


export type MutationCreateSafeArgs = {
  id: Scalars['AccountID'];
  name?: InputMaybe<Scalars['String']>;
  organization: Scalars['ID'];
};


export type MutationCreateSafeV2Args = {
  input: CreateSafeInput;
};


export type MutationCreateUnistakerTransactionArgs = {
  input: CreateUnistakerTransactionInput;
};


export type MutationCreateVoteAttemptArgs = {
  input: CreateVoteAttemptInput;
};


export type MutationDeleteIssueArgs = {
  issueId: Scalars['IntID'];
};


export type MutationDeleteSyncArgs = {
  accountID: Scalars['AccountID'];
};


export type MutationDisableWhitelabelDomainArgs = {
  domain: Scalars['String'];
};


export type MutationJoinOrganizationArgs = {
  input: JoinOrganizationInput;
};


export type MutationLinkGovernorArgs = {
  input: LinkGovernorInput;
};


export type MutationLoginArgs = {
  message: Scalars['String'];
  signInType: SignInType;
  signature: Scalars['String'];
};


export type MutationLoginAsSafeArgs = {
  id?: InputMaybe<Scalars['AccountID']>;
};


export type MutationPauseSyncArgs = {
  id: Scalars['AccountID'];
};


export type MutationRegisterAsContenderAttemptArgs = {
  address: Scalars['String'];
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  hash: Scalars['String'];
  statement?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveAccountEnsArgs = {
  id: Scalars['AccountID'];
};


export type MutationRemoveAccountTwitterArgs = {
  id: Scalars['AccountID'];
};


export type MutationRemoveOrganizationArgs = {
  input: RemoveOrgsInput;
};


export type MutationRemoveSuperAdminArgs = {
  input: RemoveSuperAdminInput;
};


export type MutationRestoreProposalDraftArgs = {
  id: Scalars['IntID'];
};


export type MutationResumeSyncArgs = {
  id: Scalars['AccountID'];
};


export type MutationSetArbitrumProposalExecutedArgs = {
  input: SetArbitrumProposalExecutedInput;
};


export type MutationUnlinkGnosisSafeArgs = {
  id: Scalars['AccountID'];
};


export type MutationUpdateAccountArgs = {
  bio?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  identities?: InputMaybe<IdentitiesInput>;
  name?: InputMaybe<Scalars['String']>;
  otherLinks?: InputMaybe<Array<InputMaybe<OtherLinkInput>>>;
  picture?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAccountByIdArgs = {
  bio?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['AccountID'];
  identities?: InputMaybe<IdentitiesInput>;
  name?: InputMaybe<Scalars['String']>;
  otherLinks?: InputMaybe<Array<InputMaybe<OtherLinkInput>>>;
  picture?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCandidateProfileArgs = {
  address: Scalars['String'];
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  statement?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateChainArgs = {
  blockExplorerURL?: InputMaybe<Scalars['String']>;
  id: Scalars['ChainID'];
  rpcURL?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateFeatureArgs = {
  accountID?: InputMaybe<Scalars['AccountID']>;
  chainID?: InputMaybe<Scalars['ChainID']>;
  governanceID?: InputMaybe<Scalars['AccountID']>;
  name: Scalars['String'];
  organizationID?: InputMaybe<Scalars['ID']>;
  value: Scalars['Boolean'];
};


export type MutationUpdateGovernorArgs = {
  input: UpdateGovernorInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationAdminsArgs = {
  input: OrganizationAdminsInput;
};


export type MutationUpdateOrganizationPasswordArgs = {
  input: OrganizationPasswordInput;
};


export type MutationUpdateOrganizationVotingParametersArgs = {
  input: OrganizationVotingParametersInput;
};


export type MutationUpdateProposalArgs = {
  input: UpdateProposalInput;
};


export type MutationUpdateSafeArgs = {
  id: Scalars['AccountID'];
  name: Scalars['String'];
};


export type MutationUpdateUnistakerTransactionArgs = {
  input: UpdateUnistakerTransactionInput;
};


export type MutationUploadArgs = {
  file: UploadFile;
};


export type MutationUpsertDelegateProfileArgs = {
  input: UpsertDelegateProfileInput;
};

export type NativeCurrency = {
  /** Decimals of the Currency. e.g.: 18 */
  decimals: Scalars['Int'];
  /** Name of the Currency. e.g.: Ether */
  name: Scalars['String'];
  /** Symbol of the Currency. e.g.: ETH */
  symbol: Scalars['String'];
};

/** Union of all node types that are paginated. */
export type Node = Delegate | Delegation | Governor | Member | Organization | Proposal | StakeEarning | StakeEvent | Vote;

export type NominationRound = Round & {
  availableVotes: Scalars['Uint256'];
  contenderRegistrationStart: Block;
  contenders: Array<Contender>;
  end: Block;
  endNomineeVotingPeriod: Block;
  id: Scalars['ProposalID'];
  start: Block;
  status: RoundStatus;
  threshold?: Maybe<Scalars['Uint256']>;
  vettingDuration: Scalars['Uint256'];
};


export type NominationRoundAvailableVotesArgs = {
  address: Scalars['String'];
};


export type NominationRoundContendersArgs = {
  filter?: InputMaybe<ContenderFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CandidateSort>;
};

export type Nominee = Candidate & {
  account: Account;
  accountElectionMeta: AccountElectionMeta;
  id: Scalars['ID'];
  totalVoters: Scalars['Int'];
  totalVotes: Scalars['Uint256'];
  votes: Array<CandidateVote>;
};


export type NomineeVotesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type NonceOutput = {
  /** Nonce expiration time; this is same as expirationTime in siwe message */
  expirationTime: Scalars['Timestamp'];
  /** Nonce issued time; this is same as issuedAt in siwe message */
  issuedAt: Scalars['Timestamp'];
  nonce: Scalars['String'];
  /** Pass this token as the 'Nonce' header in the login request. This is temporary until we figure out cookie usage */
  nonceToken: Scalars['String'];
};

export type Organization = {
  /** Can only be accessed by a TallyAdmin or Organization Admin */
  adminData?: Maybe<OrganizationAdminData>;
  chainIds: Array<Scalars['ChainID']>;
  creator?: Maybe<Account>;
  delegatesCount: Scalars['Int'];
  delegatesVotesCount: Scalars['Uint256'];
  features?: Maybe<Array<FeatureState>>;
  governorIds: Array<Scalars['AccountID']>;
  hasActiveProposals: Scalars['Boolean'];
  id: Scalars['IntID'];
  metadata?: Maybe<OrganizationMetadata>;
  myRole?: Maybe<OrganizationRole>;
  name: Scalars['String'];
  proposalsCount: Scalars['Int'];
  requiresPasswordToJoin: Scalars['Boolean'];
  slug: Scalars['String'];
  tokenIds: Array<Scalars['AssetID']>;
  tokenOwnersCount: Scalars['Int'];
};

export type OrganizationAdminData = {
  contact?: Maybe<Contact>;
  password?: Maybe<Scalars['String']>;
};

export type OrganizationAdminsInput = {
  add?: InputMaybe<Array<AddAdminInput>>;
  id: Scalars['IntID'];
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type OrganizationInput = {
  id?: InputMaybe<Scalars['IntID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type OrganizationIssueInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type OrganizationIssuesInput = {
  filters?: InputMaybe<IssuesFiltersInput>;
};

export type OrganizationMembersFiltersInput = {
  organizationId: Scalars['IntID'];
  roles?: InputMaybe<Array<OrganizationRole>>;
};

export type OrganizationMembersInput = {
  filters: OrganizationMembersFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<OrganizationMembersSortInput>;
};

export enum OrganizationMembersSortBy {
  Id = 'id'
}

export type OrganizationMembersSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: OrganizationMembersSortBy;
};

export type OrganizationMetadata = {
  color?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  /** Name of this Organization in the Karma API, also if set, it signals to the FE to fetch data from karma */
  karmaName?: Maybe<Scalars['String']>;
  socials?: Maybe<Socials>;
};

export type OrganizationMetadataInput = {
  color?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ContactInput>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  socials?: InputMaybe<SocialsInput>;
};

export type OrganizationPasswordInput = {
  id: Scalars['IntID'];
  password: Scalars['String'];
};

export enum OrganizationRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Superadmin = 'SUPERADMIN'
}

export type OrganizationVotingParametersInput = {
  id: Scalars['IntID'];
  proposalThreshold?: InputMaybe<Scalars['Uint256']>;
  quorum?: InputMaybe<Scalars['Uint256']>;
  role?: InputMaybe<OrganizationRole>;
  votingPeriod?: InputMaybe<Scalars['Int']>;
};

export type OrganizationsFiltersInput = {
  address?: InputMaybe<Scalars['Address']>;
  chainId?: InputMaybe<Scalars['ChainID']>;
  hasLogo?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether the user holds any of the governance tokens associated with the organization. */
  isMember?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<OrganizationRole>>;
};

export type OrganizationsInput = {
  filters?: InputMaybe<OrganizationsFiltersInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<OrganizationsSortInput>;
};

export enum OrganizationsSortBy {
  /** Sorts by live proposals and voters as on the Tally explore page. */
  Explore = 'explore',
  /** The default sorting method. It sorts by date. */
  Id = 'id',
  Name = 'name',
  /** Same as explore but does not prioritize live proposals. */
  Popular = 'popular'
}

export type OrganizationsSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: OrganizationsSortBy;
};

export type OtherLink = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OtherLinkInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

/** Page metadata including pagination cursors and total count */
export type PageInfo = {
  /**
   * Total number of items across all pages.
   * FYI, this is not yet implemented so the value will always be 0
   */
  count?: Maybe<Scalars['Int']>;
  /** Cursor of the first item in the page */
  firstCursor?: Maybe<Scalars['String']>;
  /** Cursor of the last item in the page */
  lastCursor?: Maybe<Scalars['String']>;
};

/**
 * Input to specify cursor based pagination parameters.
 * Depending on which page is being fetched, between `afterCursor` & `beforeCursor`,
 * only one's value needs to be provided
 */
export type PageInput = {
  /** Cursor to start pagination after to fetch the next page */
  afterCursor?: InputMaybe<Scalars['String']>;
  /** Cursor to start pagination before to fetch the previous page */
  beforeCursor?: InputMaybe<Scalars['String']>;
  /**
   * Maximum number of items per page
   * 20 is the hard limit set on the backend
   */
  limit?: InputMaybe<Scalars['Int']>;
};

/** Wraps a list of nodes and the pagination info */
export type PaginatedOutput = {
  /** List of nodes for the page */
  nodes: Array<Node>;
  /** Pagination information */
  pageInfo: PageInfo;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Parameter = {
  name: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['Any'];
  valueDecoded?: Maybe<Array<ValueDecoded>>;
};

export type PriceChecker = {
  feePath: Array<Scalars['Uint256']>;
  slippage: Scalars['Uint256'];
  tokenPath: Array<Scalars['String']>;
  /** List of Uniswap pool ids. */
  uniPoolPath?: Maybe<Array<Scalars['String']>>;
};

export type Proposal = {
  block?: Maybe<Block>;
  chainId: Scalars['ChainID'];
  createdAt: Scalars['Timestamp'];
  /** `Account` that submitted this proposal onchain */
  creator?: Maybe<Account>;
  /** Delegated votes count of a given address on this proposal */
  delegateVotesCount: Scalars['Uint256'];
  /** Last block or timestamp when you can cast a vote */
  end: BlockOrTimestamp;
  /** List of state transitions for this proposal. The last `ProposalEvent` is the current state. */
  events: Array<ProposalEvent>;
  executableCalls?: Maybe<Array<ExecutableCall>>;
  governor: Governor;
  /** Tally ID */
  id: Scalars['IntID'];
  l1ChainId?: Maybe<Scalars['ChainID']>;
  metadata: ProposalMetadata;
  /** ID onchain */
  onchainId?: Maybe<Scalars['String']>;
  organization: Organization;
  originalId?: Maybe<Scalars['IntID']>;
  participationType: ProposalParticipationType;
  /** `Account` that created this proposal offchain */
  proposer?: Maybe<Account>;
  quorum?: Maybe<Scalars['Uint256']>;
  /** First block when you can cast a vote, also the time when quorum is established */
  start: BlockOrTimestamp;
  status: ProposalStatus;
  /** List of `ChainID`s that this proposal is bridged to and thus votable on */
  votableChains: Array<Scalars['ChainID']>;
  voteStats?: Maybe<Array<VoteStats>>;
};


export type ProposalDelegateVotesCountArgs = {
  address: Scalars['Address'];
};


export type ProposalParticipationTypeArgs = {
  address: Scalars['Address'];
};

export type ProposalActionAttempt = {
  actor: Account;
  chainId: Scalars['ChainID'];
  proposal: Proposal;
  txHash: Scalars['Hash'];
  type: ProposalActionType;
};

export type ProposalActionAttemptInput = {
  proposalId: Scalars['IntID'];
  type: ProposalActionType;
};

export enum ProposalActionType {
  Cancel = 'cancel',
  Execute = 'execute',
  Queue = 'queue'
}

export type ProposalEvent = {
  block?: Maybe<Block>;
  chainId: Scalars['ChainID'];
  createdAt: Scalars['Timestamp'];
  txHash?: Maybe<Scalars['Hash']>;
  type: ProposalEventType;
};

export enum ProposalEventType {
  Activated = 'activated',
  Callexecuted = 'callexecuted',
  Canceled = 'canceled',
  Created = 'created',
  Crosschainexecuted = 'crosschainexecuted',
  Defeated = 'defeated',
  Drafted = 'drafted',
  Executed = 'executed',
  Expired = 'expired',
  Extended = 'extended',
  Pendingexecution = 'pendingexecution',
  Queued = 'queued',
  Succeeded = 'succeeded'
}

export type ProposalInput = {
  governorId?: InputMaybe<Scalars['AccountID']>;
  id?: InputMaybe<Scalars['IntID']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  isLatest?: InputMaybe<Scalars['Boolean']>;
  /** this is not unique across governors; so must be used in combination with `governorId` */
  onchainId?: InputMaybe<Scalars['String']>;
};

export type ProposalMetadata = {
  /** Proposal description onchain */
  description: Scalars['String'];
  discourseURL?: Maybe<Scalars['String']>;
  /** Time at which a proposal can be executed */
  eta?: Maybe<Scalars['Int']>;
  ipfsHash?: Maybe<Scalars['String']>;
  previousEnd?: Maybe<Scalars['Int']>;
  snapshotURL?: Maybe<Scalars['String']>;
  timelockId?: Maybe<Scalars['AccountID']>;
  /** Proposal title: usually first line of description */
  title: Scalars['String'];
  txHash?: Maybe<Scalars['Hash']>;
};

export enum ProposalParticipationType {
  Notdelegate = 'notdelegate',
  Notvoted = 'notvoted',
  Unknown = 'unknown',
  Votedabstain = 'votedabstain',
  Votedagainst = 'votedagainst',
  Votedfor = 'votedfor'
}

/** Type that describes a security check related to a saved proposal */
export type ProposalSecurityCheck = {
  createdAt: Scalars['Timestamp'];
  /** JSON metadata of the security check */
  metadata: ActionsSecurityCheck;
};

export type ProposalSecurityCheckInput = {
  executableCalls: Array<ExecutableCallInput>;
  governorId: Scalars['AccountID'];
  proposer: Scalars['AccountID'];
  value?: InputMaybe<Scalars['Uint256']>;
};

export type ProposalStats = {
  /** Total count of active proposals */
  active: Scalars['Int'];
  /** Total count of failed proposals including quorum not reached */
  failed: Scalars['Int'];
  /** Total count of passed proposals */
  passed: Scalars['Int'];
  /** Total count of proposals */
  total: Scalars['Int'];
};

export enum ProposalStatus {
  Active = 'active',
  Archived = 'archived',
  Callexecuted = 'callexecuted',
  Canceled = 'canceled',
  Crosschainexecuted = 'crosschainexecuted',
  Defeated = 'defeated',
  Draft = 'draft',
  Executed = 'executed',
  Expired = 'expired',
  Extended = 'extended',
  Pending = 'pending',
  Pendingexecution = 'pendingexecution',
  Queued = 'queued',
  Submitted = 'submitted',
  Succeeded = 'succeeded'
}

export type ProposalsCreatedCountInput = {
  governorId?: InputMaybe<Scalars['AccountID']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
};

export type ProposalsFiltersInput = {
  governorId?: InputMaybe<Scalars['AccountID']>;
  /** Only drafts can be archived; so, this works ONLY with `isDraft: true` */
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['IntID']>;
  /** Address that created the proposal offchain; in other words, created the draft */
  proposer?: InputMaybe<Scalars['Address']>;
};

export type ProposalsInput = {
  filters: ProposalsFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<ProposalsSortInput>;
};

export enum ProposalsSortBy {
  /** The default sorting method. It sorts by date. */
  Id = 'id'
}

export type ProposalsSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: ProposalsSortBy;
};

export type Query = {
  account: Account;
  accountByEns: Account;
  accountV2: Account;
  accounts: Array<Account>;
  actionSecurityCheck: SingleActionSecurityCheck;
  actionsSecurityCheck: ActionsSecurityCheck;
  address: AddressInfo;
  /** Returns tokens that can be swapped from the governor's treasury via the Tally Swap proposal recipe. */
  availableSwaps: SwapAssets;
  balances: Array<BalanceItem>;
  /** Returns the `Block` including an actual or estimated timestamp given a `BlockID`. */
  block: Block;
  candidateEmails: Array<CandidateExport>;
  chains: Array<Maybe<Chain>>;
  claimAndDelegateAttempt?: Maybe<ClaimAndDelegateAttempt>;
  competencyFields: Array<CompetencyField>;
  contender: Contender;
  contractAbi: Array<AbiElement>;
  council: Council;
  councilSlugToId: Scalars['AccountID'];
  councils: Array<Council>;
  /** Returns delegate information by an address for an organization or governor. */
  delegate?: Maybe<Delegate>;
  /** Returns a delegatee of a user, to whom this user has delegated, for a governor */
  delegatee?: Maybe<Delegation>;
  /** Returns a paginated list of delegatees of a user, to whom this user has delegated, that match the provided filters. */
  delegatees: PaginatedOutput;
  /** Returns a paginated list of delegates that match the provided filters. */
  delegates: PaginatedOutput;
  delegationAttempt?: Maybe<DelegationAttempt>;
  /** Returns a paginated list of delegators of a delegate that match the provided filters. */
  delegators: PaginatedOutput;
  election: Election;
  endorsementService: EndorsementService;
  generateAdminToolToken: Scalars['String'];
  /** Returns any `GnosisSafe`'s info given a chain scoped `AccountID`. */
  gnosisSafe: GnosisSafe;
  gnosisSafeTransaction: GnosisSafeTransaction;
  /** Returns a list of multisig tranasctions given a safe `AccountID`.  `Pagniation` defaults to a limit of 20 transactions if no limit is provided.  There are a number of filters and ordering settings we can support, please reach out to discuss. */
  gnosisSafeTransactions: Array<GnosisSafeTransaction>;
  /** This will return a list of `GnosisSafe`s related to a DAO along with `GnosisSafe` info similar to the governances query. */
  gnosisSafes: Array<GnosisSafe>;
  gnosisSafesV2: Array<GnosisSafe>;
  /** Returns governor by ID or slug. */
  governor: Governor;
  /** Returns a paginated list of governors that match the provided filters.  Note: Tally may deactivate governors from time to time. If you wish to include those set `includeInactive` to `true`. */
  governors: PaginatedOutput;
  issues?: Maybe<Array<Maybe<Issue>>>;
  latestForumActivity: ForumActivity;
  me: Account;
  memberRound: MemberRound;
  metaTransactions?: Maybe<Array<MetaTransaction>>;
  nominationRound: NominationRound;
  nominee: Nominee;
  nonce: NonceOutput;
  /** Returns organization by ID or slug. */
  organization: Organization;
  organizationMembers: PaginatedOutput;
  organizationSlugToId: Scalars['IntID'];
  /** Returns a paginated list of organizations that match the provided filters. */
  organizations: PaginatedOutput;
  /** Returns a proposal by ID or onchainId + governorId. Also retruns latest draft version by ID. */
  proposal: Proposal;
  proposalActionAttempt: ProposalActionAttempt;
  proposalSecurityCheck: ProposalSecurityCheck;
  proposalWithVersions: Array<Proposal>;
  /** Returns a paginated list of proposals that match the provided filters. */
  proposals: PaginatedOutput;
  /** Returns a quote for a swap. */
  quoteSwap: SwapQuote;
  searchOrganization: Array<Organization>;
  stakeEarnings: PaginatedOutput;
  stakeEvents: PaginatedOutput;
  stakeStats: StakeStats;
  token: Token;
  /** Returns all token balances of an address for a governor or organization or token */
  tokenBalances: Array<TokenBalance>;
  transactionAttempts: Array<TransactionAttempt>;
  unistakerTransactions: Array<UnistakerTransaction>;
  validateNewGovernor: ValidateNewGovernorOutput;
  voteAttempt?: Maybe<VoteAttempt>;
  /** Returns a paginated list of votes that match the provided filters. */
  votes: PaginatedOutput;
  whitelabelDomains?: Maybe<Array<Scalars['String']>>;
};


export type QueryAccountArgs = {
  id: Scalars['AccountID'];
};


export type QueryAccountByEnsArgs = {
  ens: Scalars['String'];
};


export type QueryAccountV2Args = {
  id: Scalars['Address'];
};


export type QueryAccountsArgs = {
  addresses?: InputMaybe<Array<Scalars['Address']>>;
  ids?: InputMaybe<Array<Scalars['AccountID']>>;
};


export type QueryActionSecurityCheckArgs = {
  input: SingleActionSecurityCheckInput;
};


export type QueryActionsSecurityCheckArgs = {
  input: ProposalSecurityCheckInput;
};


export type QueryAddressArgs = {
  address: Scalars['Address'];
};


export type QueryAvailableSwapsArgs = {
  governorID: Scalars['AccountID'];
};


export type QueryBalancesArgs = {
  accountID: Scalars['AccountID'];
};


export type QueryBlockArgs = {
  id: BlockIdInput;
};


export type QueryCandidateEmailsArgs = {
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  round: Scalars['Int'];
};


export type QueryClaimAndDelegateAttemptArgs = {
  delegatorId: Scalars['AccountID'];
  tokenId: Scalars['AssetID'];
};


export type QueryCompetencyFieldsArgs = {
  input: CompetencyFieldsInput;
};


export type QueryContenderArgs = {
  address?: InputMaybe<Scalars['String']>;
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  ens?: InputMaybe<Scalars['String']>;
};


export type QueryContractAbiArgs = {
  id: Scalars['AccountID'];
};


export type QueryCouncilArgs = {
  slug: Scalars['String'];
};


export type QueryCouncilSlugToIdArgs = {
  slug: Scalars['String'];
};


export type QueryCouncilsArgs = {
  tokenId: Scalars['AssetID'];
};


export type QueryDelegateArgs = {
  input: DelegateInput;
};


export type QueryDelegateeArgs = {
  input: DelegationInput;
};


export type QueryDelegateesArgs = {
  input: DelegationsInput;
};


export type QueryDelegatesArgs = {
  input: DelegatesInput;
};


export type QueryDelegationAttemptArgs = {
  delegatorId: Scalars['AccountID'];
  governanceId: Scalars['AccountID'];
};


export type QueryDelegatorsArgs = {
  input: DelegationsInput;
};


export type QueryElectionArgs = {
  councilSlug: Scalars['String'];
  number: Scalars['Int'];
};


export type QueryEndorsementServiceArgs = {
  input: EndorsementServiceInput;
};


export type QueryGnosisSafeArgs = {
  id: Scalars['AccountID'];
};


export type QueryGnosisSafeTransactionArgs = {
  safeTxHashID: Scalars['HashID'];
};


export type QueryGnosisSafeTransactionsArgs = {
  allTransactions?: InputMaybe<Scalars['Boolean']>;
  gnosisSafeId: Scalars['AccountID'];
  pagination?: InputMaybe<Pagination>;
};


export type QueryGnosisSafesArgs = {
  organizationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryGnosisSafesV2Args = {
  input?: InputMaybe<GnosisSafesInput>;
};


export type QueryGovernorArgs = {
  input: GovernorInput;
};


export type QueryGovernorsArgs = {
  input: GovernorsInput;
};


export type QueryIssuesArgs = {
  input?: InputMaybe<IssuesInput>;
};


export type QueryLatestForumActivityArgs = {
  input: OrganizationInput;
};


export type QueryMemberRoundArgs = {
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
};


export type QueryMetaTransactionsArgs = {
  action: MetaTransactionAction;
  address?: InputMaybe<Scalars['Address']>;
  governorId?: InputMaybe<Scalars['AccountID']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<MetaTransactionSort>;
};


export type QueryNominationRoundArgs = {
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
};


export type QueryNomineeArgs = {
  address?: InputMaybe<Scalars['String']>;
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  ens?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationArgs = {
  input: OrganizationInput;
};


export type QueryOrganizationMembersArgs = {
  input: OrganizationMembersInput;
};


export type QueryOrganizationSlugToIdArgs = {
  slug: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  input?: InputMaybe<OrganizationsInput>;
};


export type QueryProposalArgs = {
  input: ProposalInput;
};


export type QueryProposalActionAttemptArgs = {
  input: ProposalActionAttemptInput;
};


export type QueryProposalSecurityCheckArgs = {
  proposalId: Scalars['ID'];
};


export type QueryProposalWithVersionsArgs = {
  input: ProposalInput;
};


export type QueryProposalsArgs = {
  input: ProposalsInput;
};


export type QueryQuoteSwapArgs = {
  buy: Scalars['AccountID'];
  governorID: Scalars['AccountID'];
  sell: Scalars['AccountID'];
  sellAmount: Scalars['Uint256'];
};


export type QuerySearchOrganizationArgs = {
  input: SearchOrganizationInput;
};


export type QueryStakeEarningsArgs = {
  input: StakeInput;
};


export type QueryStakeEventsArgs = {
  input: StakeInput;
};


export type QueryStakeStatsArgs = {
  input: StakeInput;
};


export type QueryTokenArgs = {
  input: TokenInput;
};


export type QueryTokenBalancesArgs = {
  input: TokenBalancesInput;
};


export type QueryTransactionAttemptsArgs = {
  input: TransactionAttemptsInput;
};


export type QueryUnistakerTransactionsArgs = {
  input: UnistakerTransactionsInput;
};


export type QueryValidateNewGovernorArgs = {
  input: ValidateNewGovernorInput;
};


export type QueryVoteAttemptArgs = {
  input: VoteAttemptInput;
};


export type QueryVotesArgs = {
  input: VotesInput;
};

export type RecentParticipationStatsInput = {
  governorID: Scalars['AccountID'];
};

export type RemoveOrgsInput = {
  organizationIds: Array<Scalars['IntID']>;
};

export type RemoveSuperAdminInput = {
  accountId: Scalars['AccountID'];
  organizationId: Scalars['IntID'];
};

export type ResyncInput = {
  governorIds: Array<Scalars['AccountID']>;
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type Round = {
  availableVotes: Scalars['Uint256'];
  end: Block;
  id: Scalars['ProposalID'];
  start: Block;
  status: RoundStatus;
};


export type RoundAvailableVotesArgs = {
  address: Scalars['String'];
};

export enum RoundStatus {
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Executed = 'EXECUTED',
  Pending = 'PENDING'
}

export type SafeTokenBalance = {
  address?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  decimals: Scalars['Int'];
  fiat: Scalars['String'];
  logoURI: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type SearchOrganizationFiltersInput = {
  chainId?: InputMaybe<Scalars['ChainID']>;
};

export type SearchOrganizationInput = {
  filters?: InputMaybe<SearchOrganizationFiltersInput>;
  name: Scalars['String'];
};

export type SecurityAnalysisV2 = {
  dataPoints: Array<AnalysisDataPointV2>;
};

export type SetArbitrumProposalExecutedInput = {
  OnchainProposalId: Scalars['String'];
  blockNumber: Scalars['Int'];
  /** unix timestamp in seconds */
  blockTimestamp: Scalars['Int'];
  txHash: Scalars['Hash'];
};

export enum SignInType {
  Evm = 'evm',
  Solana = 'solana'
}

export enum SimulationStatus {
  Failed = 'failed',
  Success = 'success'
}

/** Security check for a single action */
export type SingleActionSecurityCheck = {
  metadata: SingleActionSecurityCheckMetadata;
  simulation: TransactionSimulationV2;
};

export type SingleActionSecurityCheckInput = {
  executableCall: ExecutableCallInput;
  governorId: Scalars['AccountID'];
  proposer?: InputMaybe<Scalars['AccountID']>;
  value?: InputMaybe<Scalars['Uint256']>;
};

/** Metadata for a single action security check */
export type SingleActionSecurityCheckMetadata = {
  contractVerification?: Maybe<ContractVerificationV2>;
  securityAnalysis?: Maybe<SecurityAnalysisV2>;
};

export type Socials = {
  discord?: Maybe<Scalars['String']>;
  discourse?: Maybe<Scalars['String']>;
  others?: Maybe<Array<Maybe<OtherLink>>>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type SocialsInput = {
  discord?: InputMaybe<Scalars['String']>;
  discourse?: InputMaybe<Scalars['String']>;
  others?: InputMaybe<Array<InputMaybe<OtherLinkInput>>>;
  telegram?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpectaQlOption = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type StakeEarning = {
  amount: Scalars['Uint256'];
  date: Scalars['Date'];
};

export type StakeEvent = {
  amount: Scalars['Uint256'];
  block: Block;
  type: StakeEventType;
};

export enum StakeEventType {
  Deposit = 'deposit',
  Withdraw = 'withdraw'
}

export type StakeInput = {
  stakedTokenAccountId: Scalars['AccountID'];
  staker: Scalars['Address'];
};

export type StakeStats = {
  estimatedEarningsRate?: Maybe<Scalars['Float']>;
  totalEarnings: Scalars['Uint256'];
};

/** Vote Choice */
export enum SupportType {
  Abstain = 'ABSTAIN',
  Against = 'AGAINST',
  For = 'FOR'
}

export type SwapAssets = {
  /** List of tokens that can be bought via the Tally Swap proposal recipe. */
  buy: Array<SwapToken>;
  /** List of tokens that can be sold via the Tally Swap proposal recipe. */
  sell: Array<BalanceItem>;
};

export type SwapMetaInput = {
  /** Tally fee */
  fee?: Maybe<Scalars['Uint256']>;
  /** List of Uniswap pool ids, describing price checker path. */
  uniPoolPath?: Maybe<Array<Scalars['String']>>;
};

export type SwapOrder = {
  /** Address of the order smart contract. */
  address?: Maybe<Scalars['String']>;
  /** Buy amount if status is fulfilled. */
  buyAmount?: Maybe<Scalars['Uint256']>;
  /** CoW order id if status is fulfilled. */
  id?: Maybe<Scalars['String']>;
  /** Status of the order. */
  status: SwapOrderStatus;
};

export enum SwapOrderStatus {
  Failed = 'FAILED',
  Fulfilled = 'FULFILLED',
  Pending = 'PENDING',
  PendingExecution = 'PENDING_EXECUTION'
}

export type SwapQuote = {
  buyAmount: Scalars['Uint256'];
  buyTokenQuoteRate?: Maybe<Scalars['Float']>;
  feeAmount: Scalars['Uint256'];
  sellAmount: Scalars['Uint256'];
  validTo: Scalars['Timestamp'];
};

export type SwapToken = {
  decimals: Scalars['Int'];
  id: Scalars['AccountID'];
  logo: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

/** Source of data: Hexagate. actionsData is the analysis per executable call, and proposerRisk is an opiniated value from Hexagate (e.g. High)  */
export type ThreatAnalysis = {
  actionsData: Array<Maybe<ActionThreatData>>;
  proposerRisk: Scalars['String'];
};

export enum TimeInterval {
  All = 'ALL',
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** Core type that describes an onchain Token contract */
export type Token = {
  /** Number of decimal places included in `Uint256` values */
  decimals: Scalars['Int'];
  /** Eligibility of an account to claim this token */
  eligibility: Eligibility;
  id: Scalars['AssetID'];
  isBehind: Scalars['Boolean'];
  isIndexing: Scalars['Boolean'];
  /** Last block that Tally has indexed.  Sometimes our indexer needs to catch up.  Our indexer is usually ~1min behind depending on chain so we don't serve data that might later be reorged. */
  lastIndexedBlock: Block;
  /** Onchain name */
  name: Scalars['String'];
  /** supply derived from `Transfer` events */
  supply: Scalars['Uint256'];
  /** Onchain symbol */
  symbol: Scalars['String'];
  /** Token contract type */
  type: TokenType;
};


/** Core type that describes an onchain Token contract */
export type TokenEligibilityArgs = {
  id: Scalars['AccountID'];
};

export type TokenBalance = {
  balance: Scalars['Uint256'];
  token: Token;
};

export type TokenBalancesInput = {
  address: Scalars['Address'];
  governorID?: InputMaybe<Scalars['AccountID']>;
  organizationID?: InputMaybe<Scalars['IntID']>;
  tokenId?: InputMaybe<Scalars['AssetID']>;
};

export type TokenContract = {
  address: Scalars['Address'];
  lastBlock: Scalars['Int'];
  type: TokenType;
};

export type TokenData = {
  data: CovalentData;
  id: Scalars['AccountID'];
};

export type TokenInput = {
  id: Scalars['AssetID'];
};

export enum TokenType {
  Erc20 = 'ERC20',
  Erc20Aave = 'ERC20AAVE',
  Erc721 = 'ERC721',
  Solanaspoketoken = 'SOLANASPOKETOKEN'
}

export type Transaction = {
  block: Block;
  id: Scalars['HashID'];
};

export type TransactionAttempt = {
  address: Scalars['Address'];
  chainId: Scalars['ChainID'];
  createdAt: Scalars['Timestamp'];
  id: Scalars['HashID'];
  tokenId?: Maybe<Scalars['AssetID']>;
  transactionType: TransactionType;
  updatedAt: Scalars['Timestamp'];
};

export type TransactionAttemptsInput = {
  address: Scalars['Address'];
  tokenId?: InputMaybe<Scalars['AssetID']>;
  transactionType?: InputMaybe<TransactionType>;
};

/** Source of data: Tenderly. Array of simulations used for proposal-level checks */
export type TransactionSimulationArray = {
  simulations: Array<TransactionSimulationV2>;
};

/** V2 */
export type TransactionSimulationV2 = {
  publicURI: Scalars['String'];
  result: Scalars['String'];
};

export enum TransactionType {
  Delegation = 'delegation'
}

export type Treasury = {
  tokens: Array<SafeTokenBalance>;
  totalUSDValue: Scalars['String'];
};

export type TwitterIdentity = {
  nonce: Scalars['Int'];
  url: Scalars['String'];
};

export type UnistakerTransaction = {
  address: Scalars['Address'];
  beneficiary?: Maybe<Scalars['Address']>;
  createdAt: Scalars['Timestamp'];
  delegatee?: Maybe<Scalars['Address']>;
  depositId?: Maybe<Scalars['String']>;
  id: Scalars['HashID'];
  newAmount?: Maybe<Scalars['Uint256']>;
  previousAmount?: Maybe<Scalars['Uint256']>;
  status: UnistakerTransactionStatus;
  type: UnistakerTransactionType;
  updatedAt: Scalars['Timestamp'];
};

export enum UnistakerTransactionStatus {
  Failed = 'failed',
  Indexed = 'indexed',
  Pending = 'pending',
  Timeout = 'timeout'
}

export enum UnistakerTransactionType {
  Alterbeneficiary = 'alterbeneficiary',
  Alterdelegatee = 'alterdelegatee',
  Delete = 'delete',
  Multicall = 'multicall',
  Stake = 'stake',
  Stakemore = 'stakemore',
  Withdraw = 'withdraw'
}

export type UnistakerTransactionsInput = {
  accountId: Scalars['AccountID'];
  status?: InputMaybe<UnistakerTransactionStatus>;
};

export type UpdateGovernorInput = {
  id: Scalars['AccountID'];
  metadata?: InputMaybe<GovernorMetadataInput>;
};

export type UpdateOrganizationInput = {
  id: Scalars['IntID'];
  metadata?: InputMaybe<OrganizationMetadataInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateProposalInput = {
  id: Scalars['IntID'];
  status: ProposalStatus;
  txHash: Scalars['Hash'];
};

export type UpdateUnistakerTransactionInput = {
  id: Scalars['HashID'];
  status: UnistakerTransactionStatus;
};

/** The `UploadFile` type, represents the request for uploading a file with a certain payload. */
export type UploadFile = {
  id: Scalars['Int'];
  upload: Scalars['Upload'];
};

export type UpsertDelegateProfileInput = {
  address?: InputMaybe<Scalars['Address']>;
  dataSourceURL?: InputMaybe<Scalars['String']>;
  discourseProfileLink?: InputMaybe<Scalars['String']>;
  discourseUsername?: InputMaybe<Scalars['String']>;
  hideDisclaimer?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  isSeekingDelegation?: InputMaybe<Scalars['Boolean']>;
  issueIds?: InputMaybe<Array<Scalars['IntID']>>;
  organizationId: Scalars['IntID'];
  statement: Scalars['String'];
  statementSummary?: InputMaybe<Scalars['String']>;
};

export type ValidateNewGovernorInput = {
  id: Scalars['AccountID'];
};

export type ValidateNewGovernorOutput = {
  startBlock: Scalars['Int'];
  tokenId: Scalars['AssetID'];
  tokenStartBlock: Scalars['Int'];
  type: GovernorType;
};

export type ValueDecoded = {
  data: Scalars['String'];
  dataDecoded?: Maybe<DataDecoded>;
  operation: Scalars['Int'];
  to: Scalars['String'];
  value: Scalars['String'];
};

export type Vote = {
  amount: Scalars['Uint256'];
  block: Block;
  chainId: Scalars['ChainID'];
  id: Scalars['IntID'];
  isBridged?: Maybe<Scalars['Boolean']>;
  proposal: Proposal;
  reason?: Maybe<Scalars['String']>;
  txHash: Scalars['Hash'];
  type: VoteType;
  voter: Account;
};

export type VoteAttempt = {
  chainId: Scalars['ChainID'];
  createdAt: Scalars['Timestamp'];
  proposal: Proposal;
  txHash: Scalars['Hash'];
  type: VoteType;
  voter: Account;
};

export type VoteAttemptInput = {
  proposalId: Scalars['IntID'];
  voter: Scalars['Address'];
};

/** Voting Summary per Choice */
export type VoteStats = {
  /** Percent of votes casted for this Choice/`Votetype' */
  percent: Scalars['Float'];
  type: VoteType;
  /** Total number of distinct voters for this Choice/`VoteType` */
  votersCount: Scalars['Int'];
  /** Total votes casted for this Choice/`VoteType` */
  votesCount: Scalars['Uint256'];
};

export enum VoteType {
  Abstain = 'abstain',
  Against = 'against',
  For = 'for',
  Pendingabstain = 'pendingabstain',
  Pendingagainst = 'pendingagainst',
  Pendingfor = 'pendingfor'
}

export type VotesFiltersInput = {
  includePendingVotes?: InputMaybe<Scalars['Boolean']>;
  proposalId?: InputMaybe<Scalars['IntID']>;
  proposalIds?: InputMaybe<Array<Scalars['IntID']>>;
  type?: InputMaybe<VoteType>;
  voter?: InputMaybe<Scalars['Address']>;
};

export type VotesInput = {
  filters: VotesFiltersInput;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<VotesSortInput>;
};

export enum VotesSortBy {
  Amount = 'amount',
  /** The default sorting method. It sorts by date. */
  Id = 'id'
}

export type VotesSortInput = {
  isDescending: Scalars['Boolean'];
  sortBy: VotesSortBy;
};

export type VotingParameters = {
  proposalThreshold?: Maybe<Scalars['Uint256']>;
  quorum?: Maybe<Scalars['Uint256']>;
  /** Role user needs to have to update the voting parameters. */
  requiredRole: OrganizationRole;
  /** Voting period defined in s, defaults to 172800 (2 days). */
  votingPeriod: Scalars['Int'];
};

/** Represents a voting power change over an interval or triggered by an event. */
export type VotingPowerChange = {
  /** The `delegate` address whose voting power is changing */
  delegate: Account;
  /** Net change in voting power caused by this event */
  netChange: Scalars['Uint256'];
  /** Voting power after this event or interval */
  newBalance: Scalars['Uint256'];
  /** Voting power prior to this event or interval */
  prevBalance: Scalars['Uint256'];
  /** Timestamp of event or beginging of the interval this voting power change represents */
  timestamp: Scalars['Timestamp'];
  token: Token;
  /** Transaction that triggered this voting change, unset if this is an interval */
  transaction?: Maybe<Transaction>;
};

export type CreatedProposalsQueryVariables = Exact<{
  input: ProposalsInput;
}>;


export type CreatedProposalsQuery = { proposals: { nodes: Array<{ id: string, onchainId?: string | null, originalId?: string | null, status: ProposalStatus, createdAt: string, governor: { id: string }, metadata: { description: string }, block?: { timestamp: string } | null, voteStats?: Array<{ votesCount: string, votersCount: number, type: VoteType, percent: number }> | null } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type AddressDaosProposalsQueryVariables = Exact<{
  input: ProposalsInput;
  address: Scalars['Address'];
}>;


export type AddressDaosProposalsQuery = { proposals: { nodes: Array<{ id: string, createdAt: string, onchainId?: string | null, originalId?: string | null, status: ProposalStatus, participationType: ProposalParticipationType, metadata: { description: string }, governor: { id: string }, block?: { timestamp: string } | null, proposer?: { address: string } | null, creator?: { address: string } | null, start: { timestamp: string } | { timestamp: string }, voteStats?: Array<{ votesCount: string, votersCount: number, type: VoteType, percent: number }> | null } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type AddressGovernancesDelegatorsQueryVariables = Exact<{
  input: DelegatesInput;
}>;


export type AddressGovernancesDelegatorsQuery = { delegates: { nodes: Array<{ id: string, delegatorsCount: number, votesCount: string, account: { name: string, address: string, ens?: string | null, picture?: string | null }, organization?: { id: string, name: string, tokenOwnersCount: number, delegatesVotesCount: string, slug: string, metadata?: { icon?: string | null } | null } | null } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type AddressGovernancesDelegateesQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type AddressGovernancesDelegateesQuery = { delegatees: { nodes: Array<{ votes: string, delegate: { name: string, address: string, ens?: string | null, picture?: string | null }, delegator: { name: string, address: string, ens?: string | null, picture?: string | null }, organization: { id: string, name: string, slug: string, delegatesVotesCount: string, metadata?: { icon?: string | null } | null }, token: { decimals: number, supply: string } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type AddressHeaderQueryVariables = Exact<{
  accountId: Scalars['AccountID'];
}>;


export type AddressHeaderQuery = { account: { address: string, bio: string, name: string, picture?: string | null, twitter?: string | null } };

export type AddressDelegatingToQueryVariables = Exact<{
  delegateeInput: DelegationInput;
}>;


export type AddressDelegatingToQuery = { delegatee?: { votes: string, delegate: { name: string, address: string, ens?: string | null, picture?: string | null }, token: { id: string, name: string, symbol: string, decimals: number } } | null };

export type DelegateInformationQueryVariables = Exact<{
  tokenBalancesInput: TokenBalancesInput;
}>;


export type DelegateInformationQuery = { tokenBalances: Array<{ balance: string }> };

export type ReceivedDelegationsGovernanceQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type ReceivedDelegationsGovernanceQuery = { delegators: { nodes: Array<{ chainId: string, blockNumber: number, blockTimestamp: string, votes: string, delegator: { address: string, name: string, picture?: string | null, twitter?: string | null, ens?: string | null } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type AddressTabPanelsStatsQueryVariables = Exact<{
  input: DelegateInput;
  proposalsCreatedCountInput: ProposalsCreatedCountInput;
  accountId: Scalars['AccountID'];
}>;


export type AddressTabPanelsStatsQuery = { account: { proposalsCreatedCount: number }, delegate?: { delegatorsCount: number, votesCount: string } | null };

export type AccountByEnsQueryVariables = Exact<{
  ens: Scalars['String'];
}>;


export type AccountByEnsQuery = { accountByEns: { id: string, address: string, ens?: string | null, name: string, bio: string, picture?: string | null, twitter?: string | null } };

export type AddressMetadataQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type AddressMetadataQuery = { address: { address: string, accounts: Array<{ id: string, address: string, ens?: string | null, name: string, bio: string, picture?: string | null }> } };

export type AddressSafesQueryVariables = Exact<{
  accountId: Scalars['AccountID'];
}>;


export type AddressSafesQuery = { account: { safes?: Array<string> | null } };

export type DelegateStatementQueryVariables = Exact<{
  input: DelegateInput;
}>;


export type DelegateStatementQuery = { delegate?: { statement?: { id: string, address: string, organizationID: string, statement: string, statementSummary?: string | null, dataSource: DelegateStatementSource, dataSourceURL?: string | null, discourseUsername?: string | null, discourseProfileLink?: string | null, isSeekingDelegation?: boolean | null, isMember?: boolean | null, hideDisclaimer?: boolean | null, issues?: Array<{ id: string, name?: string | null, organizationId?: string | null, description?: string | null }> | null } | null } | null };

export type AccountsCommentsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type AccountsCommentsQuery = { accounts: Array<{ id: string, address: string, name: string, picture?: string | null }> };

export type SearchOrgsQueryVariables = Exact<{
  input: SearchOrganizationInput;
}>;


export type SearchOrgsQuery = { searchOrganization: Array<{ id: string, slug: string, name: string, governorIds: Array<string>, tokenOwnersCount: number, tokenIds: Array<string>, chainIds: Array<string>, metadata?: { icon?: string | null } | null }> };

export type TokenBalancesQueryVariables = Exact<{
  input: Scalars['AccountID'];
}>;


export type TokenBalancesQuery = { balances: Array<{ name: string, symbol: string, address: string, logo: string, nativeToken: boolean, type: string, decimals: number, balance: string, balance24H: string, quoteRate?: number | null, quoteRate24H?: number | null, quote?: number | null, quote24H?: number | null }> };

export type LoginMutationVariables = Exact<{
  message: Scalars['String'];
  signature: Scalars['String'];
  signInType: SignInType;
}>;


export type LoginMutation = { login: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, bio: string, name: string, type: AccountType, address: string, email: string, picture?: string | null, ens?: string | null, twitter?: string | null, apiKeys?: Array<{ name: string, lastFour: string }> | null } };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = { upload: { url: string, id: string, metadata: { thumbnail?: string | null, url?: string | null } } };

export type CouncilQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CouncilQuery = { council: { id: string, slug: string, name: string, cohortSize: number, description: string } };

export type ElectionQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
}>;


export type ElectionQuery = { election: { number: number, status: ElectionStatus, nominationRound: { id: string, status: RoundStatus }, memberRound?: { id: string, status: RoundStatus } | null } };

export type NominationRoundQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
}>;


export type NominationRoundQuery = { nominationRound: { status: RoundStatus } };

export type MemberRoundQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
}>;


export type MemberRoundQuery = { memberRound: { status: RoundStatus } };

export type AccountElectionMetaQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
}>;


export type AccountElectionMetaQuery = { election: { accountElectionMeta: { hasRegistered: boolean, isContender: boolean } } };

export type ContenderQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
}>;


export type ContenderQuery = { contender: { id: string, totalVoters: number, totalVotes: string, nominated: boolean, rejected: boolean, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } } };

export type NomineeQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
}>;


export type NomineeQuery = { nominee: { id: string, totalVotes: string, totalVoters: number, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } } };

export type ContenderLayoutQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
}>;


export type ContenderLayoutQuery = { contender: { id: string, totalVotes: string, nominated: boolean, rejected: boolean, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { hasRegistered: boolean, isContender: boolean, title?: string | null, statement?: string | null }, votes: Array<{ weight: string, voter: { id: string, address: string, ens?: string | null, twitter?: string | null, name: string, email: string, bio: string, picture?: string | null } }> } };

export type CouncilCurrentMembersListQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CouncilCurrentMembersListQuery = { council: { members: { firstCohort: Array<{ address: string, name: string, picture?: string | null, id: string, bio: string, ens?: string | null }>, secondCohort: Array<{ address: string, name: string, picture?: string | null, id: string, bio: string, ens?: string | null }> } } };

export type CouncilElectionsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CouncilElectionsQuery = { council: { elections: Array<{ status: ElectionStatus, number: number, nominationRound: { status: RoundStatus, start: { ts: string }, end: { ts: string } }, memberRound?: { end: { ts: string } } | null }> } };

export type CouncilMembersQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CouncilMembersQuery = { council: { members: { firstCohort: Array<{ address: string, name: string, picture?: string | null, id: string, bio: string, ens?: string | null }>, secondCohort: Array<{ address: string, name: string, picture?: string | null, id: string, bio: string, ens?: string | null }> } } };

export type ElectionLayoutQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
}>;


export type ElectionLayoutQuery = { election: { number: number, status: ElectionStatus, nominationRound: { id: string, status: RoundStatus, vettingDuration: string, threshold?: string | null, endNomineeVotingPeriod: { ts: string }, start: { ts: string }, end: { ts: string }, contenderRegistrationStart: { ts: string } }, memberRound?: { status: RoundStatus, start: { ts: string }, end: { ts: string } } | null } };

export type GovernanceCouncilBannerQueryVariables = Exact<{
  tokenId: Scalars['AssetID'];
}>;


export type GovernanceCouncilBannerQuery = { councils: Array<{ slug: string, name: string, elections: Array<{ number: number, status: ElectionStatus, nominationRound: { status: RoundStatus, vettingDuration: string, end: { ts: string }, endNomineeVotingPeriod: { ts: string }, start: { ts: string } }, memberRound?: { fullWeightDuration: string, start: { ts: string }, end: { ts: string } } | null }> }> };

export type GovernanceCouncilsQueryVariables = Exact<{
  tokenId: Scalars['AssetID'];
}>;


export type GovernanceCouncilsQuery = { councils: Array<{ id: string, name: string, slug: string, elections: Array<{ status: ElectionStatus }> }> };

export type NomineeHeaderQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
  limit: Scalars['Int'];
  includeAddressData: Scalars['Boolean'];
}>;


export type NomineeHeaderQuery = { election: { memberRound?: { id: string, status: RoundStatus, availableVotes?: string, start: { ts: string }, end: { ts: string }, nominees: Array<{ id: string, totalVotes: string, account: { address: string } }> } | null } };

export type NomineeSupportersQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
}>;


export type NomineeSupportersQuery = { nominee: { totalVotes: string, totalVoters: number, votes: Array<{ weight: string, voter: { id: string, address: string, ens?: string | null, twitter?: string | null, name: string, email: string, bio: string, picture?: string | null } }> } };

export type UpdateCandidateProfileMutationVariables = Exact<{
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  address: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  statement?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCandidateProfileMutation = { updateCandidateProfile: boolean };

export type ContenderByAddressOrEnsQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  ens?: InputMaybe<Scalars['String']>;
  isAddress: Scalars['Boolean'];
}>;


export type ContenderByAddressOrEnsQuery = { contender?: { id: string, totalVotes: string, nominated: boolean, rejected: boolean, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } }, contenderByEns: { id: string, totalVotes: string, nominated: boolean, rejected: boolean, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } } };

export type ContenderElectionMetaQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
}>;


export type ContenderElectionMetaQuery = { contender: { accountElectionMeta: { title?: string | null, statement?: string | null, hasRegistered: boolean, isContender: boolean } } };

export type ElectionMemberRoundQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
  includeAddressData: Scalars['Boolean'];
  input: GovernorInput;
}>;


export type ElectionMemberRoundQuery = { election: { memberRound?: { id: string, status: RoundStatus, fullWeightDuration: string, availableVotes?: string, start: { ts: string }, end: { ts: string } } | null, accountElectionMeta?: { hasRegistered: boolean, isContender: boolean } }, governor: { id: string, chainId: string, contracts: { governor: { address: string, type: GovernorType } }, token: { decimals: number } } };

export type ElectionMemberRoundNomineesQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CandidateSort>;
}>;


export type ElectionMemberRoundNomineesQuery = { election: { memberRound?: { nominees: Array<{ id: string, totalVotes: string, account: { address: string, name: string, bio: string, picture?: string | null }, accountElectionMeta: { title?: string | null } }> } | null } };

export type ElectionNominationRoundQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address: Scalars['String'];
  includeAddressData: Scalars['Boolean'];
  input: GovernorInput;
}>;


export type ElectionNominationRoundQuery = { election: { number: number, status: ElectionStatus, nominationRound: { id: string, status: RoundStatus, vettingDuration: string, threshold?: string | null, availableVotes?: string, start: { ts: string }, end: { ts: string }, endNomineeVotingPeriod: { ts: string } }, memberRound?: { id: string, status: RoundStatus, fullWeightDuration: string, start: { ts: string }, end: { ts: string } } | null, accountElectionMeta?: { hasRegistered: boolean, isContender: boolean } }, governor: { id: string, chainId: string, contracts: { governor: { address: string, type: GovernorType } }, token: { decimals: number } } };

export type ElectionNominationRoundContendersQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CandidateSort>;
  filter?: InputMaybe<ContenderFilter>;
}>;


export type ElectionNominationRoundContendersQuery = { nominationRound: { contenders: Array<{ id: string, totalVotes: string, nominated: boolean, rejected: boolean, account: { address: string, name: string, bio: string, picture?: string | null }, accountElectionMeta: { title?: string | null } }> } };

export type MemberRoundDecayVotesQueryVariables = Exact<{
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  votes: Scalars['Uint256'];
}>;


export type MemberRoundDecayVotesQuery = { election: { memberRound?: { votesToWeight: string } | null } };

export type NomineeByAddressOrEnsQueryVariables = Exact<{
  electionNumber: Scalars['Int'];
  councilSlug: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  ens?: InputMaybe<Scalars['String']>;
  isAddress: Scalars['Boolean'];
}>;


export type NomineeByAddressOrEnsQuery = { nominee?: { id: string, totalVotes: string, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } }, nomineeByEns: { id: string, totalVotes: string, account: { id: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null, name: string }, accountElectionMeta: { title?: string | null, statement?: string | null, isContender: boolean, hasRegistered: boolean } } };

export type RegisterAttemptMutationVariables = Exact<{
  councilSlug: Scalars['String'];
  electionNumber: Scalars['Int'];
  address: Scalars['String'];
  txHash: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  statement?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;


export type RegisterAttemptMutation = { registerAsContenderAttempt: boolean };

export type ActionsSecurityCheckQueryVariables = Exact<{
  input: ProposalSecurityCheckInput;
}>;


export type ActionsSecurityCheckQuery = { actionsSecurityCheck: { metadata: { threatAnalysis?: { proposerRisk: string, actionsData: Array<{ result: string, events?: Array<{ eventType: string, severity: string, description: string }> | null } | null> } | null }, simulations: Array<{ publicURI: string, result: string }> } };

export type CreateProposalGovernanceSelectorQueryVariables = Exact<{
  input: GovernorsInput;
}>;


export type CreateProposalGovernanceSelectorQuery = { governors: { nodes: Array<{ id: string, chainId: string, name: string, quorum: string, kind: GovernorKind, token: { id: string, decimals: number }, parameters: { proposalThreshold?: string | null, votingPeriod?: string | null, votingDelay?: string | null }, contracts: { governor: { address: string, type: GovernorType }, tokens: Array<{ address: string, type: TokenType }> } } | {}> }, chains: Array<{ id: string, blockTime: number, layer1Id?: string | null, useLayer1VotingPeriod: boolean } | null> };

export type EditTallyProposalQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type EditTallyProposalQuery = { proposal: { id: string, status: ProposalStatus, createdAt: string, proposer?: { address: string, name: string } | null, metadata: { description: string, title: string, snapshotURL?: string | null }, governor: { id: string }, executableCalls?: Array<{ value: string, target: string, calldata: string, signature?: string | null, type?: ExecutableCallType | null, offchaindata?: { contributorFee: number, tallyFee: number, recipients: Array<string> } | { amountIn: string, to: string, buyToken: { id: string, data: { decimals: number, name: string, symbol: string, logo: string, price: number } }, sellToken: { id: string, data: { decimals: number, name: string, symbol: string, logo: string, price: number } }, priceChecker: { slippage: string, tokenPath: Array<string>, feePath: Array<string>, uniPoolPath?: Array<string> | null }, quote?: { buyAmount: string, buyTokenQuoteRate?: number | null, sellAmount: string, feeAmount: string, validTo: string } | null, order?: { status: SwapOrderStatus, address?: string | null, id?: string | null, buyAmount?: string | null } | null } | null }> | null } };

export type ManageOrcaPodRecipeQueryVariables = Exact<{
  accountIds?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type ManageOrcaPodRecipeQuery = { accounts: Array<{ name: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null }> };

export type RecipientAddressQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type RecipientAddressQuery = { address: { ethAccount: { address: string, bio: string, ens?: string | null, picture?: string | null, name: string, twitter?: string | null } } };

export type AvailableSwapsQueryVariables = Exact<{
  governorId: Scalars['AccountID'];
}>;


export type AvailableSwapsQuery = { availableSwaps: { buy: Array<{ id: string, name: string, symbol: string, logo: string, decimals: number }>, sell: Array<{ address: string, balance: string, decimals: number, logo: string, name: string, quoteRate?: number | null, symbol: string }> } };

export type QuoteSwapQueryVariables = Exact<{
  governorID: Scalars['AccountID'];
  buy: Scalars['AccountID'];
  sell: Scalars['AccountID'];
  sellAmount: Scalars['Uint256'];
}>;


export type QuoteSwapQuery = { quoteSwap: { buyAmount: string, buyTokenQuoteRate?: number | null, sellAmount: string, feeAmount: string } };

export type TransferTokensRecipeQueryVariables = Exact<{
  accountIds?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type TransferTokensRecipeQuery = { accounts: Array<{ name: string, address: string, picture?: string | null, twitter?: string | null }> };

export type TransferTokensReceiptQueryVariables = Exact<{
  accountIds?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type TransferTokensReceiptQuery = { accounts: Array<{ name: string, address: string, picture?: string | null, twitter?: string | null }> };

export type CreateProposalContentQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type CreateProposalContentQuery = { governor: { id: string, chainId: string, name: string, timelockId?: string | null, kind: GovernorKind, contracts: { governor: { address: string, type: GovernorType }, tokens: Array<{ address: string }> }, organization: { name: string, governorIds: Array<string>, metadata?: { icon?: string | null } | null }, token: { decimals: number }, parameters: { proposalThreshold?: string | null } } };

export type CreateTallyProposalMutationVariables = Exact<{
  input: CreateProposalInput;
}>;


export type CreateTallyProposalMutation = { createProposal: { id: string, metadata: { txHash?: any | null } } };

export type ProposalThresholdRequirementQueryVariables = Exact<{
  governorId: Scalars['AccountID'];
  address: Scalars['Address'];
}>;


export type ProposalThresholdRequirementQuery = { delegate?: { votesCount: string } | null };

export type DelegateTokenBalancesQueryVariables = Exact<{
  input: TokenBalancesInput;
}>;


export type DelegateTokenBalancesQuery = { tokenBalances: Array<{ balance: string, token: { id: string, symbol: string, decimals: number } }> };

export type DelegationAttemptQueryVariables = Exact<{
  delegatorId: Scalars['AccountID'];
  governanceId: Scalars['AccountID'];
}>;


export type DelegationAttemptQuery = { delegationAttempt?: { createdAt: string, txID: string } | null, chains: Array<{ id: string, blockTime: number } | null> };

export type SecurityCouncilActionBannerQueryVariables = Exact<{
  gnosisSafeId: Scalars['AccountID'];
  allTransactions?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<Pagination>;
}>;


export type SecurityCouncilActionBannerQuery = { gnosisSafeTransactions: Array<{ id: string, safeID: string, block?: { timestamp: string, ts: string } | null, dataDecoded?: { method: string } | null }> };

export type GovernanceGovernorTypeQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceGovernorTypeQuery = { governor: { contracts: { governor: { type: GovernorType } } } };

export type GovernanceSponsorDelegationByIdQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceSponsorDelegationByIdQuery = { governor: { id: string, chainId: string, contracts: { tokens: Array<{ type: TokenType, address: string }> } } };

export type AddressDelegateeQueryVariables = Exact<{
  input: DelegationInput;
}>;


export type AddressDelegateeQuery = { delegatee?: { delegate: { address: string } } | null };

export type CreateDelegationAttemptMutationVariables = Exact<{
  delegatorId: Scalars['AccountID'];
  delegateeId: Scalars['AccountID'];
  tokenId?: InputMaybe<Scalars['AssetID']>;
  txID: Scalars['Bytes32'];
}>;


export type CreateDelegationAttemptMutation = { createDelegationAttempt: boolean };

export type GovernorMetadataQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernorMetadataQuery = { governor: { id: string, kind: GovernorKind, chainId: string, quorum: string, timelockId?: string | null, contracts: { tokens: Array<{ address: string, type: TokenType }> }, token: { decimals: number }, organization: { id: string, name: string, slug: string, governorIds: Array<string>, metadata?: { description?: string | null, icon?: string | null, socials?: { discord?: string | null, telegram?: string | null, twitter?: string | null, website?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null } } };

export type GovernanceFeaturesQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceFeaturesQuery = { governor: { slug: string, features?: Array<{ name: string, enabled: boolean }> | null } };

export type DelegateButtonQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type DelegateButtonQuery = { governor: { id: string, chainId: string, name: string, organization: { name: string }, contracts: { tokens: Array<{ type: TokenType, address: string }>, governor: { type: GovernorType } } } };

export type GovernanceClaimAirdropQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceClaimAirdropQuery = { governor: { name: string, token: { id: string, name: string, symbol: string, decimals: number } } };

export type GovernanceDelegateProfileEditQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceDelegateProfileEditQuery = { governor: { id: string, chainId: string, name: string, organization: { name: string }, contracts: { tokens: Array<{ address: string, type: TokenType }> } } };

export type DelegatesQueryVariables = Exact<{
  input: DelegatesInput;
}>;


export type DelegatesQuery = { delegates: { nodes: Array<{ id: string, votesCount: string, delegatorsCount: number, account: { address: string, bio: string, name: string, picture?: string | null, twitter?: string | null }, statement?: { statementSummary?: string | null } | null, token?: { symbol: string, decimals: number } | null } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type SearchByEnsQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type SearchByEnsQuery = { accountByEns: { address: string, bio: string, picture?: string | null, name: string, twitter?: string | null } };

export type GovernanceDelegatesSummaryQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceDelegatesSummaryQuery = { governor: { id: string, name: string, chainId: string, quorum: string, contracts: { tokens: Array<{ type: TokenType, address: string }>, governor: { type: GovernorType } }, parameters: { proposalThreshold?: string | null }, token: { supply: string, symbol: string, decimals: number } } };

export type GovernanceHeaderQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceHeaderQuery = { governor: { id: string, name: string, chainId: string, delegatesVotesCount: string, isBehind: boolean, token: { type: TokenType, supply: string, decimals: number, symbol: string }, contracts: { governor: { type: GovernorType }, tokens: Array<{ address: string, type: TokenType }> }, organization: { id: string, name: string, slug: string, metadata?: { description?: string | null, icon?: string | null, socials?: { discord?: string | null, telegram?: string | null, twitter?: string | null, website?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null } } };

export type GovernanceIdtoOrgIdQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceIdtoOrgIdQuery = { governor: { organization: { id: string, name: string, slug: string, metadata?: { description?: string | null, icon?: string | null, socials?: { discord?: string | null, telegram?: string | null, twitter?: string | null, website?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null } } };

export type OrganizationDelegateInformationQueryVariables = Exact<{
  input: DelegateInput;
}>;


export type OrganizationDelegateInformationQuery = { delegate?: { id: string, delegatorsCount: number, votesCount: string, token?: { decimals: number, symbol: string } | null } | null };

export type OrganizationDelegateChainsQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type OrganizationDelegateChainsQuery = { delegatees: { nodes: Array<{ chainId: string, votes: string, token: { decimals: number, symbol: string } } | {}> } };

export type OrganizationReceivedDelegationsQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type OrganizationReceivedDelegationsQuery = { delegators: { nodes: Array<{ chainId: string, blockNumber: number, blockTimestamp: string, votes: string, delegator: { address: string, name: string, picture?: string | null, twitter?: string | null, ens?: string | null } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type GovernanceMetaInformationQueryVariables = Exact<{
  input: GovernorsInput;
}>;


export type GovernanceMetaInformationQuery = { governors: { nodes: Array<{ id: string, delegatesCount: number, tokenOwnersCount: number, organization: { metadata?: { icon?: string | null } | null }, contracts: { tokens: Array<{ address: string }> }, token: { supply: string }, proposalStats: { total: number } } | {}> } };

export type GovernanceModuleInformationQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceModuleInformationQuery = { governor: { id: string, chainId: string, name: string, quorum: string, timelockId?: string | null, kind: GovernorKind, type: GovernorType, token: { id: string, decimals: number }, parameters: { proposalThreshold?: string | null, votingPeriod?: string | null, votingDelay?: string | null, clockMode?: string | null }, contracts: { governor: { address: string, type: GovernorType }, tokens: Array<{ address: string, type: TokenType }> }, metadata?: { description?: string | null } | null }, chains: Array<{ id: string, blockTime: number, layer1Id?: string | null, useLayer1VotingPeriod: boolean } | null> };

export type GovernanceMyVotingPowerQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceMyVotingPowerQuery = { governor: { id: string, token: { symbol: string, decimals: number } } };

export type OrganizationMyVotingPowerDelegatingToQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type OrganizationMyVotingPowerDelegatingToQuery = { delegatees: { nodes: Array<{ chainId: string, votes: string, delegate: { name: string, address: string, ens?: string | null, picture?: string | null }, token: { id: string, name: string, symbol: string, decimals: number } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type OrganizationTokenBalancesQueryVariables = Exact<{
  input: TokenBalancesInput;
}>;


export type OrganizationTokenBalancesQuery = { tokenBalances: Array<{ balance: string, token: { id: string, name: string, symbol: string, decimals: number } }> };

export type GovernanceProposalsStatsQueryVariables = Exact<{
  input: GovernorsInput;
}>;


export type GovernanceProposalsStatsQuery = { governors: { nodes: Array<{ proposalStats: { passed: number, failed: number } } | {}> } };

export type GovernanceProposalsQueryVariables = Exact<{
  input: ProposalsInput;
}>;


export type GovernanceProposalsQuery = { proposals: { nodes: Array<{ id: string, onchainId?: string | null, status: ProposalStatus, originalId?: string | null, createdAt: string, voteStats?: Array<{ votesCount: string, percent: number, type: VoteType, votersCount: number }> | null, metadata: { description: string }, start: { timestamp: string } | { timestamp: string }, block?: { timestamp: string } | null, governor: { id: string, quorum: string, name: string, timelockId?: string | null, token: { decimals: number } } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null, count?: number | null } } };

export type GovernanceProposalsVotesQueryVariables = Exact<{
  input: VotesInput;
}>;


export type GovernanceProposalsVotesQuery = { votes: { nodes: Array<{ type: VoteType, proposal: { id: string, governor: { id: string } }, voter: { picture?: string | null, address: string, twitter?: string | null } } | {}> } };

export type GovernanceTallyProposalsQueryVariables = Exact<{
  input: ProposalsInput;
}>;


export type GovernanceTallyProposalsQuery = { proposals: { nodes: Array<{ id: string, createdAt: string, originalId?: string | null, status: ProposalStatus, metadata: { description: string, title: string }, executableCalls?: Array<{ calldata: string }> | null, governor: { id: string, quorum: string, name: string, organization: { slug: string, metadata?: { icon?: string | null } | null } } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type GovernanceTopAdvocatesQueryVariables = Exact<{
  input: GovernorInput;
  delegatesInput: DelegatesInput;
}>;


export type GovernanceTopAdvocatesQuery = { governor: { id: string, chainId: string, delegatesVotesCount: string, token: { decimals: number, supply: string } }, delegates: { nodes: Array<{ id: string, votesCount: string, account: { address: string, picture?: string | null, name: string }, voteChanges: Array<{ newBalance: string, timestamp: string }> } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type GovernanceTreasuryInformationQueryVariables = Exact<{
  input: GovernorsInput;
}>;


export type GovernanceTreasuryInformationQuery = { governors: { nodes: Array<{ id: string, timelockId?: string | null, name: string, kind: GovernorKind, contracts: { governor: { address: string }, tokens: Array<{ address: string }> } } | {}> } };

export type GovernorsByOrganizationQueryVariables = Exact<{
  input: GovernorsInput;
}>;


export type GovernorsByOrganizationQuery = { governors: { nodes: Array<{ id: string, name: string, kind: GovernorKind, quorum: string, isPrimary: boolean, chainId: string, type: GovernorType, contracts: { governor: { address: string, type: GovernorType }, tokens: Array<{ address: string, type: TokenType }> }, token: { id: string, decimals: number, name: string, symbol: string, type: TokenType } } | {}> } };

export type OrganizationIssuesQueryVariables = Exact<{
  input?: InputMaybe<IssuesInput>;
}>;


export type OrganizationIssuesQuery = { issues?: Array<{ id: string, name?: string | null, organizationId?: string | null } | null> | null };

export type GovernanceClaimAndDelegateAttemptQueryVariables = Exact<{
  delegatorId: Scalars['AccountID'];
  tokenId: Scalars['AssetID'];
}>;


export type GovernanceClaimAndDelegateAttemptQuery = { claimAndDelegateAttempt?: { createdAt: string, txID: string } | null, chains: Array<{ id: string, blockTime: number } | null> };

export type GovernanceClaimConfirmQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceClaimConfirmQuery = { governor: { id: string, chainId: string, contracts: { tokens: Array<{ address: string, type: TokenType }>, governor: { type: GovernorType } }, token: { id: string } } };

export type GuardiansAccountsQueryVariables = Exact<{
  accountIds?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type GuardiansAccountsQuery = { accounts: Array<{ name: string, address: string, picture?: string | null, ens?: string | null, twitter?: string | null }> };

export type AccountByIdQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type AccountByIdQuery = { account: { address: string, picture?: string | null, name: string } };

export type ClaimAirdropEligibleQueryVariables = Exact<{
  input: GovernorInput;
  addressId: Scalars['AccountID'];
}>;


export type ClaimAirdropEligibleQuery = { governor: { token: { symbol: string, decimals: number, eligibility: { amount?: string | null, status: EligibilityStatus, proof?: Array<string> | null, tx?: string | null } } } };

export type CreateClaimAndDelegateAttemptMutationVariables = Exact<{
  delegatorId: Scalars['AccountID'];
  tokenId: Scalars['AssetID'];
  delegateeId: Scalars['AccountID'];
  txID: Scalars['HashID'];
  proof?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  expiry: Scalars['Uint256'];
  parameterV: Scalars['Uint256'];
  parameterR: Scalars['Bytes32'];
  parameterS: Scalars['Bytes32'];
}>;


export type CreateClaimAndDelegateAttemptMutation = { createClaimAndDelegateAttempt: boolean };

export type CreateUnistakerTransactionMutationVariables = Exact<{
  input: CreateUnistakerTransactionInput;
}>;


export type CreateUnistakerTransactionMutation = { createUnistakerTransaction: boolean };

export type GetUnistakerTransactionsQueryVariables = Exact<{
  accnt: Scalars['AccountID'];
  status?: InputMaybe<UnistakerTransactionStatus>;
}>;


export type GetUnistakerTransactionsQuery = { unistakerTransactions: Array<{ id: string, type: UnistakerTransactionType, delegatee?: string | null, beneficiary?: string | null, previousAmount?: string | null, newAmount?: string | null, createdAt: string, depositId?: string | null }> };

export type GovernorClaimFlowQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernorClaimFlowQuery = { governor: { token: { symbol: string, decimals: number } } };

export type ResumeSyncMutationVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type ResumeSyncMutation = { resumeSync: boolean };

export type UpdateUnistakerTransactionMutationVariables = Exact<{
  input: UpdateUnistakerTransactionInput;
}>;


export type UpdateUnistakerTransactionMutation = { updateUnistakerTransaction: boolean };

export type UpsertDelegateProfileMutationVariables = Exact<{
  input: UpsertDelegateProfileInput;
}>;


export type UpsertDelegateProfileMutation = { upsertDelegateProfile: { id: string } };

export type CreateCastVoteMetaTransactionMutationVariables = Exact<{
  governorId: Scalars['AccountID'];
  address: Scalars['Address'];
  transactionId: Scalars['String'];
  validUntil: Scalars['Timestamp'];
  gasPrice: Scalars['Uint256'];
  proposalId: Scalars['ID'];
  support: SupportType;
}>;


export type CreateCastVoteMetaTransactionMutation = { createCastVoteMetaTransaction: { id: string } };

export type CreateDelegateMetaTransactionMutationVariables = Exact<{
  governorId: Scalars['AccountID'];
  address: Scalars['Address'];
  tokenContractId: Scalars['AssetID'];
  from: Scalars['Address'];
  delegatee: Scalars['Address'];
  transactionId: Scalars['String'];
  validUntil: Scalars['Timestamp'];
  gasPrice: Scalars['Uint256'];
}>;


export type CreateDelegateMetaTransactionMutation = { createDelegateMetaTransaction: { id: string } };

export type MetaTransactionsQueryVariables = Exact<{
  action: MetaTransactionAction;
  governorId?: InputMaybe<Scalars['AccountID']>;
  address?: InputMaybe<Scalars['Address']>;
  pagination?: InputMaybe<Pagination>;
}>;


export type MetaTransactionsQuery = { metaTransactions?: Array<{ id: string, governorId: string, address: string, action: MetaTransactionAction, createdAt: string, metadata: { proposalId: string } | {} }> | null };

export type OrganizationFeaturesQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationFeaturesQuery = { organization: { features?: Array<{ name: string, enabled: boolean }> | null } };

export type OrganizationAddAdminFormQueryVariables = Exact<{
  input: OrganizationMembersInput;
}>;


export type OrganizationAddAdminFormQuery = { organizationMembers: { nodes: Array<{ account: { address: string } } | {}> } };

export type OrganizationAdminListQueryVariables = Exact<{
  input: OrganizationMembersInput;
}>;


export type OrganizationAdminListQuery = { organizationMembers: { nodes: Array<{ id: string, role: OrganizationRole, account: { id: string, address: string, name: string, picture?: string | null, ens?: string | null, twitter?: string | null } } | {}> } };

export type OrganizationBasicsSettingsQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationBasicsSettingsQuery = { organization: { id: string, name: string, slug: string, metadata?: { description?: string | null, karmaName?: string | null, socials?: { discord?: string | null, telegram?: string | null, twitter?: string | null, website?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null, adminData?: { contact?: { name: string, email: string, twitter: string, discord: string } | null } | null } };

export type GovernanceBasicsSettingsQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type GovernanceBasicsSettingsQuery = { governor: { name: string, slug: string } };

export type OrganizationBySlugQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationBySlugQuery = { organization: { id: string, name: string, slug: string, chainIds: Array<string>, governorIds: Array<string>, tokenIds: Array<string>, metadata?: { description?: string | null, icon?: string | null, karmaName?: string | null, socials?: { website?: string | null, discord?: string | null, telegram?: string | null, twitter?: string | null, discourse?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null, features?: Array<{ name: string, enabled: boolean }> | null } };

export type OrganizationEditLogoQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationEditLogoQuery = { organization: { id: string, slug: string, metadata?: { icon?: string | null } | null } };

export type OrganizationHeaderQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationHeaderQuery = { organization: { id: string, name: string, slug: string, governorIds: Array<string>, metadata?: { description?: string | null, icon?: string | null, karmaName?: string | null, socials?: { discord?: string | null, telegram?: string | null, twitter?: string | null, website?: string | null, others?: Array<{ label: string, value: string } | null> | null } | null } | null } };

export type OrganizationHomeDelegatesMobileQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationHomeDelegatesMobileQuery = { organization: { delegatesCount: number, tokenOwnersCount: number } };

export type OrganizationHomeProposalsMobileQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationHomeProposalsMobileQuery = { organization: { proposalsCount: number, hasActiveProposals: boolean } };

export type OrganizationLatestForumActivitiesQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationLatestForumActivitiesQuery = { latestForumActivity: { topics: Array<{ title: string, slug?: string | null, replyCount: number, lastPostedAt: string, views: number, pinned?: boolean | null, originalPosterName?: string | null }> } };

export type OrganizationMyVotingPowerQueryVariables = Exact<{
  delegateeInput: DelegationInput;
  delegateInput: DelegateInput;
  tokenBalancesInput: TokenBalancesInput;
}>;


export type OrganizationMyVotingPowerQuery = { delegatee?: { votes: string, delegate: { name: string, address: string, ens?: string | null, picture?: string | null }, token: { id: string, name: string, symbol: string, decimals: number } } | null, delegate?: { delegatorsCount: number, votesCount: string, token?: { id: string, name: string, symbol: string, decimals: number } | null } | null, tokenBalances: Array<{ balance: string, token: { id: string, name: string, symbol: string, decimals: number } }> };

export type OrganizationMyVotingPowerMultiChainQueryVariables = Exact<{
  input: DelegatesInput;
}>;


export type OrganizationMyVotingPowerMultiChainQuery = { delegates: { nodes: Array<{ id: string, votesCount: string, delegatorsCount: number, token?: { symbol: string, decimals: number } | null } | {}> } };

export type OrganizationRisingDelegatesQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type OrganizationRisingDelegatesQuery = { governor: { token: { symbol: string, decimals: number } } };

export type OrganizationSafeListQueryVariables = Exact<{
  input?: InputMaybe<GnosisSafesInput>;
}>;


export type OrganizationSafeListQuery = { gnosisSafesV2: Array<{ id: string, nonce?: number | null, name?: string | null, threshold?: number | null, version?: string | null, owners?: Array<{ id: string, address: string, name: string, bio: string, picture?: string | null }> | null, balance?: { totalUSDValue: string, tokens: Array<{ name: string, symbol: string, address?: string | null, amount: string, fiat: string, decimals: number, logoURI: string }> } | null }> };

export type OrganizationSlugToIdQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type OrganizationSlugToIdQuery = { organizationSlugToId: string };

export type ValidateNewGovernorQueryVariables = Exact<{
  input: ValidateNewGovernorInput;
}>;


export type ValidateNewGovernorQuery = { validateNewGovernor: { type: GovernorType, startBlock: number, tokenId: string, tokenStartBlock: number } };

export type CreateDaoMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateDaoMutation = { createOrganization: { id: string, slug: string } };

export type JoinOrganizationMutationVariables = Exact<{
  input: JoinOrganizationInput;
}>;


export type JoinOrganizationMutation = { joinOrganization: boolean };

export type OrganizationDelegatesSummaryQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationDelegatesSummaryQuery = { organization: { delegatesVotesCount: string, tokenIds: Array<string> } };

export type RemoveSuperAdminMutationVariables = Exact<{
  input: RemoveSuperAdminInput;
}>;


export type RemoveSuperAdminMutation = { removeSuperAdmin: boolean };

export type TokenQueryVariables = Exact<{
  input: TokenInput;
}>;


export type TokenQuery = { token: { id: string, type: TokenType, name: string, symbol: string, supply: string, decimals: number } };

export type UnlinkGnosisSafeMutationVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type UnlinkGnosisSafeMutation = { unlinkGnosisSafe: boolean };

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { updateOrganization: { name: string, id: string, slug: string } };

export type UpdateOrganizationAdminsMutationVariables = Exact<{
  input: OrganizationAdminsInput;
}>;


export type UpdateOrganizationAdminsMutation = { updateOrganizationAdmins: boolean };

export type UpdateOrganizationPasswordMutationVariables = Exact<{
  input: OrganizationPasswordInput;
}>;


export type UpdateOrganizationPasswordMutation = { updateOrganizationPassword: boolean };

export type UpdateOrganizationLogoMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationLogoMutation = { updateOrganization: { id: string } };

export type OrganizationMetadataQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationMetadataQuery = { organization: { id: string, name: string, slug: string, metadata?: { description?: string | null, icon?: string | null } | null } };

export type OrganizationContextQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type OrganizationContextQuery = { organization: { id: string, name: string, slug: string, myRole?: OrganizationRole | null, chainIds: Array<string>, features?: Array<{ name: string, enabled: boolean }> | null } };

export type ExploreOrgsQueryVariables = Exact<{
  input: OrganizationsInput;
}>;


export type ExploreOrgsQuery = { organizations: { nodes: Array<{ id: string, slug: string, name: string, chainIds: Array<string>, proposalsCount: number, hasActiveProposals: boolean, tokenOwnersCount: number, delegatesCount: number, governorIds: Array<string>, tokenIds: Array<string>, metadata?: { icon?: string | null } | null } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type ExploreSearchOrgsQueryVariables = Exact<{
  input: SearchOrganizationInput;
}>;


export type ExploreSearchOrgsQuery = { searchOrganization: Array<{ id: string, slug: string, name: string, chainIds: Array<string>, proposalsCount: number, hasActiveProposals: boolean, tokenOwnersCount: number, delegatesCount: number, governorIds: Array<string>, tokenIds: Array<string>, metadata?: { icon?: string | null } | null }> };

export type ProposalAccountVoteQueryVariables = Exact<{
  input: VotesInput;
}>;


export type ProposalAccountVoteQuery = { votes: { nodes: Array<{ type: VoteType } | {}> } };

export type ProposalBubbleChartQueryVariables = Exact<{
  input: ProposalInput;
  votesInput: VotesInput;
}>;


export type ProposalBubbleChartQuery = { proposal: { createdAt: string, status: ProposalStatus, governor: { id: string, delegatesVotesCount: string, token: { supply: string, decimals: number } }, voteStats?: Array<{ votersCount: number, votesCount: string, type: VoteType, percent: number }> | null }, votes: { nodes: Array<{ amount: string, type: VoteType, voter: { name: string, address: string } } | {}> } };

export type ProposalDetailsQueryVariables = Exact<{
  input: ProposalInput;
  votesInput: VotesInput;
}>;


export type ProposalDetailsQuery = { proposal: { id: string, onchainId?: string | null, metadata: { description: string, discourseURL?: string | null, snapshotURL?: string | null }, executableCalls?: Array<{ value: string, target: string, calldata: string, signature?: string | null, type?: ExecutableCallType | null, offchaindata?: { contributorFee: number, tallyFee: number, recipients: Array<string> } | { amountIn: string, fee?: string | null, to: string, buyToken: { data: { price: number, decimals: number, name: string, symbol: string } }, sellToken: { data: { price: number, decimals: number, name: string, symbol: string } }, quote?: { buyAmount: string, feeAmount: string } | null, order?: { id?: string | null, status: SwapOrderStatus, buyAmount?: string | null, address?: string | null } | null, priceChecker: { tokenPath: Array<string>, feePath: Array<string>, uniPoolPath?: Array<string> | null, slippage: string } } | null }> | null, governor: { id: string, chainId: string, slug: string, timelockId?: string | null, organization: { metadata?: { description?: string | null } | null }, contracts: { governor: { address: string, type: GovernorType } } } }, votes: { nodes: Array<{ isBridged?: boolean | null, reason?: string | null, type: VoteType, voter: { name: string, picture?: string | null, address: string, twitter?: string | null }, block: { timestamp: string } } | {}> } };

export type ProposalHeaderQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalHeaderQuery = { proposal: { id: string, onchainId?: string | null, createdAt: string, status: ProposalStatus, metadata: { description: string }, governor: { id: string, name: string, quorum: string, timelockId?: string | null, type: GovernorType, token: { decimals: number } }, voteStats?: Array<{ votesCount: string, type: VoteType, votersCount: number }> | null, proposer?: { name: string, picture?: string | null, address: string } | null, creator?: { name: string, picture?: string | null, address: string } | null, events: Array<{ type: ProposalEventType }> } };

export type ProposalMetadataQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalMetadataQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { title: string, description: string, snapshotURL?: string | null, discourseURL?: string | null }, events: Array<{ type: ProposalEventType, block?: { number: number } | null }>, governor: { id: string, organization: { name: string, metadata?: { icon?: string | null } | null } } } };

export type ProposalMobileButtonsQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalMobileButtonsQuery = { proposal: { id: string, createdAt: string, status: ProposalStatus, metadata: { description: string }, governor: { id: string } } };

export type ProposalPendingVotesQueryVariables = Exact<{
  input: VotesInput;
}>;


export type ProposalPendingVotesQuery = { votes: { nodes: Array<{ isBridged?: boolean | null } | {}> } };

export type ProposalProgressBarsQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalProgressBarsQuery = { proposal: { voteStats?: Array<{ votersCount: number, type: VoteType, percent: number }> | null, governor: { token: { decimals: number } } } };

export type ProposalStatusHistoryQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalStatusHistoryQuery = { proposal: { createdAt: string, quorum?: string | null, status: ProposalStatus, end: { id: string, timestamp: string } | { timestamp: string }, start: { id: string, timestamp: string } | { timestamp: string }, creator?: { name: string, address: string, picture?: string | null } | null, governor: { timelockId?: string | null, quorum: string, type: GovernorType, token: { decimals: number } }, voteStats?: Array<{ votesCount: string, type: VoteType, votersCount: number }> | null, proposer?: { name: string, address: string, picture?: string | null } | null, block?: { id: string } | null, events: Array<{ type: ProposalEventType, txHash?: any | null, chainId: string, createdAt: string, block?: { id: string, timestamp: string } | null }> } };

export type ProposalTimelineChartQueryVariables = Exact<{
  input: ProposalInput;
  votesInput: VotesInput;
}>;


export type ProposalTimelineChartQuery = { proposal: { createdAt: string, quorum?: string | null, organization: { slug: string }, governor: { quorum: string, token: { decimals: number } }, voteStats?: Array<{ votesCount: string, votersCount: number, type: VoteType, percent: number }> | null }, votes: { nodes: Array<{ amount: string, type: VoteType, id: string, voter: { name: string, picture?: string | null, address: string }, block: { id: string, timestamp: string } } | {}>, pageInfo: { count?: number | null, lastCursor?: string | null } } };

export type ProposalVoteModalQueryVariables = Exact<{
  input: ProposalInput;
  address: Scalars['Address'];
}>;


export type ProposalVoteModalQuery = { proposal: { id: string, onchainId?: string | null, delegateVotesCount: string, metadata: { title: string, description: string }, governor: { id: string, token: { decimals: number, supply: string }, contracts: { governor: { type: GovernorType, address: string } }, organization: { id: string, name: string, tokenIds: Array<string>, metadata?: { icon?: string | null } | null } } }, accountV2: { address: string, name: string, picture?: string | null, twitter?: string | null } };

export type ProposalVotingPowerQueryVariables = Exact<{
  input: DelegationsInput;
}>;


export type ProposalVotingPowerQuery = { delegatees: { nodes: Array<{ id: string, votes: string, chainId: string } | {}> } };

export type ProposalCastVotesQueryVariables = Exact<{
  input: VotesInput;
}>;


export type ProposalCastVotesQuery = { votes: { nodes: Array<{ id: string, chainId: string, txHash: any, type: VoteType } | {}> } };

export type ProposalParticipationsQueryVariables = Exact<{
  input: ProposalInput;
  address: Scalars['Address'];
}>;


export type ProposalParticipationsQuery = { proposal: { id: string, chainId: string, participationType: ProposalParticipationType } };

export type ProposalVotesCastQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalVotesCastQuery = { proposal: { onchainId?: string | null, status: ProposalStatus, quorum?: string | null, voteStats?: Array<{ votesCount: string, votersCount: number, type: VoteType, percent: number }> | null, governor: { quorum: string, type: GovernorType, id: string, token: { decimals: number } } } };

export type ProposalVotesCastListQueryVariables = Exact<{
  forInput: VotesInput;
  againstInput: VotesInput;
  abstainInput: VotesInput;
}>;


export type ProposalVotesCastListQuery = { forVotes: { nodes: Array<{ isBridged?: boolean | null, amount: string, type: VoteType, chainId: string, voter: { name: string, picture?: string | null, address: string, twitter?: string | null } } | {}> }, againstVotes: { nodes: Array<{ isBridged?: boolean | null, amount: string, type: VoteType, chainId: string, voter: { name: string, picture?: string | null, address: string, twitter?: string | null } } | {}> }, abstainVotes: { nodes: Array<{ isBridged?: boolean | null, amount: string, type: VoteType, chainId: string, voter: { name: string, picture?: string | null, address: string, twitter?: string | null } } | {}> } };

export type ProposalVoterVotesCastListQueryVariables = Exact<{
  input: VotesInput;
}>;


export type ProposalVoterVotesCastListQuery = { votes: { nodes: Array<{ isBridged?: boolean | null, amount: string, type: VoteType, chainId: string, voter: { name: string, picture?: string | null, address: string, twitter?: string | null } } | {}> } };

export type VoteListHeaderQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type VoteListHeaderQuery = { proposal: { id: string, metadata: { description: string }, governor: { organization: { name: string, metadata?: { icon?: string | null } | null } } } };

export type VoteListTableQueryVariables = Exact<{
  input: VotesInput;
}>;


export type VoteListTableQuery = { votes: { nodes: Array<{ isBridged?: boolean | null, amount: string, type: VoteType, chainId: string, voter: { name: string, picture?: string | null, address: string, twitter?: string | null } } | {}>, pageInfo: { firstCursor?: string | null, lastCursor?: string | null } } };

export type VoteListTableGovernanceQueryVariables = Exact<{
  input: GovernorInput;
}>;


export type VoteListTableGovernanceQuery = { governor: { token: { decimals: number, supply: string } } };

export type ProposalActionAttemptQueryVariables = Exact<{
  input: ProposalActionAttemptInput;
}>;


export type ProposalActionAttemptQuery = { proposalActionAttempt: { txHash: any, chainId: string, type: ProposalActionType, proposal: { createdAt: string } } };

export type ProposalActiveCtaQueryVariables = Exact<{
  input: ProposalInput;
  votersInput: VotesInput;
}>;


export type ProposalActiveCtaQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { description: string }, executableCalls?: Array<{ calldata: string, target: string, value: string }> | null, events: Array<{ type: ProposalEventType, txHash?: any | null }>, governor: { id: string, chainId: string, timelockId?: string | null, contracts: { governor: { type: GovernorType, address: string } } } }, votes: { nodes: Array<{ type: VoteType, chainId: string } | {}> } };

export type ProposalVoteAttemptQueryVariables = Exact<{
  input: VoteAttemptInput;
}>;


export type ProposalVoteAttemptQuery = { voteAttempt?: { txHash: any, chainId: string, createdAt: string, type: VoteType } | null };

export type ProposalDefeatedCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalDefeatedCtaQuery = { proposal: { id: string, onchainId?: string | null, quorum?: string | null, metadata: { description: string }, voteStats?: Array<{ votesCount: string, votersCount: number, type: VoteType }> | null, governor: { id: string, quorum: string, type: GovernorType, token: { type: TokenType, supply: string, decimals: number } } } };

export type ProposalExecutedCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalExecutedCtaQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { description: string }, events: Array<{ type: ProposalEventType, txHash?: any | null }>, governor: { id: string, chainId: string } } };

export type ProposalPendingCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalPendingCtaQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { description: string }, start: { timestamp: string } | { timestamp: string }, executableCalls?: Array<{ calldata: string, target: string, value: string }> | null, events: Array<{ type: ProposalEventType, txHash?: any | null }>, governor: { id: string, chainId: string, timelockId?: string | null, contracts: { governor: { type: GovernorType, address: string } } } } };

export type ProposalQueuedCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalQueuedCtaQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { description: string }, executableCalls?: Array<{ calldata: string, target: string, value: string }> | null, events: Array<{ type: ProposalEventType, txHash?: any | null }>, governor: { id: string, chainId: string, timelockId?: string | null, contracts: { governor: { type: GovernorType, address: string } } } } };

export type ProposalSuccededCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type ProposalSuccededCtaQuery = { proposal: { id: string, onchainId?: string | null, status: ProposalStatus, metadata: { description: string }, executableCalls?: Array<{ calldata: string, target: string, value: string }> | null, events: Array<{ type: ProposalEventType }>, governor: { id: string, chainId: string, timelockId?: string | null, contracts: { governor: { type: GovernorType, address: string } } } } };

export type ProposalTransferTokensReceiptQueryVariables = Exact<{
  accountIds?: InputMaybe<Array<Scalars['AccountID']> | Scalars['AccountID']>;
}>;


export type ProposalTransferTokensReceiptQuery = { accounts: Array<{ name: string, address: string, picture?: string | null, twitter?: string | null }> };

export type BlockMetadataQueryVariables = Exact<{
  chain: Scalars['ChainID'];
  blockNumber: Scalars['Int'];
}>;


export type BlockMetadataQuery = { block: { id: string, number: number, timestamp: string } };

export type CreateProposalActionAttemptMutationVariables = Exact<{
  input: CreateProposalActionAttemptInput;
}>;


export type CreateProposalActionAttemptMutation = { createProposalActionAttempt: boolean };

export type ContractAbiQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type ContractAbiQuery = { contractAbi: Array<{ type: string, name: string }> };

export type CreateSafeMutationVariables = Exact<{
  input: CreateSafeInput;
}>;


export type CreateSafeMutation = { createSafeV2: boolean };

export type SafeAssetsQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type SafeAssetsQuery = { gnosisSafe: { balance?: { tokens: Array<{ name: string, fiat: string, logoURI: string, symbol: string, decimals: number, address?: string | null, amount: string }> } | null } };

export type SafeBalanceInformationQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type SafeBalanceInformationQuery = { gnosisSafe: { id: string, balance?: { totalUSDValue: string } | null } };

export type SafeHeaderQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type SafeHeaderQuery = { account: { address: string, name: string }, gnosisSafe: { id: string, name?: string | null } };

export type SafeOwnersQueryVariables = Exact<{
  id: Scalars['AccountID'];
}>;


export type SafeOwnersQuery = { gnosisSafe: { threshold?: number | null, owners?: Array<{ id: string, address: string, name: string, ens?: string | null, twitter?: string | null, picture?: string | null }> | null } };

export type UpdateSafeMutationVariables = Exact<{
  id: Scalars['AccountID'];
  name: Scalars['String'];
}>;


export type UpdateSafeMutation = { updateSafe: boolean };

export type LoginAsSafeMutationVariables = Exact<{
  accountId: Scalars['AccountID'];
}>;


export type LoginAsSafeMutation = { loginAsSafe: string };

export type TallyProposalDetailsQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalDetailsQuery = { proposal: { id: string, metadata: { title: string, description: string, snapshotURL?: string | null }, executableCalls?: Array<{ value: string, target: string, calldata: string, signature?: string | null, type?: ExecutableCallType | null, offchaindata?: { contributorFee: number, tallyFee: number, recipients: Array<string> } | { amountIn: string, fee?: string | null, to: string, buyToken: { data: { price: number, decimals: number, name: string, symbol: string } }, sellToken: { data: { price: number, decimals: number, name: string, symbol: string } }, quote?: { buyAmount: string, feeAmount: string } | null, order?: { id?: string | null, status: SwapOrderStatus, buyAmount?: string | null, address?: string | null } | null, priceChecker: { tokenPath: Array<string>, feePath: Array<string>, uniPoolPath?: Array<string> | null, slippage: string } } | null }> | null, governor: { id: string, chainId: string, slug: string, timelockId?: string | null, organization: { metadata?: { description?: string | null } | null }, contracts: { governor: { address: string, type: GovernorType }, tokens: Array<{ address: string }> } } } };

export type TallyProposalHeaderQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalHeaderQuery = { proposal: { id: string, status: ProposalStatus, originalId?: string | null, metadata: { description: string, title: string }, governor: { quorum: string, name: string, chainId: string }, proposer?: { name: string, picture?: string | null, address: string } | null, executableCalls?: Array<{ calldata: string }> | null } };

export type ProposalSecurityCheckQueryVariables = Exact<{
  proposalId: Scalars['ID'];
}>;


export type ProposalSecurityCheckQuery = { proposalSecurityCheck: { createdAt: string, metadata: { metadata: { threatAnalysis?: { proposerRisk: string, actionsData: Array<{ result: string, events?: Array<{ eventType: string, severity: string, description: string }> | null } | null> } | null }, simulations: Array<{ publicURI: string, result: string }> } } };

export type TallyProposalMobileButtonsQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalMobileButtonsQuery = { proposal: { id: string, status: ProposalStatus, originalId?: string | null, governor: { id: string } } };

export type TallyProposalStatusHistoryQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalStatusHistoryQuery = { proposal: { id: string, createdAt: string, governor: { id: string, timelockId?: string | null }, proposer?: { name: string, address: string, picture?: string | null } | null, executableCalls?: Array<{ target: string }> | null } };

export type TallyProposalVersionHistoryQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalVersionHistoryQuery = { proposalWithVersions: Array<{ id: string, createdAt: string, proposer?: { name: string, address: string } | null }> };

export type TallyProposalDraftCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalDraftCtaQuery = { proposal: { id: string, status: ProposalStatus, createdAt: string, originalId?: string | null, executableCalls?: Array<{ value: string, target: string, calldata: string, signature?: string | null }> | null, proposer?: { address: string, name: string } | null, metadata: { description: string, title: string }, events: Array<{ txHash?: any | null, type: ProposalEventType }>, governor: { id: string, chainId: string, contracts: { governor: { address: string, type: GovernorType } }, token: { decimals: number }, parameters: { proposalThreshold?: string | null } } } };

export type TallyProposalSubmittedCtaQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalSubmittedCtaQuery = { proposal: { id: string, governor: { chainId: string }, events: Array<{ type: ProposalEventType, txHash?: any | null }> } };

export type ArchiveProposalMutationVariables = Exact<{
  originalId: Scalars['IntID'];
}>;


export type ArchiveProposalMutation = { archiveProposal: boolean };

export type RestorePreviousProposalDraftMutationVariables = Exact<{
  id: Scalars['IntID'];
}>;


export type RestorePreviousProposalDraftMutation = { restoreProposalDraft: boolean };

export type TallyProposalQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalQuery = { proposal: { onchainId?: string | null, status: ProposalStatus, proposer?: { address: string } | null, governor: { id: string, organization: { governorIds: Array<string> } } } };

export type TallyProposalMetadataQueryVariables = Exact<{
  input: ProposalInput;
}>;


export type TallyProposalMetadataQuery = { proposal: { id: string, originalId?: string | null, onchainId?: string | null, status: ProposalStatus, metadata: { title: string, description: string, snapshotURL?: string | null }, proposer?: { address: string } | null, governor: { id: string, kind: GovernorKind, organization: { id: string, name: string, metadata?: { icon?: string | null } | null } } } };

export type UpdateTallyProposalMutationVariables = Exact<{
  input: UpdateProposalInput;
}>;


export type UpdateTallyProposalMutation = { updateProposal: { id: string } };

export type UserConnectAddressQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type UserConnectAddressQuery = { address: { address: string, ethAccount: { email: string } } };

export type UserCreateApikeyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type UserCreateApikeyMutation = { createAPIKey: string };

export type UserOrganizationsMemberOfQueryVariables = Exact<{
  input?: InputMaybe<OrganizationsInput>;
}>;


export type UserOrganizationsMemberOfQuery = { organizations: { nodes: Array<{ id: string, name: string, chainIds: Array<string>, slug: string, hasActiveProposals: boolean, metadata?: { icon?: string | null } | null } | {}> } };

export type UserOrganizationsQueryVariables = Exact<{
  input?: InputMaybe<OrganizationsInput>;
}>;


export type UserOrganizationsQuery = { organizations: { nodes: Array<{ id: string, name: string, slug: string, myRole?: OrganizationRole | null, governorIds: Array<string>, metadata?: { description?: string | null, icon?: string | null, socials?: { website?: string | null } | null } | null } | {}> } };

export type UserProfileUpdateMutationVariables = Exact<{
  bio?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
}>;


export type UserProfileUpdateMutation = { updateAccount: boolean };

export type RemoveTwitterMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveTwitterMutation = { removeTwitter: boolean };

export type UpdateAccountMutationVariables = Exact<{
  bio?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAccountMutation = { updateAccount: boolean };

export type UpdateAccountEmailMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAccountEmailMutation = { updateAccount: boolean };

export type UpdateIdentitiesMutationVariables = Exact<{
  identities?: InputMaybe<IdentitiesInput>;
}>;


export type UpdateIdentitiesMutation = { updateAccount: boolean };

export type UpdateProfileMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = { updateAccount: boolean };

export type UpdateProfileImageMutationVariables = Exact<{
  picture?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfileImageMutation = { updateAccount: boolean };

export type GovernorAccountVotingPowerQueryVariables = Exact<{
  input: DelegateInput;
  blockNumber?: InputMaybe<Scalars['Int']>;
}>;


export type GovernorAccountVotingPowerQuery = { delegate?: { votesCount: string } | null };

export type CreateVoteAttemptMutationVariables = Exact<{
  input: CreateVoteAttemptInput;
}>;


export type CreateVoteAttemptMutation = { createVoteAttempt: boolean };

export type NonceQueryVariables = Exact<{ [key: string]: never; }>;


export type NonceQuery = { nonce: { expirationTime: string, issuedAt: string, nonce: string, nonceToken: string } };

export type TransactionAttemptsQueryVariables = Exact<{
  input: TransactionAttemptsInput;
}>;


export type TransactionAttemptsQuery = { transactionAttempts: Array<{ id: string, tokenId?: string | null, transactionType: TransactionType, createdAt: string }> };


export const CreatedProposalsDocument = `
    query CreatedProposals($input: ProposalsInput!) {
  proposals(input: $input) {
    nodes {
      ... on Proposal {
        id
        onchainId
        originalId
        governor {
          id
        }
        metadata {
          description
        }
        status
        createdAt
        block {
          timestamp
        }
        voteStats {
          votesCount
          votersCount
          type
          percent
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useCreatedProposalsQuery = <
      TData = CreatedProposalsQuery,
      TError = Error
    >(
      variables: CreatedProposalsQueryVariables,
      options?: UseQueryOptions<CreatedProposalsQuery, TError, TData>
    ) =>
    useQuery<CreatedProposalsQuery, TError, TData>(
      ['CreatedProposals', variables],
      useFetchData<CreatedProposalsQuery, CreatedProposalsQueryVariables>(CreatedProposalsDocument).bind(null, variables),
      options
    );
export const AddressDaosProposalsDocument = `
    query AddressDAOSProposals($input: ProposalsInput!, $address: Address!) {
  proposals(input: $input) {
    nodes {
      ... on Proposal {
        id
        createdAt
        onchainId
        originalId
        metadata {
          description
        }
        governor {
          id
        }
        block {
          timestamp
        }
        proposer {
          address
        }
        creator {
          address
        }
        start {
          ... on Block {
            timestamp
          }
          ... on BlocklessTimestamp {
            timestamp
          }
        }
        status
        voteStats {
          votesCount
          votersCount
          type
          percent
        }
        participationType(address: $address)
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useAddressDaosProposalsQuery = <
      TData = AddressDaosProposalsQuery,
      TError = Error
    >(
      variables: AddressDaosProposalsQueryVariables,
      options?: UseQueryOptions<AddressDaosProposalsQuery, TError, TData>
    ) =>
    useQuery<AddressDaosProposalsQuery, TError, TData>(
      ['AddressDAOSProposals', variables],
      useFetchData<AddressDaosProposalsQuery, AddressDaosProposalsQueryVariables>(AddressDaosProposalsDocument).bind(null, variables),
      options
    );
export const AddressGovernancesDelegatorsDocument = `
    query AddressGovernancesDelegators($input: DelegatesInput!) {
  delegates(input: $input) {
    nodes {
      ... on Delegate {
        id
        delegatorsCount
        votesCount
        account {
          name
          address
          ens
          picture
        }
        organization {
          id
          name
          tokenOwnersCount
          delegatesVotesCount
          slug
          metadata {
            icon
          }
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useAddressGovernancesDelegatorsQuery = <
      TData = AddressGovernancesDelegatorsQuery,
      TError = Error
    >(
      variables: AddressGovernancesDelegatorsQueryVariables,
      options?: UseQueryOptions<AddressGovernancesDelegatorsQuery, TError, TData>
    ) =>
    useQuery<AddressGovernancesDelegatorsQuery, TError, TData>(
      ['AddressGovernancesDelegators', variables],
      useFetchData<AddressGovernancesDelegatorsQuery, AddressGovernancesDelegatorsQueryVariables>(AddressGovernancesDelegatorsDocument).bind(null, variables),
      options
    );
export const AddressGovernancesDelegateesDocument = `
    query AddressGovernancesDelegatees($input: DelegationsInput!) {
  delegatees(input: $input) {
    nodes {
      ... on Delegation {
        delegate {
          name
          address
          ens
          picture
        }
        delegator {
          name
          address
          ens
          picture
        }
        organization {
          id
          name
          slug
          delegatesVotesCount
          metadata {
            icon
          }
        }
        token {
          decimals
          supply
        }
        votes
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useAddressGovernancesDelegateesQuery = <
      TData = AddressGovernancesDelegateesQuery,
      TError = Error
    >(
      variables: AddressGovernancesDelegateesQueryVariables,
      options?: UseQueryOptions<AddressGovernancesDelegateesQuery, TError, TData>
    ) =>
    useQuery<AddressGovernancesDelegateesQuery, TError, TData>(
      ['AddressGovernancesDelegatees', variables],
      useFetchData<AddressGovernancesDelegateesQuery, AddressGovernancesDelegateesQueryVariables>(AddressGovernancesDelegateesDocument).bind(null, variables),
      options
    );
export const AddressHeaderDocument = `
    query AddressHeader($accountId: AccountID!) {
  account(id: $accountId) {
    address
    bio
    name
    picture
    twitter
  }
}
    `;
export const useAddressHeaderQuery = <
      TData = AddressHeaderQuery,
      TError = Error
    >(
      variables: AddressHeaderQueryVariables,
      options?: UseQueryOptions<AddressHeaderQuery, TError, TData>
    ) =>
    useQuery<AddressHeaderQuery, TError, TData>(
      ['AddressHeader', variables],
      useFetchData<AddressHeaderQuery, AddressHeaderQueryVariables>(AddressHeaderDocument).bind(null, variables),
      options
    );
export const AddressDelegatingToDocument = `
    query AddressDelegatingTo($delegateeInput: DelegationInput!) {
  delegatee(input: $delegateeInput) {
    delegate {
      name
      address
      ens
      picture
    }
    votes
    token {
      id
      name
      symbol
      decimals
    }
  }
}
    `;
export const useAddressDelegatingToQuery = <
      TData = AddressDelegatingToQuery,
      TError = Error
    >(
      variables: AddressDelegatingToQueryVariables,
      options?: UseQueryOptions<AddressDelegatingToQuery, TError, TData>
    ) =>
    useQuery<AddressDelegatingToQuery, TError, TData>(
      ['AddressDelegatingTo', variables],
      useFetchData<AddressDelegatingToQuery, AddressDelegatingToQueryVariables>(AddressDelegatingToDocument).bind(null, variables),
      options
    );
export const DelegateInformationDocument = `
    query DelegateInformation($tokenBalancesInput: TokenBalancesInput!) {
  tokenBalances(input: $tokenBalancesInput) {
    balance
  }
}
    `;
export const useDelegateInformationQuery = <
      TData = DelegateInformationQuery,
      TError = Error
    >(
      variables: DelegateInformationQueryVariables,
      options?: UseQueryOptions<DelegateInformationQuery, TError, TData>
    ) =>
    useQuery<DelegateInformationQuery, TError, TData>(
      ['DelegateInformation', variables],
      useFetchData<DelegateInformationQuery, DelegateInformationQueryVariables>(DelegateInformationDocument).bind(null, variables),
      options
    );
export const ReceivedDelegationsGovernanceDocument = `
    query ReceivedDelegationsGovernance($input: DelegationsInput!) {
  delegators(input: $input) {
    nodes {
      ... on Delegation {
        chainId
        delegator {
          address
          name
          picture
          twitter
          ens
        }
        blockNumber
        blockTimestamp
        votes
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useReceivedDelegationsGovernanceQuery = <
      TData = ReceivedDelegationsGovernanceQuery,
      TError = Error
    >(
      variables: ReceivedDelegationsGovernanceQueryVariables,
      options?: UseQueryOptions<ReceivedDelegationsGovernanceQuery, TError, TData>
    ) =>
    useQuery<ReceivedDelegationsGovernanceQuery, TError, TData>(
      ['ReceivedDelegationsGovernance', variables],
      useFetchData<ReceivedDelegationsGovernanceQuery, ReceivedDelegationsGovernanceQueryVariables>(ReceivedDelegationsGovernanceDocument).bind(null, variables),
      options
    );
export const AddressTabPanelsStatsDocument = `
    query AddressTabPanelsStats($input: DelegateInput!, $proposalsCreatedCountInput: ProposalsCreatedCountInput!, $accountId: AccountID!) {
  account(id: $accountId) {
    proposalsCreatedCount(input: $proposalsCreatedCountInput)
  }
  delegate(input: $input) {
    delegatorsCount
    votesCount
  }
}
    `;
export const useAddressTabPanelsStatsQuery = <
      TData = AddressTabPanelsStatsQuery,
      TError = Error
    >(
      variables: AddressTabPanelsStatsQueryVariables,
      options?: UseQueryOptions<AddressTabPanelsStatsQuery, TError, TData>
    ) =>
    useQuery<AddressTabPanelsStatsQuery, TError, TData>(
      ['AddressTabPanelsStats', variables],
      useFetchData<AddressTabPanelsStatsQuery, AddressTabPanelsStatsQueryVariables>(AddressTabPanelsStatsDocument).bind(null, variables),
      options
    );
export const AccountByEnsDocument = `
    query AccountByEns($ens: String!) {
  accountByEns(ens: $ens) {
    id
    address
    ens
    name
    bio
    picture
    twitter
  }
}
    `;
export const useAccountByEnsQuery = <
      TData = AccountByEnsQuery,
      TError = Error
    >(
      variables: AccountByEnsQueryVariables,
      options?: UseQueryOptions<AccountByEnsQuery, TError, TData>
    ) =>
    useQuery<AccountByEnsQuery, TError, TData>(
      ['AccountByEns', variables],
      useFetchData<AccountByEnsQuery, AccountByEnsQueryVariables>(AccountByEnsDocument).bind(null, variables),
      options
    );
export const AddressMetadataDocument = `
    query AddressMetadata($address: Address!) {
  address(address: $address) {
    address
    accounts {
      id
      address
      ens
      name
      bio
      picture
    }
  }
}
    `;
export const useAddressMetadataQuery = <
      TData = AddressMetadataQuery,
      TError = Error
    >(
      variables: AddressMetadataQueryVariables,
      options?: UseQueryOptions<AddressMetadataQuery, TError, TData>
    ) =>
    useQuery<AddressMetadataQuery, TError, TData>(
      ['AddressMetadata', variables],
      useFetchData<AddressMetadataQuery, AddressMetadataQueryVariables>(AddressMetadataDocument).bind(null, variables),
      options
    );
export const AddressSafesDocument = `
    query AddressSafes($accountId: AccountID!) {
  account(id: $accountId) {
    safes
  }
}
    `;
export const useAddressSafesQuery = <
      TData = AddressSafesQuery,
      TError = Error
    >(
      variables: AddressSafesQueryVariables,
      options?: UseQueryOptions<AddressSafesQuery, TError, TData>
    ) =>
    useQuery<AddressSafesQuery, TError, TData>(
      ['AddressSafes', variables],
      useFetchData<AddressSafesQuery, AddressSafesQueryVariables>(AddressSafesDocument).bind(null, variables),
      options
    );
export const DelegateStatementDocument = `
    query DelegateStatement($input: DelegateInput!) {
  delegate(input: $input) {
    statement {
      id
      address
      organizationID
      issues {
        id
        name
        organizationId
        description
      }
      statement
      statementSummary
      dataSource
      dataSourceURL
      discourseUsername
      discourseProfileLink
      isSeekingDelegation
      isMember
      hideDisclaimer
    }
  }
}
    `;
export const useDelegateStatementQuery = <
      TData = DelegateStatementQuery,
      TError = Error
    >(
      variables: DelegateStatementQueryVariables,
      options?: UseQueryOptions<DelegateStatementQuery, TError, TData>
    ) =>
    useQuery<DelegateStatementQuery, TError, TData>(
      ['DelegateStatement', variables],
      useFetchData<DelegateStatementQuery, DelegateStatementQueryVariables>(DelegateStatementDocument).bind(null, variables),
      options
    );
export const AccountsCommentsDocument = `
    query AccountsComments($ids: [AccountID!]) {
  accounts(ids: $ids) {
    id
    address
    name
    picture
  }
}
    `;
export const useAccountsCommentsQuery = <
      TData = AccountsCommentsQuery,
      TError = Error
    >(
      variables?: AccountsCommentsQueryVariables,
      options?: UseQueryOptions<AccountsCommentsQuery, TError, TData>
    ) =>
    useQuery<AccountsCommentsQuery, TError, TData>(
      variables === undefined ? ['AccountsComments'] : ['AccountsComments', variables],
      useFetchData<AccountsCommentsQuery, AccountsCommentsQueryVariables>(AccountsCommentsDocument).bind(null, variables),
      options
    );
export const SearchOrgsDocument = `
    query SearchOrgs($input: SearchOrganizationInput!) {
  searchOrganization(input: $input) {
    id
    slug
    name
    governorIds
    metadata {
      icon
    }
    tokenOwnersCount
    tokenIds
    chainIds
  }
}
    `;
export const useSearchOrgsQuery = <
      TData = SearchOrgsQuery,
      TError = Error
    >(
      variables: SearchOrgsQueryVariables,
      options?: UseQueryOptions<SearchOrgsQuery, TError, TData>
    ) =>
    useQuery<SearchOrgsQuery, TError, TData>(
      ['SearchOrgs', variables],
      useFetchData<SearchOrgsQuery, SearchOrgsQueryVariables>(SearchOrgsDocument).bind(null, variables),
      options
    );
export const TokenBalancesDocument = `
    query TokenBalances($input: AccountID!) {
  balances(accountID: $input) {
    name
    symbol
    address
    logo
    nativeToken
    type
    decimals
    balance
    balance24H
    quoteRate
    quoteRate24H
    quote
    quote24H
  }
}
    `;
export const useTokenBalancesQuery = <
      TData = TokenBalancesQuery,
      TError = Error
    >(
      variables: TokenBalancesQueryVariables,
      options?: UseQueryOptions<TokenBalancesQuery, TError, TData>
    ) =>
    useQuery<TokenBalancesQuery, TError, TData>(
      ['TokenBalances', variables],
      useFetchData<TokenBalancesQuery, TokenBalancesQueryVariables>(TokenBalancesDocument).bind(null, variables),
      options
    );
export const LoginDocument = `
    mutation Login($message: String!, $signature: String!, $signInType: SignInType!) {
  login(message: $message, signature: $signature, signInType: $signInType)
}
    `;
export const useLoginMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
      options
    );
export const MeDocument = `
    query Me {
  me {
    id
    bio
    name
    type
    address
    email
    picture
    apiKeys {
      name
      lastFour
    }
    ens
    twitter
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = Error
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      useFetchData<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
      options
    );
export const UploadFileDocument = `
    mutation UploadFile($file: Upload!) {
  upload(file: {id: 1, upload: $file}) {
    url
    id
    metadata {
      thumbnail
      url
    }
  }
}
    `;
export const useUploadFileMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UploadFileMutation, TError, UploadFileMutationVariables, TContext>) =>
    useMutation<UploadFileMutation, TError, UploadFileMutationVariables, TContext>(
      ['UploadFile'],
      useFetchData<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument),
      options
    );
export const CouncilDocument = `
    query Council($slug: String!) {
  council(slug: $slug) {
    id
    slug
    name
    cohortSize
    description
  }
}
    `;
export const useCouncilQuery = <
      TData = CouncilQuery,
      TError = Error
    >(
      variables: CouncilQueryVariables,
      options?: UseQueryOptions<CouncilQuery, TError, TData>
    ) =>
    useQuery<CouncilQuery, TError, TData>(
      ['Council', variables],
      useFetchData<CouncilQuery, CouncilQueryVariables>(CouncilDocument).bind(null, variables),
      options
    );
export const ElectionDocument = `
    query Election($electionNumber: Int!, $councilSlug: String!) {
  election(councilSlug: $councilSlug, number: $electionNumber) {
    number
    status
    nominationRound {
      id
      status
    }
    memberRound {
      id
      status
    }
  }
}
    `;
export const useElectionQuery = <
      TData = ElectionQuery,
      TError = Error
    >(
      variables: ElectionQueryVariables,
      options?: UseQueryOptions<ElectionQuery, TError, TData>
    ) =>
    useQuery<ElectionQuery, TError, TData>(
      ['Election', variables],
      useFetchData<ElectionQuery, ElectionQueryVariables>(ElectionDocument).bind(null, variables),
      options
    );
export const NominationRoundDocument = `
    query NominationRound($electionNumber: Int!, $councilSlug: String!) {
  nominationRound(electionNumber: $electionNumber, councilSlug: $councilSlug) {
    status
  }
}
    `;
export const useNominationRoundQuery = <
      TData = NominationRoundQuery,
      TError = Error
    >(
      variables: NominationRoundQueryVariables,
      options?: UseQueryOptions<NominationRoundQuery, TError, TData>
    ) =>
    useQuery<NominationRoundQuery, TError, TData>(
      ['NominationRound', variables],
      useFetchData<NominationRoundQuery, NominationRoundQueryVariables>(NominationRoundDocument).bind(null, variables),
      options
    );
export const MemberRoundDocument = `
    query MemberRound($electionNumber: Int!, $councilSlug: String!) {
  memberRound(electionNumber: $electionNumber, councilSlug: $councilSlug) {
    status
  }
}
    `;
export const useMemberRoundQuery = <
      TData = MemberRoundQuery,
      TError = Error
    >(
      variables: MemberRoundQueryVariables,
      options?: UseQueryOptions<MemberRoundQuery, TError, TData>
    ) =>
    useQuery<MemberRoundQuery, TError, TData>(
      ['MemberRound', variables],
      useFetchData<MemberRoundQuery, MemberRoundQueryVariables>(MemberRoundDocument).bind(null, variables),
      options
    );
export const AccountElectionMetaDocument = `
    query AccountElectionMeta($electionNumber: Int!, $councilSlug: String!, $address: String!) {
  election(number: $electionNumber, councilSlug: $councilSlug) {
    accountElectionMeta(address: $address) {
      hasRegistered
      isContender
    }
  }
}
    `;
export const useAccountElectionMetaQuery = <
      TData = AccountElectionMetaQuery,
      TError = Error
    >(
      variables: AccountElectionMetaQueryVariables,
      options?: UseQueryOptions<AccountElectionMetaQuery, TError, TData>
    ) =>
    useQuery<AccountElectionMetaQuery, TError, TData>(
      ['AccountElectionMeta', variables],
      useFetchData<AccountElectionMetaQuery, AccountElectionMetaQueryVariables>(AccountElectionMetaDocument).bind(null, variables),
      options
    );
export const ContenderDocument = `
    query Contender($electionNumber: Int!, $councilSlug: String!, $address: String!) {
  contender(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVoters
    totalVotes
    nominated
    rejected
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
}
    `;
export const useContenderQuery = <
      TData = ContenderQuery,
      TError = Error
    >(
      variables: ContenderQueryVariables,
      options?: UseQueryOptions<ContenderQuery, TError, TData>
    ) =>
    useQuery<ContenderQuery, TError, TData>(
      ['Contender', variables],
      useFetchData<ContenderQuery, ContenderQueryVariables>(ContenderDocument).bind(null, variables),
      options
    );
export const NomineeDocument = `
    query Nominee($electionNumber: Int!, $councilSlug: String!, $address: String!) {
  nominee(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    totalVoters
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
}
    `;
export const useNomineeQuery = <
      TData = NomineeQuery,
      TError = Error
    >(
      variables: NomineeQueryVariables,
      options?: UseQueryOptions<NomineeQuery, TError, TData>
    ) =>
    useQuery<NomineeQuery, TError, TData>(
      ['Nominee', variables],
      useFetchData<NomineeQuery, NomineeQueryVariables>(NomineeDocument).bind(null, variables),
      options
    );
export const ContenderLayoutDocument = `
    query ContenderLayout($electionNumber: Int!, $councilSlug: String!, $address: String!, $pagination: Pagination) {
  contender(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    nominated
    rejected
    accountElectionMeta {
      hasRegistered
      isContender
      title
      statement
    }
    votes(pagination: $pagination) {
      voter {
        id
        address
        ens
        twitter
        name
        email
        bio
        picture
      }
      weight
    }
  }
}
    `;
export const useContenderLayoutQuery = <
      TData = ContenderLayoutQuery,
      TError = Error
    >(
      variables: ContenderLayoutQueryVariables,
      options?: UseQueryOptions<ContenderLayoutQuery, TError, TData>
    ) =>
    useQuery<ContenderLayoutQuery, TError, TData>(
      ['ContenderLayout', variables],
      useFetchData<ContenderLayoutQuery, ContenderLayoutQueryVariables>(ContenderLayoutDocument).bind(null, variables),
      options
    );
export const CouncilCurrentMembersListDocument = `
    query CouncilCurrentMembersList($slug: String!) {
  council(slug: $slug) {
    members {
      firstCohort {
        address
        name
        picture
        id
        bio
        ens
      }
      secondCohort {
        address
        name
        picture
        id
        bio
        ens
      }
    }
  }
}
    `;
export const useCouncilCurrentMembersListQuery = <
      TData = CouncilCurrentMembersListQuery,
      TError = Error
    >(
      variables: CouncilCurrentMembersListQueryVariables,
      options?: UseQueryOptions<CouncilCurrentMembersListQuery, TError, TData>
    ) =>
    useQuery<CouncilCurrentMembersListQuery, TError, TData>(
      ['CouncilCurrentMembersList', variables],
      useFetchData<CouncilCurrentMembersListQuery, CouncilCurrentMembersListQueryVariables>(CouncilCurrentMembersListDocument).bind(null, variables),
      options
    );
export const CouncilElectionsDocument = `
    query CouncilElections($slug: String!) {
  council(slug: $slug) {
    elections {
      status
      number
      nominationRound {
        start {
          ts
        }
        end {
          ts
        }
        status
      }
      memberRound {
        end {
          ts
        }
      }
    }
  }
}
    `;
export const useCouncilElectionsQuery = <
      TData = CouncilElectionsQuery,
      TError = Error
    >(
      variables: CouncilElectionsQueryVariables,
      options?: UseQueryOptions<CouncilElectionsQuery, TError, TData>
    ) =>
    useQuery<CouncilElectionsQuery, TError, TData>(
      ['CouncilElections', variables],
      useFetchData<CouncilElectionsQuery, CouncilElectionsQueryVariables>(CouncilElectionsDocument).bind(null, variables),
      options
    );
export const CouncilMembersDocument = `
    query CouncilMembers($slug: String!) {
  council(slug: $slug) {
    members {
      firstCohort {
        address
        name
        picture
        id
        bio
        ens
      }
      secondCohort {
        address
        name
        picture
        id
        bio
        ens
      }
    }
  }
}
    `;
export const useCouncilMembersQuery = <
      TData = CouncilMembersQuery,
      TError = Error
    >(
      variables: CouncilMembersQueryVariables,
      options?: UseQueryOptions<CouncilMembersQuery, TError, TData>
    ) =>
    useQuery<CouncilMembersQuery, TError, TData>(
      ['CouncilMembers', variables],
      useFetchData<CouncilMembersQuery, CouncilMembersQueryVariables>(CouncilMembersDocument).bind(null, variables),
      options
    );
export const ElectionLayoutDocument = `
    query ElectionLayout($electionNumber: Int!, $councilSlug: String!) {
  election(councilSlug: $councilSlug, number: $electionNumber) {
    number
    status
    nominationRound {
      id
      status
      endNomineeVotingPeriod {
        ts
      }
      start {
        ts
      }
      end {
        ts
      }
      vettingDuration
      threshold
      contenderRegistrationStart {
        ts
      }
    }
    memberRound {
      status
      start {
        ts
      }
      end {
        ts
      }
    }
  }
}
    `;
export const useElectionLayoutQuery = <
      TData = ElectionLayoutQuery,
      TError = Error
    >(
      variables: ElectionLayoutQueryVariables,
      options?: UseQueryOptions<ElectionLayoutQuery, TError, TData>
    ) =>
    useQuery<ElectionLayoutQuery, TError, TData>(
      ['ElectionLayout', variables],
      useFetchData<ElectionLayoutQuery, ElectionLayoutQueryVariables>(ElectionLayoutDocument).bind(null, variables),
      options
    );
export const GovernanceCouncilBannerDocument = `
    query GovernanceCouncilBanner($tokenId: AssetID!) {
  councils(tokenId: $tokenId) {
    slug
    name
    elections {
      number
      status
      nominationRound {
        end {
          ts
        }
        endNomineeVotingPeriod {
          ts
        }
        start {
          ts
        }
        status
        vettingDuration
      }
      memberRound {
        fullWeightDuration
        start {
          ts
        }
        end {
          ts
        }
      }
    }
  }
}
    `;
export const useGovernanceCouncilBannerQuery = <
      TData = GovernanceCouncilBannerQuery,
      TError = Error
    >(
      variables: GovernanceCouncilBannerQueryVariables,
      options?: UseQueryOptions<GovernanceCouncilBannerQuery, TError, TData>
    ) =>
    useQuery<GovernanceCouncilBannerQuery, TError, TData>(
      ['GovernanceCouncilBanner', variables],
      useFetchData<GovernanceCouncilBannerQuery, GovernanceCouncilBannerQueryVariables>(GovernanceCouncilBannerDocument).bind(null, variables),
      options
    );
export const GovernanceCouncilsDocument = `
    query GovernanceCouncils($tokenId: AssetID!) {
  councils(tokenId: $tokenId) {
    id
    name
    slug
    elections {
      status
    }
  }
}
    `;
export const useGovernanceCouncilsQuery = <
      TData = GovernanceCouncilsQuery,
      TError = Error
    >(
      variables: GovernanceCouncilsQueryVariables,
      options?: UseQueryOptions<GovernanceCouncilsQuery, TError, TData>
    ) =>
    useQuery<GovernanceCouncilsQuery, TError, TData>(
      ['GovernanceCouncils', variables],
      useFetchData<GovernanceCouncilsQuery, GovernanceCouncilsQueryVariables>(GovernanceCouncilsDocument).bind(null, variables),
      options
    );
export const NomineeHeaderDocument = `
    query NomineeHeader($electionNumber: Int!, $councilSlug: String!, $address: String!, $limit: Int!, $includeAddressData: Boolean!) {
  election(number: $electionNumber, councilSlug: $councilSlug) {
    memberRound {
      id
      status
      start {
        ts
      }
      end {
        ts
      }
      availableVotes(address: $address) @include(if: $includeAddressData)
      nominees(pagination: {offset: 0, limit: $limit}) {
        id
        account {
          address
        }
        totalVotes
      }
    }
  }
}
    `;
export const useNomineeHeaderQuery = <
      TData = NomineeHeaderQuery,
      TError = Error
    >(
      variables: NomineeHeaderQueryVariables,
      options?: UseQueryOptions<NomineeHeaderQuery, TError, TData>
    ) =>
    useQuery<NomineeHeaderQuery, TError, TData>(
      ['NomineeHeader', variables],
      useFetchData<NomineeHeaderQuery, NomineeHeaderQueryVariables>(NomineeHeaderDocument).bind(null, variables),
      options
    );
export const NomineeSupportersDocument = `
    query NomineeSupporters($electionNumber: Int!, $councilSlug: String!, $address: String!, $pagination: Pagination) {
  nominee(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) {
    totalVotes
    totalVoters
    votes(pagination: $pagination) {
      voter {
        id
        address
        ens
        twitter
        name
        email
        bio
        picture
      }
      weight
    }
  }
}
    `;
export const useNomineeSupportersQuery = <
      TData = NomineeSupportersQuery,
      TError = Error
    >(
      variables: NomineeSupportersQueryVariables,
      options?: UseQueryOptions<NomineeSupportersQuery, TError, TData>
    ) =>
    useQuery<NomineeSupportersQuery, TError, TData>(
      ['NomineeSupporters', variables],
      useFetchData<NomineeSupportersQuery, NomineeSupportersQueryVariables>(NomineeSupportersDocument).bind(null, variables),
      options
    );
export const UpdateCandidateProfileDocument = `
    mutation UpdateCandidateProfile($councilSlug: String!, $electionNumber: Int!, $address: String!, $title: String, $statement: String, $email: String) {
  updateCandidateProfile(
    councilSlug: $councilSlug
    electionNumber: $electionNumber
    address: $address
    title: $title
    statement: $statement
    email: $email
  )
}
    `;
export const useUpdateCandidateProfileMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCandidateProfileMutation, TError, UpdateCandidateProfileMutationVariables, TContext>) =>
    useMutation<UpdateCandidateProfileMutation, TError, UpdateCandidateProfileMutationVariables, TContext>(
      ['UpdateCandidateProfile'],
      useFetchData<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>(UpdateCandidateProfileDocument),
      options
    );
export const ContenderByAddressOrEnsDocument = `
    query ContenderByAddressOrEns($electionNumber: Int!, $councilSlug: String!, $address: String, $ens: String, $isAddress: Boolean!) {
  contender(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) @include(if: $isAddress) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    nominated
    rejected
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
  contenderByEns: contender(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    ens: $ens
  ) @skip(if: $isAddress) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    nominated
    rejected
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
}
    `;
export const useContenderByAddressOrEnsQuery = <
      TData = ContenderByAddressOrEnsQuery,
      TError = Error
    >(
      variables: ContenderByAddressOrEnsQueryVariables,
      options?: UseQueryOptions<ContenderByAddressOrEnsQuery, TError, TData>
    ) =>
    useQuery<ContenderByAddressOrEnsQuery, TError, TData>(
      ['ContenderByAddressOrEns', variables],
      useFetchData<ContenderByAddressOrEnsQuery, ContenderByAddressOrEnsQueryVariables>(ContenderByAddressOrEnsDocument).bind(null, variables),
      options
    );
export const ContenderElectionMetaDocument = `
    query ContenderElectionMeta($electionNumber: Int!, $councilSlug: String!, $address: String!) {
  contender(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) {
    accountElectionMeta {
      title
      statement
      hasRegistered
      isContender
    }
  }
}
    `;
export const useContenderElectionMetaQuery = <
      TData = ContenderElectionMetaQuery,
      TError = Error
    >(
      variables: ContenderElectionMetaQueryVariables,
      options?: UseQueryOptions<ContenderElectionMetaQuery, TError, TData>
    ) =>
    useQuery<ContenderElectionMetaQuery, TError, TData>(
      ['ContenderElectionMeta', variables],
      useFetchData<ContenderElectionMetaQuery, ContenderElectionMetaQueryVariables>(ContenderElectionMetaDocument).bind(null, variables),
      options
    );
export const ElectionMemberRoundDocument = `
    query ElectionMemberRound($electionNumber: Int!, $councilSlug: String!, $address: String!, $includeAddressData: Boolean!, $input: GovernorInput!) {
  election(number: $electionNumber, councilSlug: $councilSlug) {
    memberRound {
      id
      status
      fullWeightDuration
      start {
        ts
      }
      end {
        ts
      }
      availableVotes(address: $address) @include(if: $includeAddressData)
    }
    accountElectionMeta(address: $address) @include(if: $includeAddressData) {
      hasRegistered
      isContender
    }
  }
  governor(input: $input) {
    id
    chainId
    contracts {
      governor {
        address
        type
      }
    }
    token {
      decimals
    }
  }
}
    `;
export const useElectionMemberRoundQuery = <
      TData = ElectionMemberRoundQuery,
      TError = Error
    >(
      variables: ElectionMemberRoundQueryVariables,
      options?: UseQueryOptions<ElectionMemberRoundQuery, TError, TData>
    ) =>
    useQuery<ElectionMemberRoundQuery, TError, TData>(
      ['ElectionMemberRound', variables],
      useFetchData<ElectionMemberRoundQuery, ElectionMemberRoundQueryVariables>(ElectionMemberRoundDocument).bind(null, variables),
      options
    );
export const ElectionMemberRoundNomineesDocument = `
    query ElectionMemberRoundNominees($electionNumber: Int!, $councilSlug: String!, $pagination: Pagination, $sort: CandidateSort) {
  election(number: $electionNumber, councilSlug: $councilSlug) {
    memberRound {
      nominees(pagination: $pagination, sort: $sort) {
        id
        account {
          address
          name
          bio
          picture
        }
        totalVotes
        accountElectionMeta {
          title
        }
      }
    }
  }
}
    `;
export const useElectionMemberRoundNomineesQuery = <
      TData = ElectionMemberRoundNomineesQuery,
      TError = Error
    >(
      variables: ElectionMemberRoundNomineesQueryVariables,
      options?: UseQueryOptions<ElectionMemberRoundNomineesQuery, TError, TData>
    ) =>
    useQuery<ElectionMemberRoundNomineesQuery, TError, TData>(
      ['ElectionMemberRoundNominees', variables],
      useFetchData<ElectionMemberRoundNomineesQuery, ElectionMemberRoundNomineesQueryVariables>(ElectionMemberRoundNomineesDocument).bind(null, variables),
      options
    );
export const ElectionNominationRoundDocument = `
    query ElectionNominationRound($electionNumber: Int!, $councilSlug: String!, $address: String!, $includeAddressData: Boolean!, $input: GovernorInput!) {
  election(number: $electionNumber, councilSlug: $councilSlug) {
    number
    status
    nominationRound {
      id
      status
      start {
        ts
      }
      end {
        ts
      }
      endNomineeVotingPeriod {
        ts
      }
      vettingDuration
      threshold
      availableVotes(address: $address) @include(if: $includeAddressData)
    }
    memberRound {
      id
      status
      fullWeightDuration
      start {
        ts
      }
      end {
        ts
      }
    }
    accountElectionMeta(address: $address) @include(if: $includeAddressData) {
      hasRegistered
      isContender
    }
  }
  governor(input: $input) {
    id
    chainId
    contracts {
      governor {
        address
        type
      }
    }
    token {
      decimals
    }
  }
}
    `;
export const useElectionNominationRoundQuery = <
      TData = ElectionNominationRoundQuery,
      TError = Error
    >(
      variables: ElectionNominationRoundQueryVariables,
      options?: UseQueryOptions<ElectionNominationRoundQuery, TError, TData>
    ) =>
    useQuery<ElectionNominationRoundQuery, TError, TData>(
      ['ElectionNominationRound', variables],
      useFetchData<ElectionNominationRoundQuery, ElectionNominationRoundQueryVariables>(ElectionNominationRoundDocument).bind(null, variables),
      options
    );
export const ElectionNominationRoundContendersDocument = `
    query ElectionNominationRoundContenders($electionNumber: Int!, $councilSlug: String!, $pagination: Pagination, $sort: CandidateSort, $filter: ContenderFilter) {
  nominationRound(electionNumber: $electionNumber, councilSlug: $councilSlug) {
    contenders(pagination: $pagination, sort: $sort, filter: $filter) {
      id
      account {
        address
        name
        bio
        picture
      }
      totalVotes
      nominated
      rejected
      accountElectionMeta {
        title
      }
    }
  }
}
    `;
export const useElectionNominationRoundContendersQuery = <
      TData = ElectionNominationRoundContendersQuery,
      TError = Error
    >(
      variables: ElectionNominationRoundContendersQueryVariables,
      options?: UseQueryOptions<ElectionNominationRoundContendersQuery, TError, TData>
    ) =>
    useQuery<ElectionNominationRoundContendersQuery, TError, TData>(
      ['ElectionNominationRoundContenders', variables],
      useFetchData<ElectionNominationRoundContendersQuery, ElectionNominationRoundContendersQueryVariables>(ElectionNominationRoundContendersDocument).bind(null, variables),
      options
    );
export const MemberRoundDecayVotesDocument = `
    query MemberRoundDecayVotes($councilSlug: String!, $electionNumber: Int!, $votes: Uint256!) {
  election(councilSlug: $councilSlug, number: $electionNumber) {
    memberRound {
      votesToWeight(votes: $votes)
    }
  }
}
    `;
export const useMemberRoundDecayVotesQuery = <
      TData = MemberRoundDecayVotesQuery,
      TError = Error
    >(
      variables: MemberRoundDecayVotesQueryVariables,
      options?: UseQueryOptions<MemberRoundDecayVotesQuery, TError, TData>
    ) =>
    useQuery<MemberRoundDecayVotesQuery, TError, TData>(
      ['MemberRoundDecayVotes', variables],
      useFetchData<MemberRoundDecayVotesQuery, MemberRoundDecayVotesQueryVariables>(MemberRoundDecayVotesDocument).bind(null, variables),
      options
    );
export const NomineeByAddressOrEnsDocument = `
    query NomineeByAddressOrEns($electionNumber: Int!, $councilSlug: String!, $address: String, $ens: String, $isAddress: Boolean!) {
  nominee(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    address: $address
  ) @include(if: $isAddress) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
  nomineeByEns: nominee(
    electionNumber: $electionNumber
    councilSlug: $councilSlug
    ens: $ens
  ) @skip(if: $isAddress) {
    id
    account {
      id
      address
      picture
      ens
      twitter
      name
    }
    totalVotes
    accountElectionMeta {
      title
      statement
      isContender
      hasRegistered
    }
  }
}
    `;
export const useNomineeByAddressOrEnsQuery = <
      TData = NomineeByAddressOrEnsQuery,
      TError = Error
    >(
      variables: NomineeByAddressOrEnsQueryVariables,
      options?: UseQueryOptions<NomineeByAddressOrEnsQuery, TError, TData>
    ) =>
    useQuery<NomineeByAddressOrEnsQuery, TError, TData>(
      ['NomineeByAddressOrEns', variables],
      useFetchData<NomineeByAddressOrEnsQuery, NomineeByAddressOrEnsQueryVariables>(NomineeByAddressOrEnsDocument).bind(null, variables),
      options
    );
export const RegisterAttemptDocument = `
    mutation RegisterAttempt($councilSlug: String!, $electionNumber: Int!, $address: String!, $txHash: String!, $title: String, $statement: String, $email: String) {
  registerAsContenderAttempt(
    councilSlug: $councilSlug
    electionNumber: $electionNumber
    address: $address
    hash: $txHash
    title: $title
    statement: $statement
    email: $email
  )
}
    `;
export const useRegisterAttemptMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RegisterAttemptMutation, TError, RegisterAttemptMutationVariables, TContext>) =>
    useMutation<RegisterAttemptMutation, TError, RegisterAttemptMutationVariables, TContext>(
      ['RegisterAttempt'],
      useFetchData<RegisterAttemptMutation, RegisterAttemptMutationVariables>(RegisterAttemptDocument),
      options
    );
export const ActionsSecurityCheckDocument = `
    query ActionsSecurityCheck($input: ProposalSecurityCheckInput!) {
  actionsSecurityCheck(input: $input) {
    metadata {
      threatAnalysis {
        actionsData {
          events {
            eventType
            severity
            description
          }
          result
        }
        proposerRisk
      }
    }
    simulations {
      publicURI
      result
    }
  }
}
    `;
export const useActionsSecurityCheckQuery = <
      TData = ActionsSecurityCheckQuery,
      TError = Error
    >(
      variables: ActionsSecurityCheckQueryVariables,
      options?: UseQueryOptions<ActionsSecurityCheckQuery, TError, TData>
    ) =>
    useQuery<ActionsSecurityCheckQuery, TError, TData>(
      ['ActionsSecurityCheck', variables],
      useFetchData<ActionsSecurityCheckQuery, ActionsSecurityCheckQueryVariables>(ActionsSecurityCheckDocument).bind(null, variables),
      options
    );
export const CreateProposalGovernanceSelectorDocument = `
    query CreateProposalGovernanceSelector($input: GovernorsInput!) {
  governors(input: $input) {
    nodes {
      ... on Governor {
        id
        chainId
        name
        quorum
        kind
        token {
          id
          decimals
        }
        parameters {
          proposalThreshold
          votingPeriod
          votingDelay
        }
        contracts {
          governor {
            address
            type
          }
          tokens {
            address
            type
          }
        }
      }
    }
  }
  chains {
    id
    blockTime
    layer1Id
    useLayer1VotingPeriod
  }
}
    `;
export const useCreateProposalGovernanceSelectorQuery = <
      TData = CreateProposalGovernanceSelectorQuery,
      TError = Error
    >(
      variables: CreateProposalGovernanceSelectorQueryVariables,
      options?: UseQueryOptions<CreateProposalGovernanceSelectorQuery, TError, TData>
    ) =>
    useQuery<CreateProposalGovernanceSelectorQuery, TError, TData>(
      ['CreateProposalGovernanceSelector', variables],
      useFetchData<CreateProposalGovernanceSelectorQuery, CreateProposalGovernanceSelectorQueryVariables>(CreateProposalGovernanceSelectorDocument).bind(null, variables),
      options
    );
export const EditTallyProposalDocument = `
    query EditTallyProposal($input: ProposalInput!) {
  proposal(input: $input) {
    id
    status
    createdAt
    proposer {
      address
      name
    }
    metadata {
      description
      title
      snapshotURL
    }
    governor {
      id
    }
    executableCalls {
      value
      target
      calldata
      signature
      type
      offchaindata {
        ... on ExecutableCallSwap {
          amountIn
          buyToken {
            id
            data {
              decimals
              name
              symbol
              logo
              price
            }
          }
          sellToken {
            id
            data {
              decimals
              name
              symbol
              logo
              price
            }
          }
          to
          priceChecker {
            slippage
            tokenPath
            feePath
            uniPoolPath
          }
          quote {
            buyAmount
            buyTokenQuoteRate
            sellAmount
            feeAmount
            validTo
          }
          order {
            status
            address
            id
            buyAmount
          }
        }
        ... on ExecutableCallRewards {
          contributorFee
          tallyFee
          recipients
        }
      }
    }
  }
}
    `;
export const useEditTallyProposalQuery = <
      TData = EditTallyProposalQuery,
      TError = Error
    >(
      variables: EditTallyProposalQueryVariables,
      options?: UseQueryOptions<EditTallyProposalQuery, TError, TData>
    ) =>
    useQuery<EditTallyProposalQuery, TError, TData>(
      ['EditTallyProposal', variables],
      useFetchData<EditTallyProposalQuery, EditTallyProposalQueryVariables>(EditTallyProposalDocument).bind(null, variables),
      options
    );
export const ManageOrcaPodRecipeDocument = `
    query ManageOrcaPodRecipe($accountIds: [AccountID!]) {
  accounts(ids: $accountIds) {
    name
    address
    picture
    ens
    twitter
  }
}
    `;
export const useManageOrcaPodRecipeQuery = <
      TData = ManageOrcaPodRecipeQuery,
      TError = Error
    >(
      variables?: ManageOrcaPodRecipeQueryVariables,
      options?: UseQueryOptions<ManageOrcaPodRecipeQuery, TError, TData>
    ) =>
    useQuery<ManageOrcaPodRecipeQuery, TError, TData>(
      variables === undefined ? ['ManageOrcaPodRecipe'] : ['ManageOrcaPodRecipe', variables],
      useFetchData<ManageOrcaPodRecipeQuery, ManageOrcaPodRecipeQueryVariables>(ManageOrcaPodRecipeDocument).bind(null, variables),
      options
    );
export const RecipientAddressDocument = `
    query RecipientAddress($address: Address!) {
  address(address: $address) {
    ethAccount {
      address
      bio
      ens
      picture
      name
      twitter
    }
  }
}
    `;
export const useRecipientAddressQuery = <
      TData = RecipientAddressQuery,
      TError = Error
    >(
      variables: RecipientAddressQueryVariables,
      options?: UseQueryOptions<RecipientAddressQuery, TError, TData>
    ) =>
    useQuery<RecipientAddressQuery, TError, TData>(
      ['RecipientAddress', variables],
      useFetchData<RecipientAddressQuery, RecipientAddressQueryVariables>(RecipientAddressDocument).bind(null, variables),
      options
    );
export const AvailableSwapsDocument = `
    query AvailableSwaps($governorId: AccountID!) {
  availableSwaps(governorID: $governorId) {
    buy {
      id
      name
      symbol
      logo
      decimals
    }
    sell {
      address
      balance
      decimals
      logo
      name
      quoteRate
      symbol
    }
  }
}
    `;
export const useAvailableSwapsQuery = <
      TData = AvailableSwapsQuery,
      TError = Error
    >(
      variables: AvailableSwapsQueryVariables,
      options?: UseQueryOptions<AvailableSwapsQuery, TError, TData>
    ) =>
    useQuery<AvailableSwapsQuery, TError, TData>(
      ['AvailableSwaps', variables],
      useFetchData<AvailableSwapsQuery, AvailableSwapsQueryVariables>(AvailableSwapsDocument).bind(null, variables),
      options
    );
export const QuoteSwapDocument = `
    query QuoteSwap($governorID: AccountID!, $buy: AccountID!, $sell: AccountID!, $sellAmount: Uint256!) {
  quoteSwap(
    governorID: $governorID
    buy: $buy
    sell: $sell
    sellAmount: $sellAmount
  ) {
    buyAmount
    buyTokenQuoteRate
    sellAmount
    feeAmount
  }
}
    `;
export const useQuoteSwapQuery = <
      TData = QuoteSwapQuery,
      TError = Error
    >(
      variables: QuoteSwapQueryVariables,
      options?: UseQueryOptions<QuoteSwapQuery, TError, TData>
    ) =>
    useQuery<QuoteSwapQuery, TError, TData>(
      ['QuoteSwap', variables],
      useFetchData<QuoteSwapQuery, QuoteSwapQueryVariables>(QuoteSwapDocument).bind(null, variables),
      options
    );
export const TransferTokensRecipeDocument = `
    query TransferTokensRecipe($accountIds: [AccountID!]) {
  accounts(ids: $accountIds) {
    name
    address
    picture
    twitter
  }
}
    `;
export const useTransferTokensRecipeQuery = <
      TData = TransferTokensRecipeQuery,
      TError = Error
    >(
      variables?: TransferTokensRecipeQueryVariables,
      options?: UseQueryOptions<TransferTokensRecipeQuery, TError, TData>
    ) =>
    useQuery<TransferTokensRecipeQuery, TError, TData>(
      variables === undefined ? ['TransferTokensRecipe'] : ['TransferTokensRecipe', variables],
      useFetchData<TransferTokensRecipeQuery, TransferTokensRecipeQueryVariables>(TransferTokensRecipeDocument).bind(null, variables),
      options
    );
export const TransferTokensReceiptDocument = `
    query TransferTokensReceipt($accountIds: [AccountID!]) {
  accounts(ids: $accountIds) {
    name
    address
    picture
    twitter
  }
}
    `;
export const useTransferTokensReceiptQuery = <
      TData = TransferTokensReceiptQuery,
      TError = Error
    >(
      variables?: TransferTokensReceiptQueryVariables,
      options?: UseQueryOptions<TransferTokensReceiptQuery, TError, TData>
    ) =>
    useQuery<TransferTokensReceiptQuery, TError, TData>(
      variables === undefined ? ['TransferTokensReceipt'] : ['TransferTokensReceipt', variables],
      useFetchData<TransferTokensReceiptQuery, TransferTokensReceiptQueryVariables>(TransferTokensReceiptDocument).bind(null, variables),
      options
    );
export const CreateProposalContentDocument = `
    query CreateProposalContent($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    name
    timelockId
    contracts {
      governor {
        address
        type
      }
      tokens {
        address
      }
    }
    organization {
      name
      metadata {
        icon
      }
      governorIds
    }
    token {
      decimals
    }
    kind
    parameters {
      proposalThreshold
    }
  }
}
    `;
export const useCreateProposalContentQuery = <
      TData = CreateProposalContentQuery,
      TError = Error
    >(
      variables: CreateProposalContentQueryVariables,
      options?: UseQueryOptions<CreateProposalContentQuery, TError, TData>
    ) =>
    useQuery<CreateProposalContentQuery, TError, TData>(
      ['CreateProposalContent', variables],
      useFetchData<CreateProposalContentQuery, CreateProposalContentQueryVariables>(CreateProposalContentDocument).bind(null, variables),
      options
    );
export const CreateTallyProposalDocument = `
    mutation CreateTallyProposal($input: CreateProposalInput!) {
  createProposal(input: $input) {
    id
    metadata {
      txHash
    }
  }
}
    `;
export const useCreateTallyProposalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTallyProposalMutation, TError, CreateTallyProposalMutationVariables, TContext>) =>
    useMutation<CreateTallyProposalMutation, TError, CreateTallyProposalMutationVariables, TContext>(
      ['CreateTallyProposal'],
      useFetchData<CreateTallyProposalMutation, CreateTallyProposalMutationVariables>(CreateTallyProposalDocument),
      options
    );
export const ProposalThresholdRequirementDocument = `
    query ProposalThresholdRequirement($governorId: AccountID!, $address: Address!) {
  delegate(input: {address: $address, governorId: $governorId}) {
    votesCount
  }
}
    `;
export const useProposalThresholdRequirementQuery = <
      TData = ProposalThresholdRequirementQuery,
      TError = Error
    >(
      variables: ProposalThresholdRequirementQueryVariables,
      options?: UseQueryOptions<ProposalThresholdRequirementQuery, TError, TData>
    ) =>
    useQuery<ProposalThresholdRequirementQuery, TError, TData>(
      ['ProposalThresholdRequirement', variables],
      useFetchData<ProposalThresholdRequirementQuery, ProposalThresholdRequirementQueryVariables>(ProposalThresholdRequirementDocument).bind(null, variables),
      options
    );
export const DelegateTokenBalancesDocument = `
    query DelegateTokenBalances($input: TokenBalancesInput!) {
  tokenBalances(input: $input) {
    balance
    token {
      id
      symbol
      decimals
    }
  }
}
    `;
export const useDelegateTokenBalancesQuery = <
      TData = DelegateTokenBalancesQuery,
      TError = Error
    >(
      variables: DelegateTokenBalancesQueryVariables,
      options?: UseQueryOptions<DelegateTokenBalancesQuery, TError, TData>
    ) =>
    useQuery<DelegateTokenBalancesQuery, TError, TData>(
      ['DelegateTokenBalances', variables],
      useFetchData<DelegateTokenBalancesQuery, DelegateTokenBalancesQueryVariables>(DelegateTokenBalancesDocument).bind(null, variables),
      options
    );
export const DelegationAttemptDocument = `
    query DelegationAttempt($delegatorId: AccountID!, $governanceId: AccountID!) {
  delegationAttempt(delegatorId: $delegatorId, governanceId: $governanceId) {
    createdAt
    txID
  }
  chains {
    id
    blockTime
  }
}
    `;
export const useDelegationAttemptQuery = <
      TData = DelegationAttemptQuery,
      TError = Error
    >(
      variables: DelegationAttemptQueryVariables,
      options?: UseQueryOptions<DelegationAttemptQuery, TError, TData>
    ) =>
    useQuery<DelegationAttemptQuery, TError, TData>(
      ['DelegationAttempt', variables],
      useFetchData<DelegationAttemptQuery, DelegationAttemptQueryVariables>(DelegationAttemptDocument).bind(null, variables),
      options
    );
export const SecurityCouncilActionBannerDocument = `
    query SecurityCouncilActionBanner($gnosisSafeId: AccountID!, $allTransactions: Boolean, $pagination: Pagination) {
  gnosisSafeTransactions(
    gnosisSafeId: $gnosisSafeId
    allTransactions: $allTransactions
    pagination: $pagination
  ) {
    id
    safeID
    block {
      timestamp
      ts
    }
    dataDecoded {
      method
    }
  }
}
    `;
export const useSecurityCouncilActionBannerQuery = <
      TData = SecurityCouncilActionBannerQuery,
      TError = Error
    >(
      variables: SecurityCouncilActionBannerQueryVariables,
      options?: UseQueryOptions<SecurityCouncilActionBannerQuery, TError, TData>
    ) =>
    useQuery<SecurityCouncilActionBannerQuery, TError, TData>(
      ['SecurityCouncilActionBanner', variables],
      useFetchData<SecurityCouncilActionBannerQuery, SecurityCouncilActionBannerQueryVariables>(SecurityCouncilActionBannerDocument).bind(null, variables),
      options
    );
export const GovernanceGovernorTypeDocument = `
    query GovernanceGovernorType($input: GovernorInput!) {
  governor(input: $input) {
    contracts {
      governor {
        type
      }
    }
  }
}
    `;
export const useGovernanceGovernorTypeQuery = <
      TData = GovernanceGovernorTypeQuery,
      TError = Error
    >(
      variables: GovernanceGovernorTypeQueryVariables,
      options?: UseQueryOptions<GovernanceGovernorTypeQuery, TError, TData>
    ) =>
    useQuery<GovernanceGovernorTypeQuery, TError, TData>(
      ['GovernanceGovernorType', variables],
      useFetchData<GovernanceGovernorTypeQuery, GovernanceGovernorTypeQueryVariables>(GovernanceGovernorTypeDocument).bind(null, variables),
      options
    );
export const GovernanceSponsorDelegationByIdDocument = `
    query GovernanceSponsorDelegationById($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    contracts {
      tokens {
        type
        address
      }
    }
  }
}
    `;
export const useGovernanceSponsorDelegationByIdQuery = <
      TData = GovernanceSponsorDelegationByIdQuery,
      TError = Error
    >(
      variables: GovernanceSponsorDelegationByIdQueryVariables,
      options?: UseQueryOptions<GovernanceSponsorDelegationByIdQuery, TError, TData>
    ) =>
    useQuery<GovernanceSponsorDelegationByIdQuery, TError, TData>(
      ['GovernanceSponsorDelegationById', variables],
      useFetchData<GovernanceSponsorDelegationByIdQuery, GovernanceSponsorDelegationByIdQueryVariables>(GovernanceSponsorDelegationByIdDocument).bind(null, variables),
      options
    );
export const AddressDelegateeDocument = `
    query AddressDelegatee($input: DelegationInput!) {
  delegatee(input: $input) {
    delegate {
      address
    }
  }
}
    `;
export const useAddressDelegateeQuery = <
      TData = AddressDelegateeQuery,
      TError = Error
    >(
      variables: AddressDelegateeQueryVariables,
      options?: UseQueryOptions<AddressDelegateeQuery, TError, TData>
    ) =>
    useQuery<AddressDelegateeQuery, TError, TData>(
      ['AddressDelegatee', variables],
      useFetchData<AddressDelegateeQuery, AddressDelegateeQueryVariables>(AddressDelegateeDocument).bind(null, variables),
      options
    );
export const CreateDelegationAttemptDocument = `
    mutation CreateDelegationAttempt($delegatorId: AccountID!, $delegateeId: AccountID!, $tokenId: AssetID, $txID: Bytes32!) {
  createDelegationAttempt(
    delegatorId: $delegatorId
    delegateeId: $delegateeId
    tokenId: $tokenId
    txID: $txID
  )
}
    `;
export const useCreateDelegationAttemptMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDelegationAttemptMutation, TError, CreateDelegationAttemptMutationVariables, TContext>) =>
    useMutation<CreateDelegationAttemptMutation, TError, CreateDelegationAttemptMutationVariables, TContext>(
      ['CreateDelegationAttempt'],
      useFetchData<CreateDelegationAttemptMutation, CreateDelegationAttemptMutationVariables>(CreateDelegationAttemptDocument),
      options
    );
export const GovernorMetadataDocument = `
    query GovernorMetadata($input: GovernorInput!) {
  governor(input: $input) {
    id
    kind
    chainId
    quorum
    contracts {
      tokens {
        address
        type
      }
    }
    token {
      decimals
    }
    timelockId
    organization {
      id
      name
      slug
      metadata {
        description
        icon
        socials {
          discord
          telegram
          twitter
          others {
            label
            value
          }
          website
        }
      }
      governorIds
    }
  }
}
    `;
export const useGovernorMetadataQuery = <
      TData = GovernorMetadataQuery,
      TError = Error
    >(
      variables: GovernorMetadataQueryVariables,
      options?: UseQueryOptions<GovernorMetadataQuery, TError, TData>
    ) =>
    useQuery<GovernorMetadataQuery, TError, TData>(
      ['GovernorMetadata', variables],
      useFetchData<GovernorMetadataQuery, GovernorMetadataQueryVariables>(GovernorMetadataDocument).bind(null, variables),
      options
    );
export const GovernanceFeaturesDocument = `
    query GovernanceFeatures($input: GovernorInput!) {
  governor(input: $input) {
    slug
    features {
      name
      enabled
    }
  }
}
    `;
export const useGovernanceFeaturesQuery = <
      TData = GovernanceFeaturesQuery,
      TError = Error
    >(
      variables: GovernanceFeaturesQueryVariables,
      options?: UseQueryOptions<GovernanceFeaturesQuery, TError, TData>
    ) =>
    useQuery<GovernanceFeaturesQuery, TError, TData>(
      ['GovernanceFeatures', variables],
      useFetchData<GovernanceFeaturesQuery, GovernanceFeaturesQueryVariables>(GovernanceFeaturesDocument).bind(null, variables),
      options
    );
export const DelegateButtonDocument = `
    query DelegateButton($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    name
    organization {
      name
    }
    contracts {
      tokens {
        type
        address
      }
      governor {
        type
      }
    }
  }
}
    `;
export const useDelegateButtonQuery = <
      TData = DelegateButtonQuery,
      TError = Error
    >(
      variables: DelegateButtonQueryVariables,
      options?: UseQueryOptions<DelegateButtonQuery, TError, TData>
    ) =>
    useQuery<DelegateButtonQuery, TError, TData>(
      ['DelegateButton', variables],
      useFetchData<DelegateButtonQuery, DelegateButtonQueryVariables>(DelegateButtonDocument).bind(null, variables),
      options
    );
export const GovernanceClaimAirdropDocument = `
    query GovernanceClaimAirdrop($input: GovernorInput!) {
  governor(input: $input) {
    name
    token {
      id
      name
      symbol
      decimals
    }
  }
}
    `;
export const useGovernanceClaimAirdropQuery = <
      TData = GovernanceClaimAirdropQuery,
      TError = Error
    >(
      variables: GovernanceClaimAirdropQueryVariables,
      options?: UseQueryOptions<GovernanceClaimAirdropQuery, TError, TData>
    ) =>
    useQuery<GovernanceClaimAirdropQuery, TError, TData>(
      ['GovernanceClaimAirdrop', variables],
      useFetchData<GovernanceClaimAirdropQuery, GovernanceClaimAirdropQueryVariables>(GovernanceClaimAirdropDocument).bind(null, variables),
      options
    );
export const GovernanceDelegateProfileEditDocument = `
    query GovernanceDelegateProfileEdit($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    name
    organization {
      name
    }
    contracts {
      tokens {
        address
        type
      }
    }
  }
}
    `;
export const useGovernanceDelegateProfileEditQuery = <
      TData = GovernanceDelegateProfileEditQuery,
      TError = Error
    >(
      variables: GovernanceDelegateProfileEditQueryVariables,
      options?: UseQueryOptions<GovernanceDelegateProfileEditQuery, TError, TData>
    ) =>
    useQuery<GovernanceDelegateProfileEditQuery, TError, TData>(
      ['GovernanceDelegateProfileEdit', variables],
      useFetchData<GovernanceDelegateProfileEditQuery, GovernanceDelegateProfileEditQueryVariables>(GovernanceDelegateProfileEditDocument).bind(null, variables),
      options
    );
export const DelegatesDocument = `
    query Delegates($input: DelegatesInput!) {
  delegates(input: $input) {
    nodes {
      ... on Delegate {
        id
        account {
          address
          bio
          name
          picture
          twitter
        }
        votesCount
        delegatorsCount
        statement {
          statementSummary
        }
        token {
          symbol
          decimals
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useDelegatesQuery = <
      TData = DelegatesQuery,
      TError = Error
    >(
      variables: DelegatesQueryVariables,
      options?: UseQueryOptions<DelegatesQuery, TError, TData>
    ) =>
    useQuery<DelegatesQuery, TError, TData>(
      ['Delegates', variables],
      useFetchData<DelegatesQuery, DelegatesQueryVariables>(DelegatesDocument).bind(null, variables),
      options
    );
export const SearchByEnsDocument = `
    query SearchByEns($searchString: String!) {
  accountByEns(ens: $searchString) {
    address
    bio
    picture
    name
    twitter
  }
}
    `;
export const useSearchByEnsQuery = <
      TData = SearchByEnsQuery,
      TError = Error
    >(
      variables: SearchByEnsQueryVariables,
      options?: UseQueryOptions<SearchByEnsQuery, TError, TData>
    ) =>
    useQuery<SearchByEnsQuery, TError, TData>(
      ['SearchByEns', variables],
      useFetchData<SearchByEnsQuery, SearchByEnsQueryVariables>(SearchByEnsDocument).bind(null, variables),
      options
    );
export const GovernanceDelegatesSummaryDocument = `
    query GovernanceDelegatesSummary($input: GovernorInput!) {
  governor(input: $input) {
    id
    name
    chainId
    contracts {
      tokens {
        type
        address
      }
      governor {
        type
      }
    }
    quorum
    parameters {
      proposalThreshold
    }
    token {
      supply
      symbol
      decimals
    }
  }
}
    `;
export const useGovernanceDelegatesSummaryQuery = <
      TData = GovernanceDelegatesSummaryQuery,
      TError = Error
    >(
      variables: GovernanceDelegatesSummaryQueryVariables,
      options?: UseQueryOptions<GovernanceDelegatesSummaryQuery, TError, TData>
    ) =>
    useQuery<GovernanceDelegatesSummaryQuery, TError, TData>(
      ['GovernanceDelegatesSummary', variables],
      useFetchData<GovernanceDelegatesSummaryQuery, GovernanceDelegatesSummaryQueryVariables>(GovernanceDelegatesSummaryDocument).bind(null, variables),
      options
    );
export const GovernanceHeaderDocument = `
    query GovernanceHeader($input: GovernorInput!) {
  governor(input: $input) {
    id
    name
    chainId
    delegatesVotesCount
    token {
      type
      supply
      decimals
      symbol
    }
    contracts {
      governor {
        type
      }
      tokens {
        address
        type
        address
      }
    }
    organization {
      id
      metadata {
        description
        icon
        socials {
          discord
          telegram
          twitter
          others {
            label
            value
          }
          website
        }
      }
      name
      slug
    }
    isBehind
  }
}
    `;
export const useGovernanceHeaderQuery = <
      TData = GovernanceHeaderQuery,
      TError = Error
    >(
      variables: GovernanceHeaderQueryVariables,
      options?: UseQueryOptions<GovernanceHeaderQuery, TError, TData>
    ) =>
    useQuery<GovernanceHeaderQuery, TError, TData>(
      ['GovernanceHeader', variables],
      useFetchData<GovernanceHeaderQuery, GovernanceHeaderQueryVariables>(GovernanceHeaderDocument).bind(null, variables),
      options
    );
export const GovernanceIdtoOrgIdDocument = `
    query GovernanceIdtoOrgId($input: GovernorInput!) {
  governor(input: $input) {
    organization {
      id
      name
      slug
      metadata {
        description
        icon
        socials {
          discord
          telegram
          twitter
          others {
            label
            value
          }
          website
        }
      }
    }
  }
}
    `;
export const useGovernanceIdtoOrgIdQuery = <
      TData = GovernanceIdtoOrgIdQuery,
      TError = Error
    >(
      variables: GovernanceIdtoOrgIdQueryVariables,
      options?: UseQueryOptions<GovernanceIdtoOrgIdQuery, TError, TData>
    ) =>
    useQuery<GovernanceIdtoOrgIdQuery, TError, TData>(
      ['GovernanceIdtoOrgId', variables],
      useFetchData<GovernanceIdtoOrgIdQuery, GovernanceIdtoOrgIdQueryVariables>(GovernanceIdtoOrgIdDocument).bind(null, variables),
      options
    );
export const OrganizationDelegateInformationDocument = `
    query OrganizationDelegateInformation($input: DelegateInput!) {
  delegate(input: $input) {
    id
    delegatorsCount
    votesCount
    token {
      decimals
      symbol
    }
  }
}
    `;
export const useOrganizationDelegateInformationQuery = <
      TData = OrganizationDelegateInformationQuery,
      TError = Error
    >(
      variables: OrganizationDelegateInformationQueryVariables,
      options?: UseQueryOptions<OrganizationDelegateInformationQuery, TError, TData>
    ) =>
    useQuery<OrganizationDelegateInformationQuery, TError, TData>(
      ['OrganizationDelegateInformation', variables],
      useFetchData<OrganizationDelegateInformationQuery, OrganizationDelegateInformationQueryVariables>(OrganizationDelegateInformationDocument).bind(null, variables),
      options
    );
export const OrganizationDelegateChainsDocument = `
    query OrganizationDelegateChains($input: DelegationsInput!) {
  delegatees(input: $input) {
    nodes {
      ... on Delegation {
        chainId
        token {
          decimals
          symbol
        }
        votes
      }
    }
  }
}
    `;
export const useOrganizationDelegateChainsQuery = <
      TData = OrganizationDelegateChainsQuery,
      TError = Error
    >(
      variables: OrganizationDelegateChainsQueryVariables,
      options?: UseQueryOptions<OrganizationDelegateChainsQuery, TError, TData>
    ) =>
    useQuery<OrganizationDelegateChainsQuery, TError, TData>(
      ['OrganizationDelegateChains', variables],
      useFetchData<OrganizationDelegateChainsQuery, OrganizationDelegateChainsQueryVariables>(OrganizationDelegateChainsDocument).bind(null, variables),
      options
    );
export const OrganizationReceivedDelegationsDocument = `
    query OrganizationReceivedDelegations($input: DelegationsInput!) {
  delegators(input: $input) {
    nodes {
      ... on Delegation {
        chainId
        delegator {
          address
          name
          picture
          twitter
          ens
        }
        blockNumber
        blockTimestamp
        votes
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useOrganizationReceivedDelegationsQuery = <
      TData = OrganizationReceivedDelegationsQuery,
      TError = Error
    >(
      variables: OrganizationReceivedDelegationsQueryVariables,
      options?: UseQueryOptions<OrganizationReceivedDelegationsQuery, TError, TData>
    ) =>
    useQuery<OrganizationReceivedDelegationsQuery, TError, TData>(
      ['OrganizationReceivedDelegations', variables],
      useFetchData<OrganizationReceivedDelegationsQuery, OrganizationReceivedDelegationsQueryVariables>(OrganizationReceivedDelegationsDocument).bind(null, variables),
      options
    );
export const GovernanceMetaInformationDocument = `
    query GovernanceMetaInformation($input: GovernorsInput!) {
  governors(input: $input) {
    nodes {
      ... on Governor {
        id
        organization {
          metadata {
            icon
          }
        }
        contracts {
          tokens {
            address
          }
        }
        token {
          supply
        }
        proposalStats {
          total
        }
        delegatesCount
        tokenOwnersCount
      }
    }
  }
}
    `;
export const useGovernanceMetaInformationQuery = <
      TData = GovernanceMetaInformationQuery,
      TError = Error
    >(
      variables: GovernanceMetaInformationQueryVariables,
      options?: UseQueryOptions<GovernanceMetaInformationQuery, TError, TData>
    ) =>
    useQuery<GovernanceMetaInformationQuery, TError, TData>(
      ['GovernanceMetaInformation', variables],
      useFetchData<GovernanceMetaInformationQuery, GovernanceMetaInformationQueryVariables>(GovernanceMetaInformationDocument).bind(null, variables),
      options
    );
export const GovernanceModuleInformationDocument = `
    query GovernanceModuleInformation($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    name
    quorum
    timelockId
    kind
    type
    token {
      id
      decimals
    }
    parameters {
      proposalThreshold
      votingPeriod
      votingDelay
      clockMode
    }
    contracts {
      governor {
        address
        type
      }
      tokens {
        address
        type
      }
    }
    metadata {
      description
    }
  }
  chains {
    id
    blockTime
    layer1Id
    useLayer1VotingPeriod
  }
}
    `;
export const useGovernanceModuleInformationQuery = <
      TData = GovernanceModuleInformationQuery,
      TError = Error
    >(
      variables: GovernanceModuleInformationQueryVariables,
      options?: UseQueryOptions<GovernanceModuleInformationQuery, TError, TData>
    ) =>
    useQuery<GovernanceModuleInformationQuery, TError, TData>(
      ['GovernanceModuleInformation', variables],
      useFetchData<GovernanceModuleInformationQuery, GovernanceModuleInformationQueryVariables>(GovernanceModuleInformationDocument).bind(null, variables),
      options
    );
export const GovernanceMyVotingPowerDocument = `
    query GovernanceMyVotingPower($input: GovernorInput!) {
  governor(input: $input) {
    id
    token {
      symbol
      decimals
    }
  }
}
    `;
export const useGovernanceMyVotingPowerQuery = <
      TData = GovernanceMyVotingPowerQuery,
      TError = Error
    >(
      variables: GovernanceMyVotingPowerQueryVariables,
      options?: UseQueryOptions<GovernanceMyVotingPowerQuery, TError, TData>
    ) =>
    useQuery<GovernanceMyVotingPowerQuery, TError, TData>(
      ['GovernanceMyVotingPower', variables],
      useFetchData<GovernanceMyVotingPowerQuery, GovernanceMyVotingPowerQueryVariables>(GovernanceMyVotingPowerDocument).bind(null, variables),
      options
    );
export const OrganizationMyVotingPowerDelegatingToDocument = `
    query OrganizationMyVotingPowerDelegatingTo($input: DelegationsInput!) {
  delegatees(input: $input) {
    nodes {
      ... on Delegation {
        chainId
        delegate {
          name
          address
          ens
          picture
        }
        votes
        token {
          id
          name
          symbol
          decimals
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useOrganizationMyVotingPowerDelegatingToQuery = <
      TData = OrganizationMyVotingPowerDelegatingToQuery,
      TError = Error
    >(
      variables: OrganizationMyVotingPowerDelegatingToQueryVariables,
      options?: UseQueryOptions<OrganizationMyVotingPowerDelegatingToQuery, TError, TData>
    ) =>
    useQuery<OrganizationMyVotingPowerDelegatingToQuery, TError, TData>(
      ['OrganizationMyVotingPowerDelegatingTo', variables],
      useFetchData<OrganizationMyVotingPowerDelegatingToQuery, OrganizationMyVotingPowerDelegatingToQueryVariables>(OrganizationMyVotingPowerDelegatingToDocument).bind(null, variables),
      options
    );
export const OrganizationTokenBalancesDocument = `
    query OrganizationTokenBalances($input: TokenBalancesInput!) {
  tokenBalances(input: $input) {
    token {
      id
      name
      symbol
      decimals
    }
    balance
  }
}
    `;
export const useOrganizationTokenBalancesQuery = <
      TData = OrganizationTokenBalancesQuery,
      TError = Error
    >(
      variables: OrganizationTokenBalancesQueryVariables,
      options?: UseQueryOptions<OrganizationTokenBalancesQuery, TError, TData>
    ) =>
    useQuery<OrganizationTokenBalancesQuery, TError, TData>(
      ['OrganizationTokenBalances', variables],
      useFetchData<OrganizationTokenBalancesQuery, OrganizationTokenBalancesQueryVariables>(OrganizationTokenBalancesDocument).bind(null, variables),
      options
    );
export const GovernanceProposalsStatsDocument = `
    query GovernanceProposalsStats($input: GovernorsInput!) {
  governors(input: $input) {
    nodes {
      ... on Governor {
        proposalStats {
          passed
          failed
        }
      }
    }
  }
}
    `;
export const useGovernanceProposalsStatsQuery = <
      TData = GovernanceProposalsStatsQuery,
      TError = Error
    >(
      variables: GovernanceProposalsStatsQueryVariables,
      options?: UseQueryOptions<GovernanceProposalsStatsQuery, TError, TData>
    ) =>
    useQuery<GovernanceProposalsStatsQuery, TError, TData>(
      ['GovernanceProposalsStats', variables],
      useFetchData<GovernanceProposalsStatsQuery, GovernanceProposalsStatsQueryVariables>(GovernanceProposalsStatsDocument).bind(null, variables),
      options
    );
export const GovernanceProposalsDocument = `
    query GovernanceProposals($input: ProposalsInput!) {
  proposals(input: $input) {
    nodes {
      ... on Proposal {
        id
        onchainId
        status
        originalId
        createdAt
        voteStats {
          votesCount
          percent
          type
          votersCount
        }
        metadata {
          description
        }
        start {
          ... on Block {
            timestamp
          }
          ... on BlocklessTimestamp {
            timestamp
          }
        }
        block {
          timestamp
        }
        governor {
          id
          quorum
          name
          timelockId
          token {
            decimals
          }
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
      count
    }
  }
}
    `;
export const useGovernanceProposalsQuery = <
      TData = GovernanceProposalsQuery,
      TError = Error
    >(
      variables: GovernanceProposalsQueryVariables,
      options?: UseQueryOptions<GovernanceProposalsQuery, TError, TData>
    ) =>
    useQuery<GovernanceProposalsQuery, TError, TData>(
      ['GovernanceProposals', variables],
      useFetchData<GovernanceProposalsQuery, GovernanceProposalsQueryVariables>(GovernanceProposalsDocument).bind(null, variables),
      options
    );
export const GovernanceProposalsVotesDocument = `
    query GovernanceProposalsVotes($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        proposal {
          id
          governor {
            id
          }
        }
        type
        voter {
          picture
          address
          twitter
        }
      }
    }
  }
}
    `;
export const useGovernanceProposalsVotesQuery = <
      TData = GovernanceProposalsVotesQuery,
      TError = Error
    >(
      variables: GovernanceProposalsVotesQueryVariables,
      options?: UseQueryOptions<GovernanceProposalsVotesQuery, TError, TData>
    ) =>
    useQuery<GovernanceProposalsVotesQuery, TError, TData>(
      ['GovernanceProposalsVotes', variables],
      useFetchData<GovernanceProposalsVotesQuery, GovernanceProposalsVotesQueryVariables>(GovernanceProposalsVotesDocument).bind(null, variables),
      options
    );
export const GovernanceTallyProposalsDocument = `
    query GovernanceTallyProposals($input: ProposalsInput!) {
  proposals(input: $input) {
    nodes {
      ... on Proposal {
        id
        createdAt
        originalId
        metadata {
          description
          title
        }
        status
        executableCalls {
          calldata
        }
        governor {
          id
          quorum
          name
          organization {
            slug
            metadata {
              icon
            }
          }
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useGovernanceTallyProposalsQuery = <
      TData = GovernanceTallyProposalsQuery,
      TError = Error
    >(
      variables: GovernanceTallyProposalsQueryVariables,
      options?: UseQueryOptions<GovernanceTallyProposalsQuery, TError, TData>
    ) =>
    useQuery<GovernanceTallyProposalsQuery, TError, TData>(
      ['GovernanceTallyProposals', variables],
      useFetchData<GovernanceTallyProposalsQuery, GovernanceTallyProposalsQueryVariables>(GovernanceTallyProposalsDocument).bind(null, variables),
      options
    );
export const GovernanceTopAdvocatesDocument = `
    query GovernanceTopAdvocates($input: GovernorInput!, $delegatesInput: DelegatesInput!) {
  governor(input: $input) {
    id
    chainId
    token {
      decimals
      supply
    }
    delegatesVotesCount
  }
  delegates(input: $delegatesInput) {
    nodes {
      ... on Delegate {
        id
        account {
          address
          picture
          name
        }
        votesCount
        voteChanges {
          newBalance
          timestamp
        }
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useGovernanceTopAdvocatesQuery = <
      TData = GovernanceTopAdvocatesQuery,
      TError = Error
    >(
      variables: GovernanceTopAdvocatesQueryVariables,
      options?: UseQueryOptions<GovernanceTopAdvocatesQuery, TError, TData>
    ) =>
    useQuery<GovernanceTopAdvocatesQuery, TError, TData>(
      ['GovernanceTopAdvocates', variables],
      useFetchData<GovernanceTopAdvocatesQuery, GovernanceTopAdvocatesQueryVariables>(GovernanceTopAdvocatesDocument).bind(null, variables),
      options
    );
export const GovernanceTreasuryInformationDocument = `
    query GovernanceTreasuryInformation($input: GovernorsInput!) {
  governors(input: $input) {
    nodes {
      ... on Governor {
        id
        timelockId
        name
        kind
        contracts {
          governor {
            address
          }
          tokens {
            address
          }
        }
      }
    }
  }
}
    `;
export const useGovernanceTreasuryInformationQuery = <
      TData = GovernanceTreasuryInformationQuery,
      TError = Error
    >(
      variables: GovernanceTreasuryInformationQueryVariables,
      options?: UseQueryOptions<GovernanceTreasuryInformationQuery, TError, TData>
    ) =>
    useQuery<GovernanceTreasuryInformationQuery, TError, TData>(
      ['GovernanceTreasuryInformation', variables],
      useFetchData<GovernanceTreasuryInformationQuery, GovernanceTreasuryInformationQueryVariables>(GovernanceTreasuryInformationDocument).bind(null, variables),
      options
    );
export const GovernorsByOrganizationDocument = `
    query GovernorsByOrganization($input: GovernorsInput!) {
  governors(input: $input) {
    nodes {
      ... on Governor {
        id
        name
        kind
        quorum
        isPrimary
        chainId
        type
        contracts {
          governor {
            address
            type
          }
          tokens {
            address
            type
          }
        }
        token {
          id
          decimals
          name
          symbol
          type
        }
      }
    }
  }
}
    `;
export const useGovernorsByOrganizationQuery = <
      TData = GovernorsByOrganizationQuery,
      TError = Error
    >(
      variables: GovernorsByOrganizationQueryVariables,
      options?: UseQueryOptions<GovernorsByOrganizationQuery, TError, TData>
    ) =>
    useQuery<GovernorsByOrganizationQuery, TError, TData>(
      ['GovernorsByOrganization', variables],
      useFetchData<GovernorsByOrganizationQuery, GovernorsByOrganizationQueryVariables>(GovernorsByOrganizationDocument).bind(null, variables),
      options
    );
export const OrganizationIssuesDocument = `
    query OrganizationIssues($input: IssuesInput) {
  issues(input: $input) {
    id
    name
    organizationId
  }
}
    `;
export const useOrganizationIssuesQuery = <
      TData = OrganizationIssuesQuery,
      TError = Error
    >(
      variables?: OrganizationIssuesQueryVariables,
      options?: UseQueryOptions<OrganizationIssuesQuery, TError, TData>
    ) =>
    useQuery<OrganizationIssuesQuery, TError, TData>(
      variables === undefined ? ['OrganizationIssues'] : ['OrganizationIssues', variables],
      useFetchData<OrganizationIssuesQuery, OrganizationIssuesQueryVariables>(OrganizationIssuesDocument).bind(null, variables),
      options
    );
export const GovernanceClaimAndDelegateAttemptDocument = `
    query GovernanceClaimAndDelegateAttempt($delegatorId: AccountID!, $tokenId: AssetID!) {
  claimAndDelegateAttempt(delegatorId: $delegatorId, tokenId: $tokenId) {
    createdAt
    txID
  }
  chains {
    id
    blockTime
  }
}
    `;
export const useGovernanceClaimAndDelegateAttemptQuery = <
      TData = GovernanceClaimAndDelegateAttemptQuery,
      TError = Error
    >(
      variables: GovernanceClaimAndDelegateAttemptQueryVariables,
      options?: UseQueryOptions<GovernanceClaimAndDelegateAttemptQuery, TError, TData>
    ) =>
    useQuery<GovernanceClaimAndDelegateAttemptQuery, TError, TData>(
      ['GovernanceClaimAndDelegateAttempt', variables],
      useFetchData<GovernanceClaimAndDelegateAttemptQuery, GovernanceClaimAndDelegateAttemptQueryVariables>(GovernanceClaimAndDelegateAttemptDocument).bind(null, variables),
      options
    );
export const GovernanceClaimConfirmDocument = `
    query GovernanceClaimConfirm($input: GovernorInput!) {
  governor(input: $input) {
    id
    chainId
    contracts {
      tokens {
        address
        type
      }
      governor {
        type
      }
    }
    token {
      id
    }
  }
}
    `;
export const useGovernanceClaimConfirmQuery = <
      TData = GovernanceClaimConfirmQuery,
      TError = Error
    >(
      variables: GovernanceClaimConfirmQueryVariables,
      options?: UseQueryOptions<GovernanceClaimConfirmQuery, TError, TData>
    ) =>
    useQuery<GovernanceClaimConfirmQuery, TError, TData>(
      ['GovernanceClaimConfirm', variables],
      useFetchData<GovernanceClaimConfirmQuery, GovernanceClaimConfirmQueryVariables>(GovernanceClaimConfirmDocument).bind(null, variables),
      options
    );
export const GuardiansAccountsDocument = `
    query GuardiansAccounts($accountIds: [AccountID!]) {
  accounts(ids: $accountIds) {
    name
    address
    picture
    ens
    twitter
  }
}
    `;
export const useGuardiansAccountsQuery = <
      TData = GuardiansAccountsQuery,
      TError = Error
    >(
      variables?: GuardiansAccountsQueryVariables,
      options?: UseQueryOptions<GuardiansAccountsQuery, TError, TData>
    ) =>
    useQuery<GuardiansAccountsQuery, TError, TData>(
      variables === undefined ? ['GuardiansAccounts'] : ['GuardiansAccounts', variables],
      useFetchData<GuardiansAccountsQuery, GuardiansAccountsQueryVariables>(GuardiansAccountsDocument).bind(null, variables),
      options
    );
export const AccountByIdDocument = `
    query AccountById($id: AccountID!) {
  account(id: $id) {
    address
    picture
    name
  }
}
    `;
export const useAccountByIdQuery = <
      TData = AccountByIdQuery,
      TError = Error
    >(
      variables: AccountByIdQueryVariables,
      options?: UseQueryOptions<AccountByIdQuery, TError, TData>
    ) =>
    useQuery<AccountByIdQuery, TError, TData>(
      ['AccountById', variables],
      useFetchData<AccountByIdQuery, AccountByIdQueryVariables>(AccountByIdDocument).bind(null, variables),
      options
    );
export const ClaimAirdropEligibleDocument = `
    query ClaimAirdropEligible($input: GovernorInput!, $addressId: AccountID!) {
  governor(input: $input) {
    token {
      symbol
      decimals
      eligibility(id: $addressId) {
        amount
        status
        proof
        tx
      }
    }
  }
}
    `;
export const useClaimAirdropEligibleQuery = <
      TData = ClaimAirdropEligibleQuery,
      TError = Error
    >(
      variables: ClaimAirdropEligibleQueryVariables,
      options?: UseQueryOptions<ClaimAirdropEligibleQuery, TError, TData>
    ) =>
    useQuery<ClaimAirdropEligibleQuery, TError, TData>(
      ['ClaimAirdropEligible', variables],
      useFetchData<ClaimAirdropEligibleQuery, ClaimAirdropEligibleQueryVariables>(ClaimAirdropEligibleDocument).bind(null, variables),
      options
    );
export const CreateClaimAndDelegateAttemptDocument = `
    mutation CreateClaimAndDelegateAttempt($delegatorId: AccountID!, $tokenId: AssetID!, $delegateeId: AccountID!, $txID: HashID!, $proof: [String!], $expiry: Uint256!, $parameterV: Uint256!, $parameterR: Bytes32!, $parameterS: Bytes32!) {
  createClaimAndDelegateAttempt(
    delegatorId: $delegatorId
    tokenId: $tokenId
    delegateeId: $delegateeId
    txID: $txID
    proof: $proof
    expiry: $expiry
    parameterV: $parameterV
    parameterR: $parameterR
    parameterS: $parameterS
  )
}
    `;
export const useCreateClaimAndDelegateAttemptMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateClaimAndDelegateAttemptMutation, TError, CreateClaimAndDelegateAttemptMutationVariables, TContext>) =>
    useMutation<CreateClaimAndDelegateAttemptMutation, TError, CreateClaimAndDelegateAttemptMutationVariables, TContext>(
      ['CreateClaimAndDelegateAttempt'],
      useFetchData<CreateClaimAndDelegateAttemptMutation, CreateClaimAndDelegateAttemptMutationVariables>(CreateClaimAndDelegateAttemptDocument),
      options
    );
export const CreateUnistakerTransactionDocument = `
    mutation CreateUnistakerTransaction($input: CreateUnistakerTransactionInput!) {
  createUnistakerTransaction(input: $input)
}
    `;
export const useCreateUnistakerTransactionMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUnistakerTransactionMutation, TError, CreateUnistakerTransactionMutationVariables, TContext>) =>
    useMutation<CreateUnistakerTransactionMutation, TError, CreateUnistakerTransactionMutationVariables, TContext>(
      ['CreateUnistakerTransaction'],
      useFetchData<CreateUnistakerTransactionMutation, CreateUnistakerTransactionMutationVariables>(CreateUnistakerTransactionDocument),
      options
    );
export const GetUnistakerTransactionsDocument = `
    query GetUnistakerTransactions($accnt: AccountID!, $status: UnistakerTransactionStatus) {
  unistakerTransactions(input: {accountId: $accnt, status: $status}) {
    id
    type
    delegatee
    beneficiary
    previousAmount
    newAmount
    createdAt
    depositId
  }
}
    `;
export const useGetUnistakerTransactionsQuery = <
      TData = GetUnistakerTransactionsQuery,
      TError = Error
    >(
      variables: GetUnistakerTransactionsQueryVariables,
      options?: UseQueryOptions<GetUnistakerTransactionsQuery, TError, TData>
    ) =>
    useQuery<GetUnistakerTransactionsQuery, TError, TData>(
      ['GetUnistakerTransactions', variables],
      useFetchData<GetUnistakerTransactionsQuery, GetUnistakerTransactionsQueryVariables>(GetUnistakerTransactionsDocument).bind(null, variables),
      options
    );
export const GovernorClaimFlowDocument = `
    query GovernorClaimFlow($input: GovernorInput!) {
  governor(input: $input) {
    token {
      symbol
      decimals
    }
  }
}
    `;
export const useGovernorClaimFlowQuery = <
      TData = GovernorClaimFlowQuery,
      TError = Error
    >(
      variables: GovernorClaimFlowQueryVariables,
      options?: UseQueryOptions<GovernorClaimFlowQuery, TError, TData>
    ) =>
    useQuery<GovernorClaimFlowQuery, TError, TData>(
      ['GovernorClaimFlow', variables],
      useFetchData<GovernorClaimFlowQuery, GovernorClaimFlowQueryVariables>(GovernorClaimFlowDocument).bind(null, variables),
      options
    );
export const ResumeSyncDocument = `
    mutation ResumeSync($id: AccountID!) {
  resumeSync(id: $id)
}
    `;
export const useResumeSyncMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ResumeSyncMutation, TError, ResumeSyncMutationVariables, TContext>) =>
    useMutation<ResumeSyncMutation, TError, ResumeSyncMutationVariables, TContext>(
      ['ResumeSync'],
      useFetchData<ResumeSyncMutation, ResumeSyncMutationVariables>(ResumeSyncDocument),
      options
    );
export const UpdateUnistakerTransactionDocument = `
    mutation UpdateUnistakerTransaction($input: UpdateUnistakerTransactionInput!) {
  updateUnistakerTransaction(input: $input)
}
    `;
export const useUpdateUnistakerTransactionMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUnistakerTransactionMutation, TError, UpdateUnistakerTransactionMutationVariables, TContext>) =>
    useMutation<UpdateUnistakerTransactionMutation, TError, UpdateUnistakerTransactionMutationVariables, TContext>(
      ['UpdateUnistakerTransaction'],
      useFetchData<UpdateUnistakerTransactionMutation, UpdateUnistakerTransactionMutationVariables>(UpdateUnistakerTransactionDocument),
      options
    );
export const UpsertDelegateProfileDocument = `
    mutation UpsertDelegateProfile($input: UpsertDelegateProfileInput!) {
  upsertDelegateProfile(input: $input) {
    id
  }
}
    `;
export const useUpsertDelegateProfileMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertDelegateProfileMutation, TError, UpsertDelegateProfileMutationVariables, TContext>) =>
    useMutation<UpsertDelegateProfileMutation, TError, UpsertDelegateProfileMutationVariables, TContext>(
      ['UpsertDelegateProfile'],
      useFetchData<UpsertDelegateProfileMutation, UpsertDelegateProfileMutationVariables>(UpsertDelegateProfileDocument),
      options
    );
export const CreateCastVoteMetaTransactionDocument = `
    mutation CreateCastVoteMetaTransaction($governorId: AccountID!, $address: Address!, $transactionId: String!, $validUntil: Timestamp!, $gasPrice: Uint256!, $proposalId: ID!, $support: SupportType!) {
  createCastVoteMetaTransaction(
    governorId: $governorId
    address: $address
    transactionId: $transactionId
    validUntil: $validUntil
    gasPrice: $gasPrice
    proposalId: $proposalId
    support: $support
  ) {
    id
  }
}
    `;
export const useCreateCastVoteMetaTransactionMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCastVoteMetaTransactionMutation, TError, CreateCastVoteMetaTransactionMutationVariables, TContext>) =>
    useMutation<CreateCastVoteMetaTransactionMutation, TError, CreateCastVoteMetaTransactionMutationVariables, TContext>(
      ['CreateCastVoteMetaTransaction'],
      useFetchData<CreateCastVoteMetaTransactionMutation, CreateCastVoteMetaTransactionMutationVariables>(CreateCastVoteMetaTransactionDocument),
      options
    );
export const CreateDelegateMetaTransactionDocument = `
    mutation CreateDelegateMetaTransaction($governorId: AccountID!, $address: Address!, $tokenContractId: AssetID!, $from: Address!, $delegatee: Address!, $transactionId: String!, $validUntil: Timestamp!, $gasPrice: Uint256!) {
  createDelegateMetaTransaction(
    governorId: $governorId
    address: $address
    tokenContractId: $tokenContractId
    from: $from
    delegatee: $delegatee
    transactionId: $transactionId
    validUntil: $validUntil
    gasPrice: $gasPrice
  ) {
    id
  }
}
    `;
export const useCreateDelegateMetaTransactionMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDelegateMetaTransactionMutation, TError, CreateDelegateMetaTransactionMutationVariables, TContext>) =>
    useMutation<CreateDelegateMetaTransactionMutation, TError, CreateDelegateMetaTransactionMutationVariables, TContext>(
      ['CreateDelegateMetaTransaction'],
      useFetchData<CreateDelegateMetaTransactionMutation, CreateDelegateMetaTransactionMutationVariables>(CreateDelegateMetaTransactionDocument),
      options
    );
export const MetaTransactionsDocument = `
    query MetaTransactions($action: MetaTransactionAction!, $governorId: AccountID, $address: Address, $pagination: Pagination) {
  metaTransactions(
    action: $action
    governorId: $governorId
    address: $address
    pagination: $pagination
  ) {
    id
    governorId
    address
    action
    createdAt
    metadata {
      ... on CastVoteActionMetadata {
        proposalId
      }
    }
  }
}
    `;
export const useMetaTransactionsQuery = <
      TData = MetaTransactionsQuery,
      TError = Error
    >(
      variables: MetaTransactionsQueryVariables,
      options?: UseQueryOptions<MetaTransactionsQuery, TError, TData>
    ) =>
    useQuery<MetaTransactionsQuery, TError, TData>(
      ['MetaTransactions', variables],
      useFetchData<MetaTransactionsQuery, MetaTransactionsQueryVariables>(MetaTransactionsDocument).bind(null, variables),
      options
    );
export const OrganizationFeaturesDocument = `
    query OrganizationFeatures($input: OrganizationInput!) {
  organization(input: $input) {
    features {
      name
      enabled
    }
  }
}
    `;
export const useOrganizationFeaturesQuery = <
      TData = OrganizationFeaturesQuery,
      TError = Error
    >(
      variables: OrganizationFeaturesQueryVariables,
      options?: UseQueryOptions<OrganizationFeaturesQuery, TError, TData>
    ) =>
    useQuery<OrganizationFeaturesQuery, TError, TData>(
      ['OrganizationFeatures', variables],
      useFetchData<OrganizationFeaturesQuery, OrganizationFeaturesQueryVariables>(OrganizationFeaturesDocument).bind(null, variables),
      options
    );
export const OrganizationAddAdminFormDocument = `
    query OrganizationAddAdminForm($input: OrganizationMembersInput!) {
  organizationMembers(input: $input) {
    nodes {
      ... on Member {
        account {
          address
        }
      }
    }
  }
}
    `;
export const useOrganizationAddAdminFormQuery = <
      TData = OrganizationAddAdminFormQuery,
      TError = Error
    >(
      variables: OrganizationAddAdminFormQueryVariables,
      options?: UseQueryOptions<OrganizationAddAdminFormQuery, TError, TData>
    ) =>
    useQuery<OrganizationAddAdminFormQuery, TError, TData>(
      ['OrganizationAddAdminForm', variables],
      useFetchData<OrganizationAddAdminFormQuery, OrganizationAddAdminFormQueryVariables>(OrganizationAddAdminFormDocument).bind(null, variables),
      options
    );
export const OrganizationAdminListDocument = `
    query OrganizationAdminList($input: OrganizationMembersInput!) {
  organizationMembers(input: $input) {
    nodes {
      ... on Member {
        id
        account {
          id
          address
          name
          picture
          ens
          twitter
        }
        role
      }
    }
  }
}
    `;
export const useOrganizationAdminListQuery = <
      TData = OrganizationAdminListQuery,
      TError = Error
    >(
      variables: OrganizationAdminListQueryVariables,
      options?: UseQueryOptions<OrganizationAdminListQuery, TError, TData>
    ) =>
    useQuery<OrganizationAdminListQuery, TError, TData>(
      ['OrganizationAdminList', variables],
      useFetchData<OrganizationAdminListQuery, OrganizationAdminListQueryVariables>(OrganizationAdminListDocument).bind(null, variables),
      options
    );
export const OrganizationBasicsSettingsDocument = `
    query OrganizationBasicsSettings($input: OrganizationInput!) {
  organization(input: $input) {
    id
    name
    slug
    metadata {
      description
      socials {
        discord
        telegram
        twitter
        others {
          label
          value
        }
        website
      }
      karmaName
    }
    adminData {
      contact {
        name
        email
        twitter
        discord
      }
    }
  }
}
    `;
export const useOrganizationBasicsSettingsQuery = <
      TData = OrganizationBasicsSettingsQuery,
      TError = Error
    >(
      variables: OrganizationBasicsSettingsQueryVariables,
      options?: UseQueryOptions<OrganizationBasicsSettingsQuery, TError, TData>
    ) =>
    useQuery<OrganizationBasicsSettingsQuery, TError, TData>(
      ['OrganizationBasicsSettings', variables],
      useFetchData<OrganizationBasicsSettingsQuery, OrganizationBasicsSettingsQueryVariables>(OrganizationBasicsSettingsDocument).bind(null, variables),
      options
    );
export const GovernanceBasicsSettingsDocument = `
    query GovernanceBasicsSettings($input: GovernorInput!) {
  governor(input: $input) {
    name
    slug
  }
}
    `;
export const useGovernanceBasicsSettingsQuery = <
      TData = GovernanceBasicsSettingsQuery,
      TError = Error
    >(
      variables: GovernanceBasicsSettingsQueryVariables,
      options?: UseQueryOptions<GovernanceBasicsSettingsQuery, TError, TData>
    ) =>
    useQuery<GovernanceBasicsSettingsQuery, TError, TData>(
      ['GovernanceBasicsSettings', variables],
      useFetchData<GovernanceBasicsSettingsQuery, GovernanceBasicsSettingsQueryVariables>(GovernanceBasicsSettingsDocument).bind(null, variables),
      options
    );
export const OrganizationBySlugDocument = `
    query OrganizationBySlug($input: OrganizationInput!) {
  organization(input: $input) {
    id
    name
    slug
    chainIds
    governorIds
    tokenIds
    metadata {
      description
      icon
      socials {
        website
        discord
        telegram
        twitter
        discourse
        others {
          label
          value
        }
      }
      karmaName
    }
    features {
      name
      enabled
    }
  }
}
    `;
export const useOrganizationBySlugQuery = <
      TData = OrganizationBySlugQuery,
      TError = Error
    >(
      variables: OrganizationBySlugQueryVariables,
      options?: UseQueryOptions<OrganizationBySlugQuery, TError, TData>
    ) =>
    useQuery<OrganizationBySlugQuery, TError, TData>(
      ['OrganizationBySlug', variables],
      useFetchData<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>(OrganizationBySlugDocument).bind(null, variables),
      options
    );
export const OrganizationEditLogoDocument = `
    query OrganizationEditLogo($input: OrganizationInput!) {
  organization(input: $input) {
    id
    slug
    metadata {
      icon
    }
  }
}
    `;
export const useOrganizationEditLogoQuery = <
      TData = OrganizationEditLogoQuery,
      TError = Error
    >(
      variables: OrganizationEditLogoQueryVariables,
      options?: UseQueryOptions<OrganizationEditLogoQuery, TError, TData>
    ) =>
    useQuery<OrganizationEditLogoQuery, TError, TData>(
      ['OrganizationEditLogo', variables],
      useFetchData<OrganizationEditLogoQuery, OrganizationEditLogoQueryVariables>(OrganizationEditLogoDocument).bind(null, variables),
      options
    );
export const OrganizationHeaderDocument = `
    query OrganizationHeader($input: OrganizationInput!) {
  organization(input: $input) {
    id
    metadata {
      description
      icon
      socials {
        discord
        telegram
        twitter
        others {
          label
          value
        }
        website
      }
      karmaName
    }
    name
    slug
    governorIds
  }
}
    `;
export const useOrganizationHeaderQuery = <
      TData = OrganizationHeaderQuery,
      TError = Error
    >(
      variables: OrganizationHeaderQueryVariables,
      options?: UseQueryOptions<OrganizationHeaderQuery, TError, TData>
    ) =>
    useQuery<OrganizationHeaderQuery, TError, TData>(
      ['OrganizationHeader', variables],
      useFetchData<OrganizationHeaderQuery, OrganizationHeaderQueryVariables>(OrganizationHeaderDocument).bind(null, variables),
      options
    );
export const OrganizationHomeDelegatesMobileDocument = `
    query OrganizationHomeDelegatesMobile($input: OrganizationInput!) {
  organization(input: $input) {
    delegatesCount
    tokenOwnersCount
  }
}
    `;
export const useOrganizationHomeDelegatesMobileQuery = <
      TData = OrganizationHomeDelegatesMobileQuery,
      TError = Error
    >(
      variables: OrganizationHomeDelegatesMobileQueryVariables,
      options?: UseQueryOptions<OrganizationHomeDelegatesMobileQuery, TError, TData>
    ) =>
    useQuery<OrganizationHomeDelegatesMobileQuery, TError, TData>(
      ['OrganizationHomeDelegatesMobile', variables],
      useFetchData<OrganizationHomeDelegatesMobileQuery, OrganizationHomeDelegatesMobileQueryVariables>(OrganizationHomeDelegatesMobileDocument).bind(null, variables),
      options
    );
export const OrganizationHomeProposalsMobileDocument = `
    query OrganizationHomeProposalsMobile($input: OrganizationInput!) {
  organization(input: $input) {
    proposalsCount
    hasActiveProposals
  }
}
    `;
export const useOrganizationHomeProposalsMobileQuery = <
      TData = OrganizationHomeProposalsMobileQuery,
      TError = Error
    >(
      variables: OrganizationHomeProposalsMobileQueryVariables,
      options?: UseQueryOptions<OrganizationHomeProposalsMobileQuery, TError, TData>
    ) =>
    useQuery<OrganizationHomeProposalsMobileQuery, TError, TData>(
      ['OrganizationHomeProposalsMobile', variables],
      useFetchData<OrganizationHomeProposalsMobileQuery, OrganizationHomeProposalsMobileQueryVariables>(OrganizationHomeProposalsMobileDocument).bind(null, variables),
      options
    );
export const OrganizationLatestForumActivitiesDocument = `
    query OrganizationLatestForumActivities($input: OrganizationInput!) {
  latestForumActivity(input: $input) {
    topics {
      title
      slug
      replyCount
      lastPostedAt
      views
      pinned
      originalPosterName
    }
  }
}
    `;
export const useOrganizationLatestForumActivitiesQuery = <
      TData = OrganizationLatestForumActivitiesQuery,
      TError = Error
    >(
      variables: OrganizationLatestForumActivitiesQueryVariables,
      options?: UseQueryOptions<OrganizationLatestForumActivitiesQuery, TError, TData>
    ) =>
    useQuery<OrganizationLatestForumActivitiesQuery, TError, TData>(
      ['OrganizationLatestForumActivities', variables],
      useFetchData<OrganizationLatestForumActivitiesQuery, OrganizationLatestForumActivitiesQueryVariables>(OrganizationLatestForumActivitiesDocument).bind(null, variables),
      options
    );
export const OrganizationMyVotingPowerDocument = `
    query OrganizationMyVotingPower($delegateeInput: DelegationInput!, $delegateInput: DelegateInput!, $tokenBalancesInput: TokenBalancesInput!) {
  delegatee(input: $delegateeInput) {
    delegate {
      name
      address
      ens
      picture
    }
    votes
    token {
      id
      name
      symbol
      decimals
    }
  }
  delegate(input: $delegateInput) {
    delegatorsCount
    votesCount
    token {
      id
      name
      symbol
      decimals
    }
  }
  tokenBalances(input: $tokenBalancesInput) {
    token {
      id
      name
      symbol
      decimals
    }
    balance
  }
}
    `;
export const useOrganizationMyVotingPowerQuery = <
      TData = OrganizationMyVotingPowerQuery,
      TError = Error
    >(
      variables: OrganizationMyVotingPowerQueryVariables,
      options?: UseQueryOptions<OrganizationMyVotingPowerQuery, TError, TData>
    ) =>
    useQuery<OrganizationMyVotingPowerQuery, TError, TData>(
      ['OrganizationMyVotingPower', variables],
      useFetchData<OrganizationMyVotingPowerQuery, OrganizationMyVotingPowerQueryVariables>(OrganizationMyVotingPowerDocument).bind(null, variables),
      options
    );
export const OrganizationMyVotingPowerMultiChainDocument = `
    query OrganizationMyVotingPowerMultiChain($input: DelegatesInput!) {
  delegates(input: $input) {
    nodes {
      ... on Delegate {
        id
        votesCount
        delegatorsCount
        token {
          symbol
          decimals
        }
      }
    }
  }
}
    `;
export const useOrganizationMyVotingPowerMultiChainQuery = <
      TData = OrganizationMyVotingPowerMultiChainQuery,
      TError = Error
    >(
      variables: OrganizationMyVotingPowerMultiChainQueryVariables,
      options?: UseQueryOptions<OrganizationMyVotingPowerMultiChainQuery, TError, TData>
    ) =>
    useQuery<OrganizationMyVotingPowerMultiChainQuery, TError, TData>(
      ['OrganizationMyVotingPowerMultiChain', variables],
      useFetchData<OrganizationMyVotingPowerMultiChainQuery, OrganizationMyVotingPowerMultiChainQueryVariables>(OrganizationMyVotingPowerMultiChainDocument).bind(null, variables),
      options
    );
export const OrganizationRisingDelegatesDocument = `
    query OrganizationRisingDelegates($input: GovernorInput!) {
  governor(input: $input) {
    token {
      symbol
      decimals
    }
  }
}
    `;
export const useOrganizationRisingDelegatesQuery = <
      TData = OrganizationRisingDelegatesQuery,
      TError = Error
    >(
      variables: OrganizationRisingDelegatesQueryVariables,
      options?: UseQueryOptions<OrganizationRisingDelegatesQuery, TError, TData>
    ) =>
    useQuery<OrganizationRisingDelegatesQuery, TError, TData>(
      ['OrganizationRisingDelegates', variables],
      useFetchData<OrganizationRisingDelegatesQuery, OrganizationRisingDelegatesQueryVariables>(OrganizationRisingDelegatesDocument).bind(null, variables),
      options
    );
export const OrganizationSafeListDocument = `
    query OrganizationSafeList($input: GnosisSafesInput) {
  gnosisSafesV2(input: $input) {
    id
    nonce
    name
    threshold
    owners {
      id
      address
      name
      bio
      picture
    }
    version
    balance {
      totalUSDValue
      tokens {
        name
        symbol
        address
        amount
        fiat
        decimals
        logoURI
      }
    }
  }
}
    `;
export const useOrganizationSafeListQuery = <
      TData = OrganizationSafeListQuery,
      TError = Error
    >(
      variables?: OrganizationSafeListQueryVariables,
      options?: UseQueryOptions<OrganizationSafeListQuery, TError, TData>
    ) =>
    useQuery<OrganizationSafeListQuery, TError, TData>(
      variables === undefined ? ['OrganizationSafeList'] : ['OrganizationSafeList', variables],
      useFetchData<OrganizationSafeListQuery, OrganizationSafeListQueryVariables>(OrganizationSafeListDocument).bind(null, variables),
      options
    );
export const OrganizationSlugToIdDocument = `
    query OrganizationSlugToId($slug: String!) {
  organizationSlugToId(slug: $slug)
}
    `;
export const useOrganizationSlugToIdQuery = <
      TData = OrganizationSlugToIdQuery,
      TError = Error
    >(
      variables: OrganizationSlugToIdQueryVariables,
      options?: UseQueryOptions<OrganizationSlugToIdQuery, TError, TData>
    ) =>
    useQuery<OrganizationSlugToIdQuery, TError, TData>(
      ['OrganizationSlugToId', variables],
      useFetchData<OrganizationSlugToIdQuery, OrganizationSlugToIdQueryVariables>(OrganizationSlugToIdDocument).bind(null, variables),
      options
    );
export const ValidateNewGovernorDocument = `
    query ValidateNewGovernor($input: ValidateNewGovernorInput!) {
  validateNewGovernor(input: $input) {
    type
    startBlock
    tokenId
    tokenStartBlock
  }
}
    `;
export const useValidateNewGovernorQuery = <
      TData = ValidateNewGovernorQuery,
      TError = Error
    >(
      variables: ValidateNewGovernorQueryVariables,
      options?: UseQueryOptions<ValidateNewGovernorQuery, TError, TData>
    ) =>
    useQuery<ValidateNewGovernorQuery, TError, TData>(
      ['ValidateNewGovernor', variables],
      useFetchData<ValidateNewGovernorQuery, ValidateNewGovernorQueryVariables>(ValidateNewGovernorDocument).bind(null, variables),
      options
    );
export const CreateDaoDocument = `
    mutation CreateDAO($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    slug
  }
}
    `;
export const useCreateDaoMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDaoMutation, TError, CreateDaoMutationVariables, TContext>) =>
    useMutation<CreateDaoMutation, TError, CreateDaoMutationVariables, TContext>(
      ['CreateDAO'],
      useFetchData<CreateDaoMutation, CreateDaoMutationVariables>(CreateDaoDocument),
      options
    );
export const JoinOrganizationDocument = `
    mutation JoinOrganization($input: JoinOrganizationInput!) {
  joinOrganization(input: $input)
}
    `;
export const useJoinOrganizationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<JoinOrganizationMutation, TError, JoinOrganizationMutationVariables, TContext>) =>
    useMutation<JoinOrganizationMutation, TError, JoinOrganizationMutationVariables, TContext>(
      ['JoinOrganization'],
      useFetchData<JoinOrganizationMutation, JoinOrganizationMutationVariables>(JoinOrganizationDocument),
      options
    );
export const OrganizationDelegatesSummaryDocument = `
    query OrganizationDelegatesSummary($input: OrganizationInput!) {
  organization(input: $input) {
    delegatesVotesCount
    tokenIds
  }
}
    `;
export const useOrganizationDelegatesSummaryQuery = <
      TData = OrganizationDelegatesSummaryQuery,
      TError = Error
    >(
      variables: OrganizationDelegatesSummaryQueryVariables,
      options?: UseQueryOptions<OrganizationDelegatesSummaryQuery, TError, TData>
    ) =>
    useQuery<OrganizationDelegatesSummaryQuery, TError, TData>(
      ['OrganizationDelegatesSummary', variables],
      useFetchData<OrganizationDelegatesSummaryQuery, OrganizationDelegatesSummaryQueryVariables>(OrganizationDelegatesSummaryDocument).bind(null, variables),
      options
    );
export const RemoveSuperAdminDocument = `
    mutation RemoveSuperAdmin($input: RemoveSuperAdminInput!) {
  removeSuperAdmin(input: $input)
}
    `;
export const useRemoveSuperAdminMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveSuperAdminMutation, TError, RemoveSuperAdminMutationVariables, TContext>) =>
    useMutation<RemoveSuperAdminMutation, TError, RemoveSuperAdminMutationVariables, TContext>(
      ['RemoveSuperAdmin'],
      useFetchData<RemoveSuperAdminMutation, RemoveSuperAdminMutationVariables>(RemoveSuperAdminDocument),
      options
    );
export const TokenDocument = `
    query Token($input: TokenInput!) {
  token(input: $input) {
    id
    type
    name
    symbol
    supply
    decimals
  }
}
    `;
export const useTokenQuery = <
      TData = TokenQuery,
      TError = Error
    >(
      variables: TokenQueryVariables,
      options?: UseQueryOptions<TokenQuery, TError, TData>
    ) =>
    useQuery<TokenQuery, TError, TData>(
      ['Token', variables],
      useFetchData<TokenQuery, TokenQueryVariables>(TokenDocument).bind(null, variables),
      options
    );
export const UnlinkGnosisSafeDocument = `
    mutation UnlinkGnosisSafe($id: AccountID!) {
  unlinkGnosisSafe(id: $id)
}
    `;
export const useUnlinkGnosisSafeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UnlinkGnosisSafeMutation, TError, UnlinkGnosisSafeMutationVariables, TContext>) =>
    useMutation<UnlinkGnosisSafeMutation, TError, UnlinkGnosisSafeMutationVariables, TContext>(
      ['UnlinkGnosisSafe'],
      useFetchData<UnlinkGnosisSafeMutation, UnlinkGnosisSafeMutationVariables>(UnlinkGnosisSafeDocument),
      options
    );
export const UpdateOrganizationDocument = `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    name
    id
    slug
  }
}
    `;
export const useUpdateOrganizationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>) =>
    useMutation<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>(
      ['UpdateOrganization'],
      useFetchData<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument),
      options
    );
export const UpdateOrganizationAdminsDocument = `
    mutation UpdateOrganizationAdmins($input: OrganizationAdminsInput!) {
  updateOrganizationAdmins(input: $input)
}
    `;
export const useUpdateOrganizationAdminsMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganizationAdminsMutation, TError, UpdateOrganizationAdminsMutationVariables, TContext>) =>
    useMutation<UpdateOrganizationAdminsMutation, TError, UpdateOrganizationAdminsMutationVariables, TContext>(
      ['UpdateOrganizationAdmins'],
      useFetchData<UpdateOrganizationAdminsMutation, UpdateOrganizationAdminsMutationVariables>(UpdateOrganizationAdminsDocument),
      options
    );
export const UpdateOrganizationPasswordDocument = `
    mutation UpdateOrganizationPassword($input: OrganizationPasswordInput!) {
  updateOrganizationPassword(input: $input)
}
    `;
export const useUpdateOrganizationPasswordMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganizationPasswordMutation, TError, UpdateOrganizationPasswordMutationVariables, TContext>) =>
    useMutation<UpdateOrganizationPasswordMutation, TError, UpdateOrganizationPasswordMutationVariables, TContext>(
      ['UpdateOrganizationPassword'],
      useFetchData<UpdateOrganizationPasswordMutation, UpdateOrganizationPasswordMutationVariables>(UpdateOrganizationPasswordDocument),
      options
    );
export const UpdateOrganizationLogoDocument = `
    mutation UpdateOrganizationLogo($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
  }
}
    `;
export const useUpdateOrganizationLogoMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganizationLogoMutation, TError, UpdateOrganizationLogoMutationVariables, TContext>) =>
    useMutation<UpdateOrganizationLogoMutation, TError, UpdateOrganizationLogoMutationVariables, TContext>(
      ['UpdateOrganizationLogo'],
      useFetchData<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>(UpdateOrganizationLogoDocument),
      options
    );
export const OrganizationMetadataDocument = `
    query OrganizationMetadata($input: OrganizationInput!) {
  organization(input: $input) {
    id
    name
    metadata {
      description
      icon
    }
    slug
  }
}
    `;
export const useOrganizationMetadataQuery = <
      TData = OrganizationMetadataQuery,
      TError = Error
    >(
      variables: OrganizationMetadataQueryVariables,
      options?: UseQueryOptions<OrganizationMetadataQuery, TError, TData>
    ) =>
    useQuery<OrganizationMetadataQuery, TError, TData>(
      ['OrganizationMetadata', variables],
      useFetchData<OrganizationMetadataQuery, OrganizationMetadataQueryVariables>(OrganizationMetadataDocument).bind(null, variables),
      options
    );
export const OrganizationContextDocument = `
    query OrganizationContext($input: OrganizationInput!) {
  organization(input: $input) {
    id
    name
    slug
    myRole
    chainIds
    features {
      name
      enabled
    }
  }
}
    `;
export const useOrganizationContextQuery = <
      TData = OrganizationContextQuery,
      TError = Error
    >(
      variables: OrganizationContextQueryVariables,
      options?: UseQueryOptions<OrganizationContextQuery, TError, TData>
    ) =>
    useQuery<OrganizationContextQuery, TError, TData>(
      ['OrganizationContext', variables],
      useFetchData<OrganizationContextQuery, OrganizationContextQueryVariables>(OrganizationContextDocument).bind(null, variables),
      options
    );
export const ExploreOrgsDocument = `
    query ExploreOrgs($input: OrganizationsInput!) {
  organizations(input: $input) {
    nodes {
      ... on Organization {
        id
        slug
        name
        chainIds
        proposalsCount
        hasActiveProposals
        tokenOwnersCount
        delegatesCount
        governorIds
        metadata {
          icon
        }
        tokenIds
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useExploreOrgsQuery = <
      TData = ExploreOrgsQuery,
      TError = Error
    >(
      variables: ExploreOrgsQueryVariables,
      options?: UseQueryOptions<ExploreOrgsQuery, TError, TData>
    ) =>
    useQuery<ExploreOrgsQuery, TError, TData>(
      ['ExploreOrgs', variables],
      useFetchData<ExploreOrgsQuery, ExploreOrgsQueryVariables>(ExploreOrgsDocument).bind(null, variables),
      options
    );
export const ExploreSearchOrgsDocument = `
    query ExploreSearchOrgs($input: SearchOrganizationInput!) {
  searchOrganization(input: $input) {
    id
    slug
    name
    chainIds
    proposalsCount
    hasActiveProposals
    tokenOwnersCount
    delegatesCount
    governorIds
    tokenIds
    metadata {
      icon
    }
    governorIds
  }
}
    `;
export const useExploreSearchOrgsQuery = <
      TData = ExploreSearchOrgsQuery,
      TError = Error
    >(
      variables: ExploreSearchOrgsQueryVariables,
      options?: UseQueryOptions<ExploreSearchOrgsQuery, TError, TData>
    ) =>
    useQuery<ExploreSearchOrgsQuery, TError, TData>(
      ['ExploreSearchOrgs', variables],
      useFetchData<ExploreSearchOrgsQuery, ExploreSearchOrgsQueryVariables>(ExploreSearchOrgsDocument).bind(null, variables),
      options
    );
export const ProposalAccountVoteDocument = `
    query ProposalAccountVote($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        type
      }
    }
  }
}
    `;
export const useProposalAccountVoteQuery = <
      TData = ProposalAccountVoteQuery,
      TError = Error
    >(
      variables: ProposalAccountVoteQueryVariables,
      options?: UseQueryOptions<ProposalAccountVoteQuery, TError, TData>
    ) =>
    useQuery<ProposalAccountVoteQuery, TError, TData>(
      ['ProposalAccountVote', variables],
      useFetchData<ProposalAccountVoteQuery, ProposalAccountVoteQueryVariables>(ProposalAccountVoteDocument).bind(null, variables),
      options
    );
export const ProposalBubbleChartDocument = `
    query ProposalBubbleChart($input: ProposalInput!, $votesInput: VotesInput!) {
  proposal(input: $input) {
    createdAt
    status
    governor {
      id
      delegatesVotesCount
      token {
        supply
        decimals
        supply
      }
    }
    voteStats {
      votersCount
      votesCount
      type
      percent
    }
  }
  votes(input: $votesInput) {
    nodes {
      ... on Vote {
        voter {
          name
          address
        }
        amount
        type
      }
    }
  }
}
    `;
export const useProposalBubbleChartQuery = <
      TData = ProposalBubbleChartQuery,
      TError = Error
    >(
      variables: ProposalBubbleChartQueryVariables,
      options?: UseQueryOptions<ProposalBubbleChartQuery, TError, TData>
    ) =>
    useQuery<ProposalBubbleChartQuery, TError, TData>(
      ['ProposalBubbleChart', variables],
      useFetchData<ProposalBubbleChartQuery, ProposalBubbleChartQueryVariables>(ProposalBubbleChartDocument).bind(null, variables),
      options
    );
export const ProposalDetailsDocument = `
    query ProposalDetails($input: ProposalInput!, $votesInput: VotesInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
      discourseURL
      snapshotURL
    }
    executableCalls {
      value
      target
      calldata
      signature
      type
      offchaindata {
        ... on ExecutableCallSwap {
          amountIn
          fee
          buyToken {
            data {
              price
              decimals
              name
              symbol
            }
          }
          sellToken {
            data {
              price
              decimals
              name
              symbol
            }
          }
          to
          quote {
            buyAmount
            feeAmount
          }
          order {
            id
            status
            buyAmount
            address
          }
          priceChecker {
            tokenPath
            feePath
            uniPoolPath
            slippage
          }
        }
        ... on ExecutableCallRewards {
          contributorFee
          tallyFee
          recipients
        }
      }
    }
    governor {
      id
      chainId
      slug
      organization {
        metadata {
          description
        }
      }
      contracts {
        governor {
          address
          type
        }
      }
      timelockId
    }
  }
  votes(input: $votesInput) {
    nodes {
      ... on Vote {
        isBridged
        voter {
          name
          picture
          address
          twitter
        }
        reason
        type
        block {
          timestamp
        }
      }
    }
  }
}
    `;
export const useProposalDetailsQuery = <
      TData = ProposalDetailsQuery,
      TError = Error
    >(
      variables: ProposalDetailsQueryVariables,
      options?: UseQueryOptions<ProposalDetailsQuery, TError, TData>
    ) =>
    useQuery<ProposalDetailsQuery, TError, TData>(
      ['ProposalDetails', variables],
      useFetchData<ProposalDetailsQuery, ProposalDetailsQueryVariables>(ProposalDetailsDocument).bind(null, variables),
      options
    );
export const ProposalHeaderDocument = `
    query ProposalHeader($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    createdAt
    governor {
      id
      name
      quorum
      timelockId
      token {
        decimals
      }
      type
    }
    status
    voteStats {
      votesCount
      type
      votersCount
    }
    proposer {
      name
      picture
      address
    }
    creator {
      name
      picture
      address
    }
    events {
      type
    }
  }
}
    `;
export const useProposalHeaderQuery = <
      TData = ProposalHeaderQuery,
      TError = Error
    >(
      variables: ProposalHeaderQueryVariables,
      options?: UseQueryOptions<ProposalHeaderQuery, TError, TData>
    ) =>
    useQuery<ProposalHeaderQuery, TError, TData>(
      ['ProposalHeader', variables],
      useFetchData<ProposalHeaderQuery, ProposalHeaderQueryVariables>(ProposalHeaderDocument).bind(null, variables),
      options
    );
export const ProposalMetadataDocument = `
    query ProposalMetadata($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      title
      description
      snapshotURL
      discourseURL
    }
    status
    events {
      block {
        number
      }
      type
    }
    governor {
      id
      organization {
        name
        metadata {
          icon
        }
      }
    }
  }
}
    `;
export const useProposalMetadataQuery = <
      TData = ProposalMetadataQuery,
      TError = Error
    >(
      variables: ProposalMetadataQueryVariables,
      options?: UseQueryOptions<ProposalMetadataQuery, TError, TData>
    ) =>
    useQuery<ProposalMetadataQuery, TError, TData>(
      ['ProposalMetadata', variables],
      useFetchData<ProposalMetadataQuery, ProposalMetadataQueryVariables>(ProposalMetadataDocument).bind(null, variables),
      options
    );
export const ProposalMobileButtonsDocument = `
    query ProposalMobileButtons($input: ProposalInput!) {
  proposal(input: $input) {
    id
    metadata {
      description
    }
    createdAt
    governor {
      id
    }
    status
  }
}
    `;
export const useProposalMobileButtonsQuery = <
      TData = ProposalMobileButtonsQuery,
      TError = Error
    >(
      variables: ProposalMobileButtonsQueryVariables,
      options?: UseQueryOptions<ProposalMobileButtonsQuery, TError, TData>
    ) =>
    useQuery<ProposalMobileButtonsQuery, TError, TData>(
      ['ProposalMobileButtons', variables],
      useFetchData<ProposalMobileButtonsQuery, ProposalMobileButtonsQueryVariables>(ProposalMobileButtonsDocument).bind(null, variables),
      options
    );
export const ProposalPendingVotesDocument = `
    query ProposalPendingVotes($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        isBridged
      }
    }
  }
}
    `;
export const useProposalPendingVotesQuery = <
      TData = ProposalPendingVotesQuery,
      TError = Error
    >(
      variables: ProposalPendingVotesQueryVariables,
      options?: UseQueryOptions<ProposalPendingVotesQuery, TError, TData>
    ) =>
    useQuery<ProposalPendingVotesQuery, TError, TData>(
      ['ProposalPendingVotes', variables],
      useFetchData<ProposalPendingVotesQuery, ProposalPendingVotesQueryVariables>(ProposalPendingVotesDocument).bind(null, variables),
      options
    );
export const ProposalProgressBarsDocument = `
    query ProposalProgressBars($input: ProposalInput!) {
  proposal(input: $input) {
    voteStats {
      votersCount
      type
      percent
    }
    governor {
      token {
        decimals
      }
    }
  }
}
    `;
export const useProposalProgressBarsQuery = <
      TData = ProposalProgressBarsQuery,
      TError = Error
    >(
      variables: ProposalProgressBarsQueryVariables,
      options?: UseQueryOptions<ProposalProgressBarsQuery, TError, TData>
    ) =>
    useQuery<ProposalProgressBarsQuery, TError, TData>(
      ['ProposalProgressBars', variables],
      useFetchData<ProposalProgressBarsQuery, ProposalProgressBarsQueryVariables>(ProposalProgressBarsDocument).bind(null, variables),
      options
    );
export const ProposalStatusHistoryDocument = `
    query ProposalStatusHistory($input: ProposalInput!) {
  proposal(input: $input) {
    end {
      ... on Block {
        id
        timestamp
      }
      ... on BlocklessTimestamp {
        timestamp
      }
    }
    start {
      ... on Block {
        id
        timestamp
      }
      ... on BlocklessTimestamp {
        timestamp
      }
    }
    creator {
      name
      address
      picture
    }
    governor {
      timelockId
      quorum
      token {
        decimals
      }
      type
    }
    voteStats {
      votesCount
      type
      votersCount
    }
    proposer {
      name
      address
      picture
    }
    createdAt
    quorum
    block {
      id
    }
    events {
      type
      txHash
      chainId
      createdAt
      block {
        id
        timestamp
      }
    }
    status
  }
}
    `;
export const useProposalStatusHistoryQuery = <
      TData = ProposalStatusHistoryQuery,
      TError = Error
    >(
      variables: ProposalStatusHistoryQueryVariables,
      options?: UseQueryOptions<ProposalStatusHistoryQuery, TError, TData>
    ) =>
    useQuery<ProposalStatusHistoryQuery, TError, TData>(
      ['ProposalStatusHistory', variables],
      useFetchData<ProposalStatusHistoryQuery, ProposalStatusHistoryQueryVariables>(ProposalStatusHistoryDocument).bind(null, variables),
      options
    );
export const ProposalTimelineChartDocument = `
    query ProposalTimelineChart($input: ProposalInput!, $votesInput: VotesInput!) {
  proposal(input: $input) {
    createdAt
    organization {
      slug
    }
    governor {
      quorum
      token {
        decimals
      }
    }
    quorum
    voteStats {
      votesCount
      votersCount
      type
      percent
    }
  }
  votes(input: $votesInput) {
    nodes {
      ... on Vote {
        voter {
          name
          picture
          address
        }
        amount
        type
        id
        block {
          id
          timestamp
        }
      }
    }
    pageInfo {
      count
      lastCursor
    }
  }
}
    `;
export const useProposalTimelineChartQuery = <
      TData = ProposalTimelineChartQuery,
      TError = Error
    >(
      variables: ProposalTimelineChartQueryVariables,
      options?: UseQueryOptions<ProposalTimelineChartQuery, TError, TData>
    ) =>
    useQuery<ProposalTimelineChartQuery, TError, TData>(
      ['ProposalTimelineChart', variables],
      useFetchData<ProposalTimelineChartQuery, ProposalTimelineChartQueryVariables>(ProposalTimelineChartDocument).bind(null, variables),
      options
    );
export const ProposalVoteModalDocument = `
    query ProposalVoteModal($input: ProposalInput!, $address: Address!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      title
      description
    }
    delegateVotesCount(address: $address)
    governor {
      id
      token {
        decimals
        supply
      }
      contracts {
        governor {
          type
          address
        }
      }
      organization {
        id
        name
        metadata {
          icon
        }
        tokenIds
      }
    }
  }
  accountV2(id: $address) {
    address
    name
    picture
    twitter
  }
}
    `;
export const useProposalVoteModalQuery = <
      TData = ProposalVoteModalQuery,
      TError = Error
    >(
      variables: ProposalVoteModalQueryVariables,
      options?: UseQueryOptions<ProposalVoteModalQuery, TError, TData>
    ) =>
    useQuery<ProposalVoteModalQuery, TError, TData>(
      ['ProposalVoteModal', variables],
      useFetchData<ProposalVoteModalQuery, ProposalVoteModalQueryVariables>(ProposalVoteModalDocument).bind(null, variables),
      options
    );
export const ProposalVotingPowerDocument = `
    query ProposalVotingPower($input: DelegationsInput!) {
  delegatees(input: $input) {
    nodes {
      ... on Delegation {
        id
        votes
        chainId
      }
    }
  }
}
    `;
export const useProposalVotingPowerQuery = <
      TData = ProposalVotingPowerQuery,
      TError = Error
    >(
      variables: ProposalVotingPowerQueryVariables,
      options?: UseQueryOptions<ProposalVotingPowerQuery, TError, TData>
    ) =>
    useQuery<ProposalVotingPowerQuery, TError, TData>(
      ['ProposalVotingPower', variables],
      useFetchData<ProposalVotingPowerQuery, ProposalVotingPowerQueryVariables>(ProposalVotingPowerDocument).bind(null, variables),
      options
    );
export const ProposalCastVotesDocument = `
    query ProposalCastVotes($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        id
        chainId
        txHash
        type
      }
    }
  }
}
    `;
export const useProposalCastVotesQuery = <
      TData = ProposalCastVotesQuery,
      TError = Error
    >(
      variables: ProposalCastVotesQueryVariables,
      options?: UseQueryOptions<ProposalCastVotesQuery, TError, TData>
    ) =>
    useQuery<ProposalCastVotesQuery, TError, TData>(
      ['ProposalCastVotes', variables],
      useFetchData<ProposalCastVotesQuery, ProposalCastVotesQueryVariables>(ProposalCastVotesDocument).bind(null, variables),
      options
    );
export const ProposalParticipationsDocument = `
    query ProposalParticipations($input: ProposalInput!, $address: Address!) {
  proposal(input: $input) {
    id
    chainId
    participationType(address: $address)
  }
}
    `;
export const useProposalParticipationsQuery = <
      TData = ProposalParticipationsQuery,
      TError = Error
    >(
      variables: ProposalParticipationsQueryVariables,
      options?: UseQueryOptions<ProposalParticipationsQuery, TError, TData>
    ) =>
    useQuery<ProposalParticipationsQuery, TError, TData>(
      ['ProposalParticipations', variables],
      useFetchData<ProposalParticipationsQuery, ProposalParticipationsQueryVariables>(ProposalParticipationsDocument).bind(null, variables),
      options
    );
export const ProposalVotesCastDocument = `
    query ProposalVotesCast($input: ProposalInput!) {
  proposal(input: $input) {
    onchainId
    status
    quorum
    voteStats {
      votesCount
      votersCount
      type
      percent
    }
    governor {
      quorum
      token {
        decimals
      }
      type
      id
    }
  }
}
    `;
export const useProposalVotesCastQuery = <
      TData = ProposalVotesCastQuery,
      TError = Error
    >(
      variables: ProposalVotesCastQueryVariables,
      options?: UseQueryOptions<ProposalVotesCastQuery, TError, TData>
    ) =>
    useQuery<ProposalVotesCastQuery, TError, TData>(
      ['ProposalVotesCast', variables],
      useFetchData<ProposalVotesCastQuery, ProposalVotesCastQueryVariables>(ProposalVotesCastDocument).bind(null, variables),
      options
    );
export const ProposalVotesCastListDocument = `
    query ProposalVotesCastList($forInput: VotesInput!, $againstInput: VotesInput!, $abstainInput: VotesInput!) {
  forVotes: votes(input: $forInput) {
    nodes {
      ... on Vote {
        isBridged
        voter {
          name
          picture
          address
          twitter
        }
        amount
        type
        chainId
      }
    }
  }
  againstVotes: votes(input: $againstInput) {
    nodes {
      ... on Vote {
        isBridged
        voter {
          name
          picture
          address
          twitter
        }
        amount
        type
        chainId
      }
    }
  }
  abstainVotes: votes(input: $abstainInput) {
    nodes {
      ... on Vote {
        isBridged
        voter {
          name
          picture
          address
          twitter
        }
        amount
        type
        chainId
      }
    }
  }
}
    `;
export const useProposalVotesCastListQuery = <
      TData = ProposalVotesCastListQuery,
      TError = Error
    >(
      variables: ProposalVotesCastListQueryVariables,
      options?: UseQueryOptions<ProposalVotesCastListQuery, TError, TData>
    ) =>
    useQuery<ProposalVotesCastListQuery, TError, TData>(
      ['ProposalVotesCastList', variables],
      useFetchData<ProposalVotesCastListQuery, ProposalVotesCastListQueryVariables>(ProposalVotesCastListDocument).bind(null, variables),
      options
    );
export const ProposalVoterVotesCastListDocument = `
    query ProposalVoterVotesCastList($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        isBridged
        voter {
          name
          picture
          address
          twitter
        }
        amount
        type
        chainId
      }
    }
  }
}
    `;
export const useProposalVoterVotesCastListQuery = <
      TData = ProposalVoterVotesCastListQuery,
      TError = Error
    >(
      variables: ProposalVoterVotesCastListQueryVariables,
      options?: UseQueryOptions<ProposalVoterVotesCastListQuery, TError, TData>
    ) =>
    useQuery<ProposalVoterVotesCastListQuery, TError, TData>(
      ['ProposalVoterVotesCastList', variables],
      useFetchData<ProposalVoterVotesCastListQuery, ProposalVoterVotesCastListQueryVariables>(ProposalVoterVotesCastListDocument).bind(null, variables),
      options
    );
export const VoteListHeaderDocument = `
    query VoteListHeader($input: ProposalInput!) {
  proposal(input: $input) {
    id
    metadata {
      description
    }
    governor {
      organization {
        name
        metadata {
          icon
        }
      }
    }
  }
}
    `;
export const useVoteListHeaderQuery = <
      TData = VoteListHeaderQuery,
      TError = Error
    >(
      variables: VoteListHeaderQueryVariables,
      options?: UseQueryOptions<VoteListHeaderQuery, TError, TData>
    ) =>
    useQuery<VoteListHeaderQuery, TError, TData>(
      ['VoteListHeader', variables],
      useFetchData<VoteListHeaderQuery, VoteListHeaderQueryVariables>(VoteListHeaderDocument).bind(null, variables),
      options
    );
export const VoteListTableDocument = `
    query VoteListTable($input: VotesInput!) {
  votes(input: $input) {
    nodes {
      ... on Vote {
        isBridged
        amount
        type
        voter {
          name
          picture
          address
          twitter
        }
        chainId
      }
    }
    pageInfo {
      firstCursor
      lastCursor
    }
  }
}
    `;
export const useVoteListTableQuery = <
      TData = VoteListTableQuery,
      TError = Error
    >(
      variables: VoteListTableQueryVariables,
      options?: UseQueryOptions<VoteListTableQuery, TError, TData>
    ) =>
    useQuery<VoteListTableQuery, TError, TData>(
      ['VoteListTable', variables],
      useFetchData<VoteListTableQuery, VoteListTableQueryVariables>(VoteListTableDocument).bind(null, variables),
      options
    );
export const VoteListTableGovernanceDocument = `
    query VoteListTableGovernance($input: GovernorInput!) {
  governor(input: $input) {
    token {
      decimals
      supply
    }
  }
}
    `;
export const useVoteListTableGovernanceQuery = <
      TData = VoteListTableGovernanceQuery,
      TError = Error
    >(
      variables: VoteListTableGovernanceQueryVariables,
      options?: UseQueryOptions<VoteListTableGovernanceQuery, TError, TData>
    ) =>
    useQuery<VoteListTableGovernanceQuery, TError, TData>(
      ['VoteListTableGovernance', variables],
      useFetchData<VoteListTableGovernanceQuery, VoteListTableGovernanceQueryVariables>(VoteListTableGovernanceDocument).bind(null, variables),
      options
    );
export const ProposalActionAttemptDocument = `
    query ProposalActionAttempt($input: ProposalActionAttemptInput!) {
  proposalActionAttempt(input: $input) {
    txHash
    chainId
    type
    proposal {
      createdAt
    }
  }
}
    `;
export const useProposalActionAttemptQuery = <
      TData = ProposalActionAttemptQuery,
      TError = Error
    >(
      variables: ProposalActionAttemptQueryVariables,
      options?: UseQueryOptions<ProposalActionAttemptQuery, TError, TData>
    ) =>
    useQuery<ProposalActionAttemptQuery, TError, TData>(
      ['ProposalActionAttempt', variables],
      useFetchData<ProposalActionAttemptQuery, ProposalActionAttemptQueryVariables>(ProposalActionAttemptDocument).bind(null, variables),
      options
    );
export const ProposalActiveCtaDocument = `
    query ProposalActiveCTA($input: ProposalInput!, $votersInput: VotesInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    executableCalls {
      calldata
      target
      value
    }
    status
    events {
      type
      txHash
    }
    governor {
      id
      chainId
      timelockId
      contracts {
        governor {
          type
          address
        }
      }
    }
  }
  votes(input: $votersInput) {
    nodes {
      ... on Vote {
        type
        chainId
      }
    }
  }
}
    `;
export const useProposalActiveCtaQuery = <
      TData = ProposalActiveCtaQuery,
      TError = Error
    >(
      variables: ProposalActiveCtaQueryVariables,
      options?: UseQueryOptions<ProposalActiveCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalActiveCtaQuery, TError, TData>(
      ['ProposalActiveCTA', variables],
      useFetchData<ProposalActiveCtaQuery, ProposalActiveCtaQueryVariables>(ProposalActiveCtaDocument).bind(null, variables),
      options
    );
export const ProposalVoteAttemptDocument = `
    query ProposalVoteAttempt($input: VoteAttemptInput!) {
  voteAttempt(input: $input) {
    txHash
    chainId
    createdAt
    type
  }
}
    `;
export const useProposalVoteAttemptQuery = <
      TData = ProposalVoteAttemptQuery,
      TError = Error
    >(
      variables: ProposalVoteAttemptQueryVariables,
      options?: UseQueryOptions<ProposalVoteAttemptQuery, TError, TData>
    ) =>
    useQuery<ProposalVoteAttemptQuery, TError, TData>(
      ['ProposalVoteAttempt', variables],
      useFetchData<ProposalVoteAttemptQuery, ProposalVoteAttemptQueryVariables>(ProposalVoteAttemptDocument).bind(null, variables),
      options
    );
export const ProposalDefeatedCtaDocument = `
    query ProposalDefeatedCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    voteStats {
      votesCount
      votersCount
      type
    }
    quorum
    governor {
      id
      quorum
      token {
        type
        supply
        decimals
      }
      type
    }
  }
}
    `;
export const useProposalDefeatedCtaQuery = <
      TData = ProposalDefeatedCtaQuery,
      TError = Error
    >(
      variables: ProposalDefeatedCtaQueryVariables,
      options?: UseQueryOptions<ProposalDefeatedCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalDefeatedCtaQuery, TError, TData>(
      ['ProposalDefeatedCTA', variables],
      useFetchData<ProposalDefeatedCtaQuery, ProposalDefeatedCtaQueryVariables>(ProposalDefeatedCtaDocument).bind(null, variables),
      options
    );
export const ProposalExecutedCtaDocument = `
    query ProposalExecutedCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    events {
      type
      txHash
    }
    status
    governor {
      id
      chainId
    }
  }
}
    `;
export const useProposalExecutedCtaQuery = <
      TData = ProposalExecutedCtaQuery,
      TError = Error
    >(
      variables: ProposalExecutedCtaQueryVariables,
      options?: UseQueryOptions<ProposalExecutedCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalExecutedCtaQuery, TError, TData>(
      ['ProposalExecutedCTA', variables],
      useFetchData<ProposalExecutedCtaQuery, ProposalExecutedCtaQueryVariables>(ProposalExecutedCtaDocument).bind(null, variables),
      options
    );
export const ProposalPendingCtaDocument = `
    query ProposalPendingCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    start {
      ... on Block {
        timestamp
      }
      ... on BlocklessTimestamp {
        timestamp
      }
    }
    executableCalls {
      calldata
      target
      value
    }
    status
    events {
      type
      txHash
    }
    governor {
      id
      chainId
    }
    governor {
      id
      chainId
      timelockId
      contracts {
        governor {
          type
          address
        }
      }
    }
  }
}
    `;
export const useProposalPendingCtaQuery = <
      TData = ProposalPendingCtaQuery,
      TError = Error
    >(
      variables: ProposalPendingCtaQueryVariables,
      options?: UseQueryOptions<ProposalPendingCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalPendingCtaQuery, TError, TData>(
      ['ProposalPendingCTA', variables],
      useFetchData<ProposalPendingCtaQuery, ProposalPendingCtaQueryVariables>(ProposalPendingCtaDocument).bind(null, variables),
      options
    );
export const ProposalQueuedCtaDocument = `
    query ProposalQueuedCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    executableCalls {
      calldata
      target
      value
    }
    status
    events {
      type
      txHash
    }
    governor {
      id
      chainId
      timelockId
      contracts {
        governor {
          type
          address
        }
      }
    }
  }
}
    `;
export const useProposalQueuedCtaQuery = <
      TData = ProposalQueuedCtaQuery,
      TError = Error
    >(
      variables: ProposalQueuedCtaQueryVariables,
      options?: UseQueryOptions<ProposalQueuedCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalQueuedCtaQuery, TError, TData>(
      ['ProposalQueuedCTA', variables],
      useFetchData<ProposalQueuedCtaQuery, ProposalQueuedCtaQueryVariables>(ProposalQueuedCtaDocument).bind(null, variables),
      options
    );
export const ProposalSuccededCtaDocument = `
    query ProposalSuccededCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    onchainId
    metadata {
      description
    }
    executableCalls {
      calldata
      target
      value
    }
    status
    events {
      type
    }
    governor {
      id
      chainId
      timelockId
      contracts {
        governor {
          type
          address
        }
      }
    }
  }
}
    `;
export const useProposalSuccededCtaQuery = <
      TData = ProposalSuccededCtaQuery,
      TError = Error
    >(
      variables: ProposalSuccededCtaQueryVariables,
      options?: UseQueryOptions<ProposalSuccededCtaQuery, TError, TData>
    ) =>
    useQuery<ProposalSuccededCtaQuery, TError, TData>(
      ['ProposalSuccededCTA', variables],
      useFetchData<ProposalSuccededCtaQuery, ProposalSuccededCtaQueryVariables>(ProposalSuccededCtaDocument).bind(null, variables),
      options
    );
export const ProposalTransferTokensReceiptDocument = `
    query ProposalTransferTokensReceipt($accountIds: [AccountID!]) {
  accounts(ids: $accountIds) {
    name
    address
    picture
    twitter
  }
}
    `;
export const useProposalTransferTokensReceiptQuery = <
      TData = ProposalTransferTokensReceiptQuery,
      TError = Error
    >(
      variables?: ProposalTransferTokensReceiptQueryVariables,
      options?: UseQueryOptions<ProposalTransferTokensReceiptQuery, TError, TData>
    ) =>
    useQuery<ProposalTransferTokensReceiptQuery, TError, TData>(
      variables === undefined ? ['ProposalTransferTokensReceipt'] : ['ProposalTransferTokensReceipt', variables],
      useFetchData<ProposalTransferTokensReceiptQuery, ProposalTransferTokensReceiptQueryVariables>(ProposalTransferTokensReceiptDocument).bind(null, variables),
      options
    );
export const BlockMetadataDocument = `
    query BlockMetadata($chain: ChainID!, $blockNumber: Int!) {
  block(id: {chain: $chain, blockNumber: $blockNumber}) {
    id
    number
    timestamp
  }
}
    `;
export const useBlockMetadataQuery = <
      TData = BlockMetadataQuery,
      TError = Error
    >(
      variables: BlockMetadataQueryVariables,
      options?: UseQueryOptions<BlockMetadataQuery, TError, TData>
    ) =>
    useQuery<BlockMetadataQuery, TError, TData>(
      ['BlockMetadata', variables],
      useFetchData<BlockMetadataQuery, BlockMetadataQueryVariables>(BlockMetadataDocument).bind(null, variables),
      options
    );
export const CreateProposalActionAttemptDocument = `
    mutation CreateProposalActionAttempt($input: CreateProposalActionAttemptInput!) {
  createProposalActionAttempt(input: $input)
}
    `;
export const useCreateProposalActionAttemptMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProposalActionAttemptMutation, TError, CreateProposalActionAttemptMutationVariables, TContext>) =>
    useMutation<CreateProposalActionAttemptMutation, TError, CreateProposalActionAttemptMutationVariables, TContext>(
      ['CreateProposalActionAttempt'],
      useFetchData<CreateProposalActionAttemptMutation, CreateProposalActionAttemptMutationVariables>(CreateProposalActionAttemptDocument),
      options
    );
export const ContractAbiDocument = `
    query ContractABI($id: AccountID!) {
  contractAbi(id: $id) {
    type
    name
  }
}
    `;
export const useContractAbiQuery = <
      TData = ContractAbiQuery,
      TError = Error
    >(
      variables: ContractAbiQueryVariables,
      options?: UseQueryOptions<ContractAbiQuery, TError, TData>
    ) =>
    useQuery<ContractAbiQuery, TError, TData>(
      ['ContractABI', variables],
      useFetchData<ContractAbiQuery, ContractAbiQueryVariables>(ContractAbiDocument).bind(null, variables),
      options
    );
export const CreateSafeDocument = `
    mutation CreateSafe($input: CreateSafeInput!) {
  createSafeV2(input: $input)
}
    `;
export const useCreateSafeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSafeMutation, TError, CreateSafeMutationVariables, TContext>) =>
    useMutation<CreateSafeMutation, TError, CreateSafeMutationVariables, TContext>(
      ['CreateSafe'],
      useFetchData<CreateSafeMutation, CreateSafeMutationVariables>(CreateSafeDocument),
      options
    );
export const SafeAssetsDocument = `
    query SafeAssets($id: AccountID!) {
  gnosisSafe(id: $id) {
    balance {
      tokens {
        name
        fiat
        logoURI
        symbol
        decimals
        address
        amount
      }
    }
  }
}
    `;
export const useSafeAssetsQuery = <
      TData = SafeAssetsQuery,
      TError = Error
    >(
      variables: SafeAssetsQueryVariables,
      options?: UseQueryOptions<SafeAssetsQuery, TError, TData>
    ) =>
    useQuery<SafeAssetsQuery, TError, TData>(
      ['SafeAssets', variables],
      useFetchData<SafeAssetsQuery, SafeAssetsQueryVariables>(SafeAssetsDocument).bind(null, variables),
      options
    );
export const SafeBalanceInformationDocument = `
    query SafeBalanceInformation($id: AccountID!) {
  gnosisSafe(id: $id) {
    id
    balance {
      totalUSDValue
    }
  }
}
    `;
export const useSafeBalanceInformationQuery = <
      TData = SafeBalanceInformationQuery,
      TError = Error
    >(
      variables: SafeBalanceInformationQueryVariables,
      options?: UseQueryOptions<SafeBalanceInformationQuery, TError, TData>
    ) =>
    useQuery<SafeBalanceInformationQuery, TError, TData>(
      ['SafeBalanceInformation', variables],
      useFetchData<SafeBalanceInformationQuery, SafeBalanceInformationQueryVariables>(SafeBalanceInformationDocument).bind(null, variables),
      options
    );
export const SafeHeaderDocument = `
    query SafeHeader($id: AccountID!) {
  account(id: $id) {
    address
    name
  }
  gnosisSafe(id: $id) {
    id
    name
  }
}
    `;
export const useSafeHeaderQuery = <
      TData = SafeHeaderQuery,
      TError = Error
    >(
      variables: SafeHeaderQueryVariables,
      options?: UseQueryOptions<SafeHeaderQuery, TError, TData>
    ) =>
    useQuery<SafeHeaderQuery, TError, TData>(
      ['SafeHeader', variables],
      useFetchData<SafeHeaderQuery, SafeHeaderQueryVariables>(SafeHeaderDocument).bind(null, variables),
      options
    );
export const SafeOwnersDocument = `
    query SafeOwners($id: AccountID!) {
  gnosisSafe(id: $id) {
    threshold
    owners {
      id
      address
      name
      ens
      twitter
      picture
    }
  }
}
    `;
export const useSafeOwnersQuery = <
      TData = SafeOwnersQuery,
      TError = Error
    >(
      variables: SafeOwnersQueryVariables,
      options?: UseQueryOptions<SafeOwnersQuery, TError, TData>
    ) =>
    useQuery<SafeOwnersQuery, TError, TData>(
      ['SafeOwners', variables],
      useFetchData<SafeOwnersQuery, SafeOwnersQueryVariables>(SafeOwnersDocument).bind(null, variables),
      options
    );
export const UpdateSafeDocument = `
    mutation UpdateSafe($id: AccountID!, $name: String!) {
  updateSafe(id: $id, name: $name)
}
    `;
export const useUpdateSafeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSafeMutation, TError, UpdateSafeMutationVariables, TContext>) =>
    useMutation<UpdateSafeMutation, TError, UpdateSafeMutationVariables, TContext>(
      ['UpdateSafe'],
      useFetchData<UpdateSafeMutation, UpdateSafeMutationVariables>(UpdateSafeDocument),
      options
    );
export const LoginAsSafeDocument = `
    mutation LoginAsSafe($accountId: AccountID!) {
  loginAsSafe(id: $accountId)
}
    `;
export const useLoginAsSafeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<LoginAsSafeMutation, TError, LoginAsSafeMutationVariables, TContext>) =>
    useMutation<LoginAsSafeMutation, TError, LoginAsSafeMutationVariables, TContext>(
      ['LoginAsSafe'],
      useFetchData<LoginAsSafeMutation, LoginAsSafeMutationVariables>(LoginAsSafeDocument),
      options
    );
export const TallyProposalDetailsDocument = `
    query TallyProposalDetails($input: ProposalInput!) {
  proposal(input: $input) {
    id
    metadata {
      title
      description
      snapshotURL
    }
    executableCalls {
      value
      target
      calldata
      signature
      type
      offchaindata {
        ... on ExecutableCallSwap {
          amountIn
          fee
          buyToken {
            data {
              price
              decimals
              name
              symbol
            }
          }
          sellToken {
            data {
              price
              decimals
              name
              symbol
            }
          }
          to
          quote {
            buyAmount
            feeAmount
          }
          order {
            id
            status
            buyAmount
            address
          }
          priceChecker {
            tokenPath
            feePath
            uniPoolPath
            slippage
          }
        }
        ... on ExecutableCallRewards {
          contributorFee
          tallyFee
          recipients
        }
      }
    }
    governor {
      id
      chainId
      slug
      organization {
        metadata {
          description
        }
      }
      contracts {
        governor {
          address
          type
        }
        tokens {
          address
        }
      }
      timelockId
    }
  }
}
    `;
export const useTallyProposalDetailsQuery = <
      TData = TallyProposalDetailsQuery,
      TError = Error
    >(
      variables: TallyProposalDetailsQueryVariables,
      options?: UseQueryOptions<TallyProposalDetailsQuery, TError, TData>
    ) =>
    useQuery<TallyProposalDetailsQuery, TError, TData>(
      ['TallyProposalDetails', variables],
      useFetchData<TallyProposalDetailsQuery, TallyProposalDetailsQueryVariables>(TallyProposalDetailsDocument).bind(null, variables),
      options
    );
export const TallyProposalHeaderDocument = `
    query TallyProposalHeader($input: ProposalInput!) {
  proposal(input: $input) {
    id
    metadata {
      description
      title
    }
    status
    governor {
      quorum
      name
      chainId
    }
    proposer {
      name
      picture
      address
    }
    executableCalls {
      calldata
    }
    originalId
  }
}
    `;
export const useTallyProposalHeaderQuery = <
      TData = TallyProposalHeaderQuery,
      TError = Error
    >(
      variables: TallyProposalHeaderQueryVariables,
      options?: UseQueryOptions<TallyProposalHeaderQuery, TError, TData>
    ) =>
    useQuery<TallyProposalHeaderQuery, TError, TData>(
      ['TallyProposalHeader', variables],
      useFetchData<TallyProposalHeaderQuery, TallyProposalHeaderQueryVariables>(TallyProposalHeaderDocument).bind(null, variables),
      options
    );
export const ProposalSecurityCheckDocument = `
    query ProposalSecurityCheck($proposalId: ID!) {
  proposalSecurityCheck(proposalId: $proposalId) {
    metadata {
      metadata {
        threatAnalysis {
          actionsData {
            events {
              eventType
              severity
              description
            }
            result
          }
          proposerRisk
        }
      }
      simulations {
        publicURI
        result
      }
    }
    createdAt
  }
}
    `;
export const useProposalSecurityCheckQuery = <
      TData = ProposalSecurityCheckQuery,
      TError = Error
    >(
      variables: ProposalSecurityCheckQueryVariables,
      options?: UseQueryOptions<ProposalSecurityCheckQuery, TError, TData>
    ) =>
    useQuery<ProposalSecurityCheckQuery, TError, TData>(
      ['ProposalSecurityCheck', variables],
      useFetchData<ProposalSecurityCheckQuery, ProposalSecurityCheckQueryVariables>(ProposalSecurityCheckDocument).bind(null, variables),
      options
    );
export const TallyProposalMobileButtonsDocument = `
    query TallyProposalMobileButtons($input: ProposalInput!) {
  proposal(input: $input) {
    id
    status
    originalId
    governor {
      id
    }
  }
}
    `;
export const useTallyProposalMobileButtonsQuery = <
      TData = TallyProposalMobileButtonsQuery,
      TError = Error
    >(
      variables: TallyProposalMobileButtonsQueryVariables,
      options?: UseQueryOptions<TallyProposalMobileButtonsQuery, TError, TData>
    ) =>
    useQuery<TallyProposalMobileButtonsQuery, TError, TData>(
      ['TallyProposalMobileButtons', variables],
      useFetchData<TallyProposalMobileButtonsQuery, TallyProposalMobileButtonsQueryVariables>(TallyProposalMobileButtonsDocument).bind(null, variables),
      options
    );
export const TallyProposalStatusHistoryDocument = `
    query TallyProposalStatusHistory($input: ProposalInput!) {
  proposal(input: $input) {
    id
    createdAt
    governor {
      id
      timelockId
    }
    proposer {
      name
      address
      picture
    }
    executableCalls {
      target
    }
  }
}
    `;
export const useTallyProposalStatusHistoryQuery = <
      TData = TallyProposalStatusHistoryQuery,
      TError = Error
    >(
      variables: TallyProposalStatusHistoryQueryVariables,
      options?: UseQueryOptions<TallyProposalStatusHistoryQuery, TError, TData>
    ) =>
    useQuery<TallyProposalStatusHistoryQuery, TError, TData>(
      ['TallyProposalStatusHistory', variables],
      useFetchData<TallyProposalStatusHistoryQuery, TallyProposalStatusHistoryQueryVariables>(TallyProposalStatusHistoryDocument).bind(null, variables),
      options
    );
export const TallyProposalVersionHistoryDocument = `
    query TallyProposalVersionHistory($input: ProposalInput!) {
  proposalWithVersions(input: $input) {
    id
    createdAt
    proposer {
      name
      address
    }
  }
}
    `;
export const useTallyProposalVersionHistoryQuery = <
      TData = TallyProposalVersionHistoryQuery,
      TError = Error
    >(
      variables: TallyProposalVersionHistoryQueryVariables,
      options?: UseQueryOptions<TallyProposalVersionHistoryQuery, TError, TData>
    ) =>
    useQuery<TallyProposalVersionHistoryQuery, TError, TData>(
      ['TallyProposalVersionHistory', variables],
      useFetchData<TallyProposalVersionHistoryQuery, TallyProposalVersionHistoryQueryVariables>(TallyProposalVersionHistoryDocument).bind(null, variables),
      options
    );
export const TallyProposalDraftCtaDocument = `
    query TallyProposalDraftCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    status
    createdAt
    originalId
    executableCalls {
      value
      target
      calldata
      signature
    }
    proposer {
      address
      name
    }
    metadata {
      description
      title
    }
    events {
      txHash
      type
    }
    governor {
      id
      chainId
      contracts {
        governor {
          address
          type
        }
      }
      token {
        decimals
      }
      parameters {
        proposalThreshold
      }
    }
  }
}
    `;
export const useTallyProposalDraftCtaQuery = <
      TData = TallyProposalDraftCtaQuery,
      TError = Error
    >(
      variables: TallyProposalDraftCtaQueryVariables,
      options?: UseQueryOptions<TallyProposalDraftCtaQuery, TError, TData>
    ) =>
    useQuery<TallyProposalDraftCtaQuery, TError, TData>(
      ['TallyProposalDraftCTA', variables],
      useFetchData<TallyProposalDraftCtaQuery, TallyProposalDraftCtaQueryVariables>(TallyProposalDraftCtaDocument).bind(null, variables),
      options
    );
export const TallyProposalSubmittedCtaDocument = `
    query TallyProposalSubmittedCTA($input: ProposalInput!) {
  proposal(input: $input) {
    id
    governor {
      chainId
    }
    events {
      type
      txHash
    }
  }
}
    `;
export const useTallyProposalSubmittedCtaQuery = <
      TData = TallyProposalSubmittedCtaQuery,
      TError = Error
    >(
      variables: TallyProposalSubmittedCtaQueryVariables,
      options?: UseQueryOptions<TallyProposalSubmittedCtaQuery, TError, TData>
    ) =>
    useQuery<TallyProposalSubmittedCtaQuery, TError, TData>(
      ['TallyProposalSubmittedCTA', variables],
      useFetchData<TallyProposalSubmittedCtaQuery, TallyProposalSubmittedCtaQueryVariables>(TallyProposalSubmittedCtaDocument).bind(null, variables),
      options
    );
export const ArchiveProposalDocument = `
    mutation ArchiveProposal($originalId: IntID!) {
  archiveProposal(originalId: $originalId)
}
    `;
export const useArchiveProposalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ArchiveProposalMutation, TError, ArchiveProposalMutationVariables, TContext>) =>
    useMutation<ArchiveProposalMutation, TError, ArchiveProposalMutationVariables, TContext>(
      ['ArchiveProposal'],
      useFetchData<ArchiveProposalMutation, ArchiveProposalMutationVariables>(ArchiveProposalDocument),
      options
    );
export const RestorePreviousProposalDraftDocument = `
    mutation RestorePreviousProposalDraft($id: IntID!) {
  restoreProposalDraft(id: $id)
}
    `;
export const useRestorePreviousProposalDraftMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RestorePreviousProposalDraftMutation, TError, RestorePreviousProposalDraftMutationVariables, TContext>) =>
    useMutation<RestorePreviousProposalDraftMutation, TError, RestorePreviousProposalDraftMutationVariables, TContext>(
      ['RestorePreviousProposalDraft'],
      useFetchData<RestorePreviousProposalDraftMutation, RestorePreviousProposalDraftMutationVariables>(RestorePreviousProposalDraftDocument),
      options
    );
export const TallyProposalDocument = `
    query TallyProposal($input: ProposalInput!) {
  proposal(input: $input) {
    onchainId
    status
    proposer {
      address
    }
    governor {
      id
      organization {
        governorIds
      }
    }
  }
}
    `;
export const useTallyProposalQuery = <
      TData = TallyProposalQuery,
      TError = Error
    >(
      variables: TallyProposalQueryVariables,
      options?: UseQueryOptions<TallyProposalQuery, TError, TData>
    ) =>
    useQuery<TallyProposalQuery, TError, TData>(
      ['TallyProposal', variables],
      useFetchData<TallyProposalQuery, TallyProposalQueryVariables>(TallyProposalDocument).bind(null, variables),
      options
    );
export const TallyProposalMetadataDocument = `
    query TallyProposalMetadata($input: ProposalInput!) {
  proposal(input: $input) {
    id
    originalId
    onchainId
    metadata {
      title
      description
      snapshotURL
    }
    status
    proposer {
      address
    }
    governor {
      id
      kind
      organization {
        id
        name
        metadata {
          icon
        }
      }
    }
  }
}
    `;
export const useTallyProposalMetadataQuery = <
      TData = TallyProposalMetadataQuery,
      TError = Error
    >(
      variables: TallyProposalMetadataQueryVariables,
      options?: UseQueryOptions<TallyProposalMetadataQuery, TError, TData>
    ) =>
    useQuery<TallyProposalMetadataQuery, TError, TData>(
      ['TallyProposalMetadata', variables],
      useFetchData<TallyProposalMetadataQuery, TallyProposalMetadataQueryVariables>(TallyProposalMetadataDocument).bind(null, variables),
      options
    );
export const UpdateTallyProposalDocument = `
    mutation UpdateTallyProposal($input: UpdateProposalInput!) {
  updateProposal(input: $input) {
    id
  }
}
    `;
export const useUpdateTallyProposalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTallyProposalMutation, TError, UpdateTallyProposalMutationVariables, TContext>) =>
    useMutation<UpdateTallyProposalMutation, TError, UpdateTallyProposalMutationVariables, TContext>(
      ['UpdateTallyProposal'],
      useFetchData<UpdateTallyProposalMutation, UpdateTallyProposalMutationVariables>(UpdateTallyProposalDocument),
      options
    );
export const UserConnectAddressDocument = `
    query UserConnectAddress($address: Address!) {
  address(address: $address) {
    address
    ethAccount {
      email
    }
  }
}
    `;
export const useUserConnectAddressQuery = <
      TData = UserConnectAddressQuery,
      TError = Error
    >(
      variables: UserConnectAddressQueryVariables,
      options?: UseQueryOptions<UserConnectAddressQuery, TError, TData>
    ) =>
    useQuery<UserConnectAddressQuery, TError, TData>(
      ['UserConnectAddress', variables],
      useFetchData<UserConnectAddressQuery, UserConnectAddressQueryVariables>(UserConnectAddressDocument).bind(null, variables),
      options
    );
export const UserCreateApikeyDocument = `
    mutation UserCreateAPIKEY($name: String!) {
  createAPIKey(name: $name)
}
    `;
export const useUserCreateApikeyMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UserCreateApikeyMutation, TError, UserCreateApikeyMutationVariables, TContext>) =>
    useMutation<UserCreateApikeyMutation, TError, UserCreateApikeyMutationVariables, TContext>(
      ['UserCreateAPIKEY'],
      useFetchData<UserCreateApikeyMutation, UserCreateApikeyMutationVariables>(UserCreateApikeyDocument),
      options
    );
export const UserOrganizationsMemberOfDocument = `
    query UserOrganizationsMemberOf($input: OrganizationsInput) {
  organizations(input: $input) {
    nodes {
      ... on Organization {
        id
        name
        chainIds
        slug
        metadata {
          icon
        }
        hasActiveProposals
      }
    }
  }
}
    `;
export const useUserOrganizationsMemberOfQuery = <
      TData = UserOrganizationsMemberOfQuery,
      TError = Error
    >(
      variables?: UserOrganizationsMemberOfQueryVariables,
      options?: UseQueryOptions<UserOrganizationsMemberOfQuery, TError, TData>
    ) =>
    useQuery<UserOrganizationsMemberOfQuery, TError, TData>(
      variables === undefined ? ['UserOrganizationsMemberOf'] : ['UserOrganizationsMemberOf', variables],
      useFetchData<UserOrganizationsMemberOfQuery, UserOrganizationsMemberOfQueryVariables>(UserOrganizationsMemberOfDocument).bind(null, variables),
      options
    );
export const UserOrganizationsDocument = `
    query UserOrganizations($input: OrganizationsInput) {
  organizations(input: $input) {
    nodes {
      ... on Organization {
        id
        metadata {
          description
          socials {
            website
          }
          icon
        }
        name
        slug
        myRole
        governorIds
      }
    }
  }
}
    `;
export const useUserOrganizationsQuery = <
      TData = UserOrganizationsQuery,
      TError = Error
    >(
      variables?: UserOrganizationsQueryVariables,
      options?: UseQueryOptions<UserOrganizationsQuery, TError, TData>
    ) =>
    useQuery<UserOrganizationsQuery, TError, TData>(
      variables === undefined ? ['UserOrganizations'] : ['UserOrganizations', variables],
      useFetchData<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument).bind(null, variables),
      options
    );
export const UserProfileUpdateDocument = `
    mutation UserProfileUpdate($bio: String, $name: String, $picture: String) {
  updateAccount(bio: $bio, name: $name, picture: $picture)
}
    `;
export const useUserProfileUpdateMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UserProfileUpdateMutation, TError, UserProfileUpdateMutationVariables, TContext>) =>
    useMutation<UserProfileUpdateMutation, TError, UserProfileUpdateMutationVariables, TContext>(
      ['UserProfileUpdate'],
      useFetchData<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>(UserProfileUpdateDocument),
      options
    );
export const RemoveTwitterDocument = `
    mutation RemoveTwitter {
  removeTwitter
}
    `;
export const useRemoveTwitterMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveTwitterMutation, TError, RemoveTwitterMutationVariables, TContext>) =>
    useMutation<RemoveTwitterMutation, TError, RemoveTwitterMutationVariables, TContext>(
      ['RemoveTwitter'],
      useFetchData<RemoveTwitterMutation, RemoveTwitterMutationVariables>(RemoveTwitterDocument),
      options
    );
export const UpdateAccountDocument = `
    mutation UpdateAccount($bio: String, $name: String) {
  updateAccount(bio: $bio, name: $name)
}
    `;
export const useUpdateAccountMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAccountMutation, TError, UpdateAccountMutationVariables, TContext>) =>
    useMutation<UpdateAccountMutation, TError, UpdateAccountMutationVariables, TContext>(
      ['UpdateAccount'],
      useFetchData<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument),
      options
    );
export const UpdateAccountEmailDocument = `
    mutation UpdateAccountEmail($email: String) {
  updateAccount(email: $email)
}
    `;
export const useUpdateAccountEmailMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAccountEmailMutation, TError, UpdateAccountEmailMutationVariables, TContext>) =>
    useMutation<UpdateAccountEmailMutation, TError, UpdateAccountEmailMutationVariables, TContext>(
      ['UpdateAccountEmail'],
      useFetchData<UpdateAccountEmailMutation, UpdateAccountEmailMutationVariables>(UpdateAccountEmailDocument),
      options
    );
export const UpdateIdentitiesDocument = `
    mutation UpdateIdentities($identities: IdentitiesInput) {
  updateAccount(identities: $identities)
}
    `;
export const useUpdateIdentitiesMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateIdentitiesMutation, TError, UpdateIdentitiesMutationVariables, TContext>) =>
    useMutation<UpdateIdentitiesMutation, TError, UpdateIdentitiesMutationVariables, TContext>(
      ['UpdateIdentities'],
      useFetchData<UpdateIdentitiesMutation, UpdateIdentitiesMutationVariables>(UpdateIdentitiesDocument),
      options
    );
export const UpdateProfileDocument = `
    mutation UpdateProfile($name: String, $bio: String) {
  updateAccount(name: $name, bio: $bio)
}
    `;
export const useUpdateProfileMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>) =>
    useMutation<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>(
      ['UpdateProfile'],
      useFetchData<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument),
      options
    );
export const UpdateProfileImageDocument = `
    mutation UpdateProfileImage($picture: String) {
  updateAccount(picture: $picture)
}
    `;
export const useUpdateProfileImageMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProfileImageMutation, TError, UpdateProfileImageMutationVariables, TContext>) =>
    useMutation<UpdateProfileImageMutation, TError, UpdateProfileImageMutationVariables, TContext>(
      ['UpdateProfileImage'],
      useFetchData<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>(UpdateProfileImageDocument),
      options
    );
export const GovernorAccountVotingPowerDocument = `
    query GovernorAccountVotingPower($input: DelegateInput!, $blockNumber: Int) {
  delegate(input: $input) {
    votesCount(blockNumber: $blockNumber)
  }
}
    `;
export const useGovernorAccountVotingPowerQuery = <
      TData = GovernorAccountVotingPowerQuery,
      TError = Error
    >(
      variables: GovernorAccountVotingPowerQueryVariables,
      options?: UseQueryOptions<GovernorAccountVotingPowerQuery, TError, TData>
    ) =>
    useQuery<GovernorAccountVotingPowerQuery, TError, TData>(
      ['GovernorAccountVotingPower', variables],
      useFetchData<GovernorAccountVotingPowerQuery, GovernorAccountVotingPowerQueryVariables>(GovernorAccountVotingPowerDocument).bind(null, variables),
      options
    );
export const CreateVoteAttemptDocument = `
    mutation CreateVoteAttempt($input: CreateVoteAttemptInput!) {
  createVoteAttempt(input: $input)
}
    `;
export const useCreateVoteAttemptMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVoteAttemptMutation, TError, CreateVoteAttemptMutationVariables, TContext>) =>
    useMutation<CreateVoteAttemptMutation, TError, CreateVoteAttemptMutationVariables, TContext>(
      ['CreateVoteAttempt'],
      useFetchData<CreateVoteAttemptMutation, CreateVoteAttemptMutationVariables>(CreateVoteAttemptDocument),
      options
    );
export const NonceDocument = `
    query Nonce {
  nonce {
    expirationTime
    issuedAt
    nonce
    nonceToken
  }
}
    `;
export const useNonceQuery = <
      TData = NonceQuery,
      TError = Error
    >(
      variables?: NonceQueryVariables,
      options?: UseQueryOptions<NonceQuery, TError, TData>
    ) =>
    useQuery<NonceQuery, TError, TData>(
      variables === undefined ? ['Nonce'] : ['Nonce', variables],
      useFetchData<NonceQuery, NonceQueryVariables>(NonceDocument).bind(null, variables),
      options
    );
export const TransactionAttemptsDocument = `
    query TransactionAttempts($input: TransactionAttemptsInput!) {
  transactionAttempts(input: $input) {
    id
    tokenId
    transactionType
    createdAt
  }
}
    `;
export const useTransactionAttemptsQuery = <
      TData = TransactionAttemptsQuery,
      TError = Error
    >(
      variables: TransactionAttemptsQueryVariables,
      options?: UseQueryOptions<TransactionAttemptsQuery, TError, TData>
    ) =>
    useQuery<TransactionAttemptsQuery, TError, TData>(
      ['TransactionAttempts', variables],
      useFetchData<TransactionAttemptsQuery, TransactionAttemptsQueryVariables>(TransactionAttemptsDocument).bind(null, variables),
      options
    );