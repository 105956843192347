export const spacing = {
  88: "22rem",
  11: "2.75rem",
  13: "3.25rem",
  15: "3.75rem",
  16: "4rem",
  17: "4.25rem",
  18: "4.5rem",
  19: "4.75rem",
  21: "5.25rem",
  22: "5.5rem",
}
