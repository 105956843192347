import { useCallback, useMemo } from "react"
import type { NextRouter } from "next/router"
import { useRouter as useNextRouter } from "next/router"
import DOMPurify from "dompurify"

import type { AccountId } from "query/graphql"
import { isValidPath, isValidUrl } from "common/helpers/utils"

type QueryParams = {
  userId?: string
  proposalId?: number
  resetToken?: string
  voterAddress?: string
  governanceId?: AccountId
  tallyId?: string
  org?: string
  redirect?: string
  delegateFilter?: string
  governorIdFilter?: string
  issueFilter?: string
  sort?: string
  history?: string
  address?: string
  depId?: number
}

export const useRouter = (): Omit<NextRouter, "query"> & {
  query: QueryParams
} => {
  const { query: q, push, ...nextRouterProps } = useNextRouter()

  const voterAddress =
    q?.voterAddress != null
      ? (q.voterAddress as string).toLocaleLowerCase()
      : undefined
  const governanceId =
    q?.governanceId != null ? (q.governanceId as AccountId) : undefined
  const proposalId =
    q?.proposalId != null ? Number(q.proposalId as string) : undefined
  const userId = q?.userId != null ? (q.userId as string) : undefined
  const resetToken =
    q?.resetToken != null ? (q.resetToken as string) : undefined
  const tallyId = q?.tallyId != null ? (q.tallyId as string) : undefined
  const redirect =
    q?.redirect != null &&
    (isValidPath(decodeURIComponent(q.redirect as string)) ||
      isValidUrl(decodeURIComponent(q.redirect as string)))
      ? decodeURIComponent(q.redirect as string)
      : undefined
  const delegateFilter =
    q?.delegateFilter != null ? (q.delegateFilter as string) : undefined
  const issueFilter =
    q?.issueFilter != null ? (q.issueFilter as string) : undefined
  const governorIdFilter =
    q?.governorIdFilter != null ? (q.governorIdFilter as string) : undefined
  const sort = q?.sort != null ? (q.sort as string) : undefined
  const history = q?.history != null ? (q.history as string) : undefined
  const address = q?.address != null ? (q.address as string) : undefined
  const parsedDID = q?.depId != null ? parseInt(q.depId as string) : undefined
  const depId = parsedDID && !isNaN(parsedDID) ? parsedDID : undefined

  const query: QueryParams = useMemo(
    () => ({
      tallyId,
      voterAddress,
      governanceId,
      proposalId,
      userId,
      resetToken,
      redirect,
      delegateFilter,
      issueFilter,
      governorIdFilter,
      sort,
      history,
      address,
      depId,
    }),
    [
      tallyId,
      voterAddress,
      governanceId,
      proposalId,
      userId,
      resetToken,
      redirect,
      delegateFilter,
      issueFilter,
      governorIdFilter,
      sort,
      history,
      address,
      depId,
    ],
  )

  const sanitizedPush = useCallback(
    async (url: string, as?: string, options?: object) => {
      // sanitize input
      const sanitizedUrl = DOMPurify.sanitize(url)
      const sanitizedAs = as ? DOMPurify.sanitize(as) : undefined

      // call the original push function with the sanitized inputs
      return push(sanitizedUrl, sanitizedAs, options)
    },
    [push],
  )

  const router = useMemo(
    () => ({
      ...nextRouterProps,
      push: sanitizedPush,
      query,
    }),
    [nextRouterProps, sanitizedPush, query],
  )

  return router
}
