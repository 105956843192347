import type { FC, Dispatch, SetStateAction } from "react"
import React from "react"
import {
  Text,
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react"

import ArrowLeft from "ui/components/icons/ArrowLeft"
import CrossMark from "ui/components/icons/CrossMark"
import type { Step } from "common/types/step"

type Props = {
  isOpen: boolean
  onClose: () => void
  step: Step
  goToPrevious: () => void
  isFirstStep: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
}

const NavegableModal: FC<Props> = ({
  isOpen,
  onClose,
  step,
  goToPrevious,
  isFirstStep,
  setIsActive,
}) => {
  if (step == null) return null

  const { Component, title, props } = step

  const handleCloseClick = (): void => {
    onClose()
    setIsActive(false)
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="0.4rem">
        <ModalHeader
          borderBottom="gray.dark"
          display="flex"
          justifyContent="space-between"
          py={3}
        >
          {!isFirstStep ? (
            <IconButton
              aria-label="Go to previous step"
              bg="white"
              icon={<ArrowLeft />}
              size="sm"
              onClick={goToPrevious}
            />
          ) : (
            <Box h={6} w={6} />
          )}
          <Text>{title}</Text>
          <IconButton
            aria-label="Close modal"
            bg="white"
            icon={<CrossMark />}
            size="sm"
            onClick={handleCloseClick}
          />
        </ModalHeader>
        <ModalBody p={0}>
          <Component {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NavegableModal
