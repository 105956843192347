import React from "react"
import { createIcon } from "@chakra-ui/icons"

const PurpleCircleIcon = createIcon({
  displayName: "PurpleCircleIcon",
  viewBox: "0 0 36 36",
  path: (
    <g>
      <circle cx="18" cy="18" fill="url(#purplecircle0paint)" r="18" />
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(34.4789 7.60563) rotate(155.145) scale(37.9986 41.9984)"
          gradientUnits="userSpaceOnUse"
          id="purplecircle0paint"
          r="1"
        >
          <stop stopColor="#F1CDEB" />
          <stop offset="0.86653" stopColor="#7B21F0" />
        </radialGradient>
      </defs>
    </g>
  ),
})

export default PurpleCircleIcon
