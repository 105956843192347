import React from "react"
import { createIcon } from "@chakra-ui/icons"

const GreenParalelogramIcon = createIcon({
  displayName: "GreenParalelogramIcon",
  viewBox: "0 0 44 93",
  path: (
    <g>
      <path
        d="M43.8457 92.3216L96.0364 91.7784L52.1907 0.415771L0 0.959007L43.8457 92.3216Z"
        fill="url(#green_paralelogram_paint0)"
        opacity="0.6"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="green_paralelogram_paint0"
          x1="0"
          x2="96.0364"
          y1="46.3687"
          y2="46.3687"
        >
          <stop stopColor="#27F2D4" />
          <stop offset="1" stopColor="#C8F0D3" />
        </linearGradient>
      </defs>
    </g>
  ),
})

export default GreenParalelogramIcon
