import type { Chain } from "wagmi"

import { EVM_NAMESPACE } from "web3/constants/caip"
import type { AccountId, ChainId } from "query/graphql"
import { getChainId, getChainIdParams } from "web3/helpers/chainId"
import { getAccountId, getAccountIdParams } from "web3/helpers/accountId"

export const addressToAccountId = (
  address: string,
  reference: number,
): AccountId => {
  const namespace = EVM_NAMESPACE
  const chainId = getChainId({ namespace, reference })
  const accountId = getAccountId({ chainId, address })

  return accountId
}

export const accountIdToChainId = (accountId: AccountId): ChainId => {
  const CHAIN_ID_REGEX = /eip155:[0-9]+/gi
  const chainIdCapture = accountId.match(CHAIN_ID_REGEX)
  const chainId = chainIdCapture?.[0] as string

  return chainId
}

export const parseAccountId = (
  accountId: AccountId,
): { address: string; chainId: ChainId } => {
  const [namespace, reference, address] = accountId.split(":")

  return { chainId: `${namespace}:${reference}`, address }
}

// generalized versions of above
const parseChainIdFormat = (str: string) => {
  const [namespace, reference, parsed] = str.split(":")

  return { chainId: `${namespace}:${reference}`, parsedStr: parsed }
}

export const stripChainId = (str: string) => {
  return parseChainIdFormat(str).parsedStr
}

export const formatWithChainId = (chainId: string, str: string) => {
  return `${chainId}:${str}`
}

export const chainReferenceToChainId = (chainReference: number): ChainId => {
  const namespace = EVM_NAMESPACE
  const chainId = getChainId({ namespace, reference: chainReference })

  return chainId
}

export function chainToChainReference(
  chain:
    | (Chain & {
        unsupported?: boolean | undefined
      })
    | undefined,
) {
  return chain ? chain.id : undefined
}

export const chainIdToChainReference = (chainId: string): number => {
  const { reference } = getChainIdParams(chainId)

  return Number(reference)
}

export const getTransactionParams = (
  txId: string,
): { txHash: string; chainId: string } => {
  const { address: txHash, chainId } = getAccountIdParams(txId)

  return { txHash, chainId }
}

// TODO:DAVE remove this when we discover why target from executableCalls is sometimes an accountId and sometimes just an address
export const parseAddressFromTarget = (target: string): { address: string } => {
  if (target.includes(":")) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [namespace, reference, address] = target.split(":")

    return { address }
  }

  return { address: target }
}
