import { chainReferenceToChainId } from "web3/helpers/transformers"
import chains from "web3/constants/chains"
import type { Chain } from "query/graphql"

export const getChainByReference = (reference: number): Chain | undefined => {
  const chainId = chainReferenceToChainId(reference)
  const foundChain = Object.values(chains).find((chain) => chain.id === chainId)

  if (foundChain) {
    return foundChain
  }

  return undefined
}

export const getChainByChainId = (chainId: string): Chain | undefined => {
  const foundChain = Object.values(chains).find((chain) => chain.id === chainId)

  if (foundChain) {
    return foundChain
  }

  return undefined
}
