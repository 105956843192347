import { useState, useMemo, useEffect, useCallback } from "react"
import { useDisclosure } from "@chakra-ui/react"

import type { Step } from "common/types/step"

type Values = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  addStep: (step: Step) => void
  isFirstStep: boolean
  goToPrevious: () => void
  currentStep: Step
}

export const useModalStepper = (): Values => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [steps, setSteps] = useState<Step[]>([])

  const lastStep = useMemo(() => steps.length - 1, [steps])
  const isFirstStep = useMemo(() => steps.length === 1, [steps])

  const currentStep = useMemo(() => steps[lastStep], [steps, lastStep])

  const goToPrevious = useCallback(
    () => setSteps((prevSteps) => prevSteps.slice(0, -1)),
    [],
  )

  const addStep = useCallback(
    (step: Step) => setSteps((prevSteps) => [...prevSteps, step]),
    [],
  )

  useEffect(() => {
    if (!isOpen) {
      setSteps([])
    }
  }, [isOpen])

  return {
    isOpen,
    onClose,
    onOpen,
    isFirstStep,
    currentStep,
    goToPrevious,
    addStep,
  }
}
