import React from "react"
import { createIcon } from "@chakra-ui/icons"

const CrossMark = createIcon({
  displayName: "CrossMark",
  viewBox: "0 0 320 512",
  path: (
    <path
      d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6 27.3 411.3c-6.253 6.253-16.37 6.253-22.63 0-6.253-6.253-6.253-16.37 0-22.63L137.4 256 4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63 6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0 6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7c6.3 6.2 6.3 16.4 0 22.6z"
      fill="currentColor"
    />
  ),
})

export default CrossMark
