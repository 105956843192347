import type { ReactNode } from "react"
import {
  useState,
  createContext,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from "react"
import { useSigner } from "wagmi"
import type { Signer, FetchSignerResult } from "@wagmi/core"

function useSignerData() {
  const [chainId, setChainId] = useState<number | undefined>(undefined)

  const signer = useSigner({ chainId })

  const subscribers = useRef(new Set<() => void>())

  const get = useCallback(() => signer, [signer])

  const set = useCallback((nextChainId: number) => {
    setChainId(nextChainId)
    subscribers.current.forEach((cb) => cb())
  }, [])

  const subscribe = useCallback((cb: () => void) => {
    subscribers.current.add(cb)

    return () => subscribers.current.delete(cb)
  }, [])

  return {
    get,
    set,
    subscribe,
  }
}

type UseSignerDataReturnType = ReturnType<typeof useSignerData>

const SignerContext = createContext<UseSignerDataReturnType | null>(null)

export function SignerProvider({ children }: { children: ReactNode }) {
  return (
    <SignerContext.Provider value={useSignerData()}>
      {children}
    </SignerContext.Provider>
  )
}

type UseSignerStore = {
  signer: FetchSignerResult<Signer> | undefined
  status: "error" | "idle" | "loading" | "success"
  setChainIdForSigner: (chainId: number) => void
}

export function useSignerStore(): UseSignerStore {
  const signerContext = useContext(SignerContext)

  if (!signerContext) {
    throw new Error("Unable to find signer context")
  }

  const state = useSyncExternalStore(signerContext.subscribe, () =>
    signerContext.get(),
  )

  return {
    signer: state?.data,
    status: state?.status,
    setChainIdForSigner: signerContext.set,
  }
}
