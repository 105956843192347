import { type ComponentMultiStyleConfig } from "@chakra-ui/react"
import { modalAnatomy as parts } from "@chakra-ui/anatomy"

const modal: ComponentMultiStyleConfig = {
  parts: parts.keys,
  baseStyle: {
    dialog: {
      rounded: "10px",
    },
  },
  sizes: {
    md: {
      dialog: {
        marginLeft: "12px",
        marginRight: "12px",
      },
    },
    lg: {
      dialog: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
}

export default modal
