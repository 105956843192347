import { ChainId } from "caip"
import { removeTrailingSlashes } from "common/helpers/utils"
import type { Chain } from "query/graphql/schema"

type ChainIdParams = {
  namespace: string
  reference: number
}

export const getChainId = ({ namespace, reference }: ChainIdParams): string => {
  const chainId = ChainId.format({ namespace, reference: String(reference) })

  return chainId
}

export const getChainIdParams = (chainId: string): ChainIdParams => {


  const { namespace, reference } = ChainId.parse(chainId)

  return { namespace, reference: Number(reference) }
}

export const getExplorerAddressLink = (chainData: Chain, address: string) => {
  return `${removeTrailingSlashes(chainData.blockExplorer?.url)}/address/${address}`
}

export const isValidChainReference = (chainReference: number): boolean => {
  return typeof chainReference === "number" && chainReference > 0
}

export const isValidChainId = (chainId: string): boolean => {
  const [namespace, reference] = chainId.split(":")

  if (!namespace || !reference) return false

  // regex references can be found here:
  // https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-2.md#syntax
  const NAMESPACE_REGEX = /[-a-z0-9]{3,8}/gi
  const namespaceMatches = namespace.match(NAMESPACE_REGEX)

  const REFERENCE_REGEX = /[-a-zA-Z0-9]{1,32}/gi
  const referenceMatches = reference.match(REFERENCE_REGEX)

  if (!namespaceMatches || !referenceMatches) {
    return false
  }

  return true
}

