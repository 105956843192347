import { createStandaloneToast } from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"

import theme from "theme"
import { Toast } from "common/hooks/useToast"
import type {
  GqlError,
  HttpError,
  ParsingError,
  SentryError,
} from "common/components/ErrorBoundary"
import {
  ErrorType,
  Severity,
  toFormattedString,
} from "common/components/ErrorBoundary"
import type {
  GqlContextError,
  GqlMessages,
  HttpMessages,
} from "common/helpers/fetcher"
import { HttpErrorType } from "common/helpers/fetcher"

export const handleError = (error: Error): Error => {
  throw error
}

export const getSentryError = (error: unknown): SentryError => {
  // @ts-expect-error by the time we are here, we know for sure,
  // as we generated the error that the error.message _will_ be a JSON compliant string
  return jsonParse(error.message, {
    // @ts-expect-error by the time we are here, we know for sure,
    // as we generated the error that the error.message _will_ be a JSON compliant string
    message: error.message,
    // @ts-expect-error by the time we are here, we know for sure,
    // as we generated the error that the error.message _will_ be a JSON compliant string
    type: typeof error.message,
  }) as SentryError
}

export const isFatalError = (error: GqlError): boolean => {
  return error.context.errors.some((error) => error.isFatalError)
}

const DEFAULT_TITLE = "Unexpected error"
const DEFAULT_DESCRIPTION =
  "This error has been reported and will be addressed in the short time. Feel free to reach out through Discord as well"

enum ExpectedMessages {
  NonceExpired = "nonce expired",
}

export const renderHttpErrorMessages = (
  error: HttpError,
  messages?: HttpMessages,
): void => {
  const getExpectedErrors = () => {
    return Object.keys(HttpErrorType).map((name) => {
      return {
        name,
        status: HttpErrorType[name as keyof typeof HttpErrorType],
      }
    })
  }

  const { toast } = createStandaloneToast({
    theme,
  })

  const getDescription = (
    error: HttpError,
    messages?: HttpMessages,
  ): string => {
    const { status } = error.context.error.response
    const expectedErrors = getExpectedErrors()
    const isExpected = expectedErrors.some(
      (expectedError) => expectedError.status === status,
    )

    if (isExpected) {
      if (messages?.descriptions?.[status as HttpErrorType]) {
        return (
          messages?.descriptions?.[status as HttpErrorType] ??
          DEFAULT_DESCRIPTION
        )
      }
    }

    return DEFAULT_DESCRIPTION
  }

  const getTitle = (error: HttpError, messages?: HttpMessages): string => {
    const { status } = error.context.error.response
    const expectedErrors = getExpectedErrors()
    const isExpected = expectedErrors.some(
      (expectedError) => expectedError.status === status,
    )

    if (isExpected) {
      if (messages?.titles?.[status as HttpErrorType]) {
        return messages?.titles?.[status as HttpErrorType] ?? DEFAULT_TITLE
      }
    }

    return DEFAULT_TITLE
  }

  toast({
    status: error.context.error.isFatalError ? "error" : "warning",
    description: getDescription(error, messages),
    title: getTitle(error, messages),
  })
}

export const renderGqlErrorMessages = (
  error: GqlError,
  messages?: GqlMessages,
): void => {
  if (typeof window === "undefined") {
    return
  }

  const { toast } = createStandaloneToast({
    theme,
  })

  error.context.errors.forEach((error) => {
    const getDescription = (
      error: GqlContextError,
      messages?: GqlMessages,
    ): string | undefined => {
      const { message, extensions } = error
      const { code, description } = extensions

      if (messages?.descriptions?.[code]) {
        return messages?.descriptions?.[code] ?? DEFAULT_DESCRIPTION
      }

      if (message.includes(ExpectedMessages.NonceExpired)) {
        return "You have 60 seconds to complete the signing process. Please, try again"
      }

      return description
    }

    const getTitle = (
      error: GqlContextError,
      messages?: GqlMessages,
    ): string => {
      const { message, extensions } = error
      const { code } = extensions

      if (messages?.titles?.[code]) {
        return messages?.titles?.[code] ?? message
      }

      if (message.includes(ExpectedMessages.NonceExpired)) {
        return "Sign in expired"
      }

      return message
    }

    const getLink = (error: GqlContextError): string | undefined => {
      const { link } = error.extensions

      return link
    }

    const getDuration = (error: GqlContextError) => {
      const { type } = error.extensions

      if (type && type.includes("indexing")) {
        return null
      }

      return 5000
    }

    toast({
      position: "bottom",
      duration: getDuration(error),
      render: ({ onClose }) => {
        return (
          <Toast
            description={getDescription(error, messages)}
            link={getLink(error)}
            status={error.isFatalError ? "error" : "warning"}
            title={getTitle(error, messages)}
            onClose={onClose}
          />
        )
      },
    })
  })
}

export const handleParsingError = (
  _error: unknown,
  variables?: Record<string, unknown>,
): void => {
  const error: ParsingError = {
    type: ErrorType.Parsing,
    context: {
      error: _error as Record<string, any>,
      variables,
    },
  }

  Sentry.captureException(new Error(toFormattedString(error)), (scope) => {
    scope.setLevel(Severity.Warning)

    return scope
  })
}

type ErrorWithMessage = {
  message: string
}

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  )
}

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
export const getErrorMessage = (error: unknown): string => {
  return toErrorWithMessage(error).message
}

export const getErrorMessageFromRestRequest = (
  json: any,
): string | undefined => {
  if (json?.error?.data?.message) {
    return json.error.data.message
  }

  if (json?.error) {
    return json.error
  }

  return "Unknown error"
}
