import type { FC, ReactElement, ReactNode } from "react"
import React, { createContext } from "react"

import NavegableModal from "common/components/NavegableModal"
import { useModalStepper } from "common/hooks/useModalStepper"
import type { NavegableModalComponentProps } from "common/types/modal"
import type { Step } from "common/types/step"

type NavegableModalContextValues = {
  state: {
    NavegableModal: ({
      isActive,
      setIsActive,
    }: NavegableModalComponentProps) => JSX.Element | null
  }
  actions: {
    onClose: () => void
    onOpen: () => void
    goToPrevious: () => void
    addStep: (step: Step) => void
  }
}

export const NavegableModalContext = createContext<NavegableModalContextValues>(
  // @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
  {},
)

export const NavegableModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    isOpen,
    goToPrevious,
    isFirstStep,
    onClose,
    currentStep,
    addStep,
    onOpen,
  } = useModalStepper()

  const NavegableModalComponent = ({
    isActive,
    setIsActive,
  }: NavegableModalComponentProps): ReactElement => (
    <NavegableModal
      goToPrevious={goToPrevious}
      isFirstStep={isFirstStep}
      isOpen={isOpen && isActive}
      setIsActive={setIsActive}
      step={currentStep}
      onClose={onClose}
    />
  )

  const state = {
    NavegableModal: NavegableModalComponent,
  }

  const actions = {
    addStep,
    goToPrevious,
    onOpen,
    onClose,
  }

  return (
    <NavegableModalContext.Provider value={{ state, actions }}>
      {children}
    </NavegableModalContext.Provider>
  )
}
