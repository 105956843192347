import React, { useRef, useCallback } from "react"
import {
  Text,
  Stack,
  Box,
  IconButton,
  useToast as useChakraToast,
  Link,
  Button,
  Icon,
} from "@chakra-ui/react"

import ExternalLink from "ui/components/icons/ExternalLink"
import WarningTriangleIcon from "ui/components/icons/WarningTriangleIcon"
import InformationIcon from "ui/components/icons/InformationIcon"
import Check from "ui/components/icons/Check"
import CircleMark from "ui/components/icons/CircleMark"
import CrossMark from "ui/components/icons/CrossMark"
import type {
  ToastOptions,
  ToastProps,
  Toast as ToastType,
} from "common/types/toast"

export const Toast = ({
  title,
  status,
  description,
  link,
  onClose,
}: ToastOptions): JSX.Element => {
  const TOAST_BACKGROUND_COLORS = {
    info: "blue.600",
    error: "red.600",
    success: "green.600",
    warning: "yellow.600",
  }

  const bg = TOAST_BACKGROUND_COLORS[status]

  return (
    <Stack
      alignItems="flex-start"
      bg={bg}
      color="white"
      data-qa="toast"
      minHeight="3.2875rem"
      padding={4}
      position="relative"
      rounded="md"
      shadow="elevation.2"
      spacing={1}
      w="24rem"
    >
      <Stack isInline alignItems="center" justify="center">
        <ToastIcon status={status} />
        <Text color="white" textStyle="body.bold.lg">
          {title}
        </Text>
      </Stack>
      <Stack align="start" direction="column" spacing={0}>
        {description ? (
          <Stack isInline align="start">
            <Box h={6} w={6} />
            <Text color="white" textStyle="body.regular.md" width="100%">
              {description}
            </Text>
          </Stack>
        ) : null}
        {link ? (
          <Stack isInline align="start">
            <Box w={7} />
            <Link isExternal href={link} width="100%">
              <Button
                mx={0}
                px={0}
                rightIcon={<Icon as={ExternalLink} color="white" />}
                variant="transparent"
              >
                Learn more
              </Button>
            </Link>
          </Stack>
        ) : null}
      </Stack>
      <IconButton
        alignItems="center"
        aria-label="Close toast"
        color="white"
        display="flex"
        fontSize={20}
        icon={<Icon as={CrossMark} fill="white" />}
        justifyContent="center"
        position="absolute"
        right={0}
        top={0}
        variant="unstyled"
        onClick={onClose}
      />
    </Stack>
  )
}

export const useToast = (): { toast: ToastType } => {
  const chakraToast = useChakraToast()

  const toastIdRef = useRef<string | number | undefined>()

  const handleClose = useCallback(() => {
    if (toastIdRef.current) {
      chakraToast.close(toastIdRef.current)
    }
  }, [chakraToast])

  const toast = useCallback(
    ({
      status,
      title,
      description,
      link,
      position,
      duration,
    }: ToastProps): string | number | undefined => {
      toastIdRef.current = chakraToast({
        position: position ?? "bottom",
        duration: duration ?? 5000,

        render: function toastRender(): JSX.Element {
          return (
            <Toast
              description={description}
              link={link}
              status={status}
              title={title}
              onClose={handleClose}
            />
          )
        },
      })

      return toastIdRef.current
    },
    [chakraToast, handleClose],
  )

  return { toast }
}

type ToastIconProps = Pick<ToastOptions, "status">

const ToastIcon = ({ status }: ToastIconProps) => {
  return (
    <>
      {status === "error" && <Icon as={CircleMark} color="white" h={5} w={5} />}
      {status === "success" && <Icon as={Check} color="white" h={5} w={5} />}
      {status === "warning" && (
        <Icon as={WarningTriangleIcon} color="white" h={5} w={5} />
      )}
      {status === "info" && (
        <Icon as={InformationIcon} color="white" h={5} w={5} />
      )}
    </>
  )
}
