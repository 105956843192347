import { useMemo } from "react"
import constate from "constate"
import { useDisclosure } from "@chakra-ui/react"

type ModalType =
  | "startDao"
  | "voteModal"
  | "voteOnboarding"
  | "walletSelection"
  | "addGnosisSafe"
  | "linkTwitterModal"
  | "web3StatusModal"
  | "confirmDialogModal"

type Values = {
  [key in ModalType]: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}

const useModalsConstate = (): Values => {
  const {
    isOpen: isWalletSelectionOpen,
    onOpen: onWalletSelectionOpen,
    onClose: onWalletSelectionClose,
  } = useDisclosure()
  const {
    isOpen: isVoteOnboardingOpen,
    onOpen: onVoteOnboardingOpen,
    onClose: onVoteOnboardingClose,
  } = useDisclosure()
  const {
    isOpen: isStartDaoOpen,
    onOpen: onStartDaoOpen,
    onClose: onStartDaoClose,
  } = useDisclosure()
  const {
    isOpen: isVoteModalOpen,
    onOpen: onVoteModalOpen,
    onClose: onVoteModalClose,
  } = useDisclosure()
  const {
    isOpen: isGnosisSafeOpen,
    onOpen: onGnosisSafeOpen,
    onClose: onGnosisSafeClose,
  } = useDisclosure()
  const {
    isOpen: isLinkTwitterModalOpen,
    onOpen: onLinkTwitterModalOpen,
    onClose: onLinkTwitterModalClose,
  } = useDisclosure()
  const {
    isOpen: isWeb3StatusModalOpen,
    onOpen: onWeb3StatusModalOpen,
    onClose: onWeb3StatusModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmDialogOpen,
    onOpen: onConfirmDialogOpen,
    onClose: onConfirmDialogClose,
  } = useDisclosure()

  const modals = useMemo(
    () => ({
      startDao: {
        isOpen: isStartDaoOpen,
        onOpen: onStartDaoOpen,
        onClose: onStartDaoClose,
      },
      voteModal: {
        isOpen: isVoteModalOpen,
        onOpen: onVoteModalOpen,
        onClose: onVoteModalClose,
      },
      voteOnboarding: {
        isOpen: isVoteOnboardingOpen,
        onOpen: onVoteOnboardingOpen,
        onClose: onVoteOnboardingClose,
      },
      walletSelection: {
        isOpen: isWalletSelectionOpen,
        onOpen: onWalletSelectionOpen,
        onClose: onWalletSelectionClose,
      },
      addGnosisSafe: {
        isOpen: isGnosisSafeOpen,
        onOpen: onGnosisSafeOpen,
        onClose: onGnosisSafeClose,
      },
      linkTwitterModal: {
        isOpen: isLinkTwitterModalOpen,
        onOpen: onLinkTwitterModalOpen,
        onClose: onLinkTwitterModalClose,
      },
      web3StatusModal: {
        isOpen: isWeb3StatusModalOpen,
        onOpen: onWeb3StatusModalOpen,
        onClose: onWeb3StatusModalClose,
      },
      confirmDialogModal: {
        isOpen: isConfirmDialogOpen,
        onOpen: onConfirmDialogOpen,
        onClose: onConfirmDialogClose,
      },
    }),
    [
      isStartDaoOpen,
      onStartDaoOpen,
      onStartDaoClose,
      isVoteModalOpen,
      onVoteModalOpen,
      onVoteModalClose,
      isVoteOnboardingOpen,
      onVoteOnboardingOpen,
      onVoteOnboardingClose,
      isWalletSelectionOpen,
      onWalletSelectionOpen,
      onWalletSelectionClose,
      isGnosisSafeOpen,
      onGnosisSafeOpen,
      onGnosisSafeClose,
      isLinkTwitterModalOpen,
      onLinkTwitterModalOpen,
      onLinkTwitterModalClose,
      isWeb3StatusModalOpen,
      onWeb3StatusModalOpen,
      onWeb3StatusModalClose,
      isConfirmDialogOpen,
      onConfirmDialogOpen,
      onConfirmDialogClose,
    ],
  )

  return modals
}

const [ModalsProvider, useModals] = constate(useModalsConstate)

export { ModalsProvider, useModals }
