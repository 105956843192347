import React from "react"
import { createIcon } from "@chakra-ui/icons"

const InformationIcon = createIcon({
  displayName: "WarningTriangeIcon",
  viewBox: "0 0 20 20",
  path: (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 0.824341C4.83062 0.824341 0.625 5.02996 0.625 10.1993C0.625 15.3687 4.83062 19.5743 9.99999 19.5743C15.1694 19.5743 19.375 15.3687 19.375 10.1993C19.375 5.02996 15.1694 0.824341 9.99999 0.824341ZM9.99999 4.66809C10.241 4.66809 10.4767 4.73957 10.6771 4.87348C10.8775 5.0074 11.0337 5.19775 11.126 5.42044C11.2182 5.64314 11.2424 5.88819 11.1953 6.1246C11.1483 6.36102 11.0322 6.57818 10.8618 6.74862C10.6913 6.91907 10.4742 7.03514 10.2378 7.08217C10.0013 7.12919 9.7563 7.10506 9.5336 7.01282C9.3109 6.92057 9.12056 6.76436 8.98664 6.56394C8.85272 6.36352 8.78125 6.12788 8.78125 5.88684C8.78125 5.56361 8.90965 5.25361 9.13821 5.02505C9.36677 4.79649 9.67676 4.66809 9.99999 4.66809ZM13 15.2618H7.375V13.7618H9.43749V9.63684H7.9375V8.13684H10.9375V13.7618H13V15.2618Z"
        fill="currentColor"
      />
    </svg>
  ),
})

export default InformationIcon
