import React from "react"
import { createIcon } from "@chakra-ui/icons"

const CircleMark = createIcon({
  displayName: "CircleMark",
  viewBox: "0 0 320 512",
  path: (
    <path
      d="M320 256c0 88.37-71.63 160-160 160S0 344.37 0 256 71.63 96 160 96s160 71.6 160 160z"
      fill="currentColor"
    />
  ),
})

export default CircleMark
