import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

const skeleton: ComponentSingleStyleConfig = {
  baseStyle: {
    endColor: "#CBD5E0",
    startColor: "#FAFAFC",
  },
}

export default skeleton
