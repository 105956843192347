import type { UseQueryResult, UseQueryOptions } from "@tanstack/react-query"
import { useQuery as useReactQuery } from "@tanstack/react-query"
import { useState } from "react"

import type { GqlError, HttpError } from "common/components/ErrorBoundary"
import type { GqlRequestParams } from "common/helpers/fetcher"
import { fetcher } from "common/helpers/fetcher"

type Options<Data> =
  | Omit<UseQueryOptions<Data, Error>, "queryKey" | "queryFn" | "onError">
  | undefined

export type Error = HttpError | GqlError | undefined

export const useQuery = <
  Data,
  Variables extends Record<string, unknown> = Record<string, unknown>,
>(
  params: GqlRequestParams<Variables>,
  options?: Options<Data>,
): Omit<UseQueryResult<Data, Error>, "error"> & { error: Error } => {
  // UseQueryResult<Data> => {
  const [error, setError] = useState<Error>(undefined)
  const [isError, setIsError] = useState(false)
  // in the first line of the query, it looks for the first word in
  // PascalCase which is 100% of the time the name of either the
  // mutation or the query
  const QUERY_TITLE_REGEX = /([A-Z][a-z0-9]+)+/
  const matches = params.query.match(QUERY_TITLE_REGEX)

  if (!matches) {
    throw new Error("useQuery => error => the query should have a name")
  }

  const [queryName] = matches

  const reactQuery = useReactQuery<Data, Error>(
    [queryName, params.variables],
    () => {
      return fetcher.gql<Data, Variables>({
        ...params,
        onError: (error) => {
          setError(error)
          setIsError(true)

          params.onError?.(error)
        },
      }) as Promise<Data>
    },
    options,
  )

  return { ...reactQuery, error, isError }
}
