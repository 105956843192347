import React from "react"
import { createIcon } from "@chakra-ui/icons"

const WavesIcon = createIcon({
  displayName: "WavesIcon",
  viewBox: "0 0 480 156",
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M89.3971 518.007C32.5773 464.434 32.2449 391.329 8.94924 324.751C-15.8374 253.918 -73.0122 179.755 -51.0931 116.515C-28.7546 52.0639 43.1651 10.9405 120.911 1.72341C191.874 -6.6922 262.861 60.9029 337.763 70.4075C428.668 81.9432 512.273 17.4973 596.707 62.0998C681.493 106.886 731.63 202.783 740.383 283.093C748.684 359.198 670.955 402.185 640.866 467.11C610.411 532.818 635.61 634.804 563.398 664.961C491.296 695.074 394.13 633.151 307.551 606.306C231.378 582.693 147.138 572.451 89.3971 518.007Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
      <path
        clipRule="evenodd"
        d="M119.019 492.986C68.5653 445.311 68.268 380.247 47.5816 321.002C25.5705 257.962 -25.2006 191.962 -5.73478 135.681C14.1018 78.3242 77.9661 41.7273 147.005 33.5235C210.02 26.0353 273.056 86.1909 339.569 94.6495C420.292 104.914 494.533 47.5612 569.51 87.2559C644.797 127.113 689.324 212.456 697.096 283.927C704.467 351.654 635.446 389.912 608.722 447.688C581.681 506.166 604.057 596.928 539.931 623.768C475.906 650.565 389.622 595.458 312.74 571.57C245.098 550.553 170.293 541.442 119.019 492.986Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
      <path
        clipRule="evenodd"
        d="M149.22 466.594C105.402 425.219 105.144 368.758 87.1803 317.344C68.0631 262.638 23.9697 205.363 40.8739 156.522C58.1015 106.747 113.567 74.988 173.524 67.8688C228.25 61.3708 282.996 113.574 340.759 120.914C410.865 129.823 475.343 80.052 540.456 114.498C605.843 149.087 644.51 223.148 651.261 285.17C657.66 343.942 597.719 377.143 574.514 427.286C551.028 478.035 570.459 556.798 514.769 580.085C459.164 603.341 384.229 555.518 317.459 534.787C258.716 516.55 193.75 508.644 149.22 466.594Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
      <path
        clipRule="evenodd"
        d="M178.033 442.555C140.397 406.972 140.175 358.423 124.744 314.206C108.325 267.162 70.4529 217.909 84.972 175.908C99.7688 133.104 147.409 105.794 198.907 99.6707C245.913 94.0829 292.935 138.975 342.55 145.287C402.766 152.949 458.145 110.148 514.074 139.77C570.236 169.515 603.447 233.203 609.246 286.539C614.742 337.085 563.257 365.63 543.324 408.751C523.153 452.389 539.845 520.124 492.011 540.153C444.251 560.148 379.887 519.023 322.537 501.199C272.081 485.515 216.28 478.712 178.033 442.555Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
      <path
        clipRule="evenodd"
        d="M207.888 417.83C176.525 388.248 176.34 347.88 163.481 311.118C149.799 272.003 118.238 231.052 130.338 196.131C142.669 160.543 182.368 137.836 225.282 132.745C264.454 128.099 303.638 165.424 344.983 170.672C395.163 177.042 441.313 141.456 487.922 166.085C534.72 190.815 562.399 243.767 567.233 288.113C571.811 330.136 528.908 353.873 512.297 389.722C495.486 426.006 509.397 482.321 469.536 498.974C429.735 515.6 376.1 481.408 328.308 466.587C286.26 453.548 239.761 447.89 207.888 417.83Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
      <path
        clipRule="evenodd"
        d="M228.946 399.189C202.31 374.18 202.153 340.053 191.232 308.975C179.612 275.91 152.809 241.294 163.084 211.771C173.557 181.686 207.272 162.49 243.72 158.187C276.987 154.259 310.266 185.813 345.38 190.249C387.996 195.636 427.19 165.551 466.772 186.371C506.519 207.277 530.023 252.041 534.128 289.53C538.02 325.052 501.58 345.122 487.474 375.426C473.196 406.1 485.01 453.707 451.157 467.782C417.355 481.839 371.805 452.933 331.216 440.405C295.507 429.38 256.016 424.601 228.946 399.189Z"
        fillRule="evenodd"
        stroke="#EDF2F7"
      />
    </g>
  ),
})

export default WavesIcon
