import type { FC, ReactNode } from "react"
import React from "react"
import type { FlexProps } from "@chakra-ui/react"
import { Text, Center, Icon } from "@chakra-ui/react"
import type { Scope } from "@sentry/nextjs"
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs"

import type {
  Account,
  ChainId,
  Governor,
  Organization,
  Proposal,
} from "query/graphql"
import PurpleCircleIcon from "ui/components/icons/PurpleCircleIcon"
import GreenParalelogramIcon from "ui/components/icons/GreenParalelogramIcon"
import WavesIcon from "ui/components/icons/WavesIcon"
import type {
  GqlContext,
  GqlContextError,
  GrpcErrorType,
  HttpContext,
  HttpContextError,
  ParsingContext,
  RestContext,
  TransactionContext,
} from "common/helpers/fetcher"
import { jsonStringify } from "common/helpers/serialization"
import { getSentryError } from "common/helpers/error"
import { useMe } from "user/providers/MeProvider"

export enum ErrorType {
  Gql = "GQL",
  Http = "HTTP",
  Rest = "REST",
  Parsing = "PARSING",
  ThirdParty = "THIRD_PARTY",
  Transaction = "TRANSACTION",
}

export type RestError = {
  type: ErrorType.Rest
  context: RestContext
}

export type GqlError = {
  type: ErrorType.Gql
  context: GqlContext
}

export type HttpError = {
  type: ErrorType.Http
  context: HttpContext
}

type ThirdPartyContext = {
  from: string
  error: unknown
}

export type ThirdPartyError = {
  type: ErrorType.ThirdParty
  context: ThirdPartyContext
}

export type TransactionError = {
  type: ErrorType.Transaction
  context: TransactionContext
}

export type ParsingError = {
  type: ErrorType.Parsing
  context: ParsingContext
}

export type SentryError = RestError | GqlError | HttpError

type Context = {
  // logged in user's address
  session?: string
  account?: Partial<Account>
  proposal?: Partial<Proposal>
  governance?: Partial<Governor>
  organization?: Partial<Organization>
  tallyProposal?: Partial<Proposal>
  chainId?: ChainId
}

export enum Severity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
  Critical = "critical",
}

export const toFormattedString = (object: Record<any, any>): string => {
  // This slice matches the maxValueLength from sentry.client.config file
  // just to avoid sending a big string that Sentry cannot deal with
  return jsonStringify(object, null, 2).slice(0, 2000)
}

const toObject = (
  errors: GqlContextError[],
): Record<GrpcErrorType, GqlContextError> => {
  return errors.reduce(
    (acc, error) => ({ ...acc, [error.extensions.code]: error }),
    {} as Record<GrpcErrorType, GqlContextError>,
  )
}

export const addThirdPartyContexts = (
  scope: Scope,
  error: ThirdPartyError,
): void => {
  scope.setContext("from", { error: error.context.from })
  scope.setContext("error", {
    error: toFormattedString(error.context.error as Record<string, any>),
  })
}

export const addGqlContexts = (scope: Scope, error: GqlError): void => {
  const { context } = error
  const { query, variables, errors } = context

  scope.setContext("query/graphql", { query })
  scope.setContext("errors", { errors: toFormattedString(toObject(errors)) })

  if (variables) {
    scope.setContext("variables", { variables: toFormattedString(variables) })
  }
}

export const addHttpContexts = (scope: Scope, error: HttpError): void => {
  const { context } = error
  const { query, variables, error: httpError } = context

  const toLoggableObject = (error: HttpContextError): Record<string, any> => {
    return {
      isFatalError: error.isFatalError,
      response: {
        status: error.response.status,
      },
    }
  }

  scope.setContext("query/graphql", { query })
  scope.setContext("error", {
    errors: toFormattedString(toLoggableObject(httpError)),
  })

  if (variables) {
    scope.setContext("variables", { variables: toFormattedString(variables) })
  }
}

export const addRestContexts = (scope: Scope, error: RestError): void => {
  const { context } = error
  const { endpoint, error: restError, variables } = context

  scope.setContext("endpoint", { endpoint })
  scope.setContext("error", { error: restError })

  if (variables) {
    scope.setContext("variables", { variables: toFormattedString(variables) })
  }
}

const addExpectedErrorContexts = (scope: Scope, _error: globalThis.Error) => {
  const error = getSentryError(_error)
  // the type expected by the "setLevel" method is incorrectly set by Sentry
  // if we are here, it means that we've failed to catch it gracefully and it
  // should be reported right away. This severity says that
  // I recommend that we configure our notifications around "Fatal" errors
  scope.setLevel(Severity.Fatal as any)

  if (error.type === ErrorType.Gql) {
    addGqlContexts(scope, error)
  }

  if (error.type === ErrorType.Http) {
    addHttpContexts(scope, error)
  }

  if (error.type === ErrorType.Rest) {
    addRestContexts(scope, error)
  }
}

type Props = {
  section: string
  context?: Context
  children: ReactNode
}

const ErrorBoundary: FC<Props> = ({ section, children, context }) => {
  const me = useMe()

  const addError = (scope: Scope, error: globalThis.Error): void => {
    try {
      // by the time we are here, we know for sure, as we generated the error
      // that the error.message _will_ be a JSON compliant string
      const isExpected = JSON.parse(error.message)?.type

      if (isExpected) {
        addExpectedErrorContexts(scope, error)
      } else {
        scope.setContext("error", { error })
      }
    } catch {
      scope.setContext("error", { error })
    }
  }

  return (
    <SentryErrorBoundary
      beforeCapture={(scope, error) => {
        scope.setTag("section", section)

        if (me) {
          scope.setContext("user", {
            user: toFormattedString(me),
          })
        }

        if (context?.governance) {
          scope.setContext("governance", {
            governance: toFormattedString(context.governance),
          })
        }

        if (context?.organization) {
          scope.setContext("organization", {
            organization: toFormattedString(context.organization),
          })
        }

        if (context?.proposal) {
          scope.setContext("proposal", {
            proposal: toFormattedString(context.proposal),
          })
        }

        if (context?.tallyProposal) {
          scope.setContext("tallyProposal", {
            tallyProposal: toFormattedString(context.tallyProposal),
          })
        }

        if (context?.account) {
          scope.setContext("account", {
            account: toFormattedString(context.account),
          })
        }

        if (context?.session) {
          scope.setContext("session", {
            session: toFormattedString({ address: context.session }),
          })
        }

        if (context?.chainId) {
          scope.setContext("chainId", { chainId: String(context.chainId) })
        }

        if (error) {
          addError(scope, error)
        }
      }}
      fallback={<ErrorBoundaryFallback h={24} w="full" />}
    >
      {children}
    </SentryErrorBoundary>
  )
}

type FallbackProps = {
  h: string | number
  w: string | number
}

const ErrorBoundaryFallback: FC<FallbackProps & FlexProps> = ({ h, w }) => {
  return (
    <Center bg="gray.50" h={h} my={3} pos="relative" w={w}>
      <Icon
        as={PurpleCircleIcon}
        h={10}
        pos="absolute"
        right={3}
        top={3}
        w={10}
      />
      <Icon
        as={GreenParalelogramIcon}
        bottom={1}
        h={20}
        left={3}
        pos="absolute"
        w={10}
      />
      <Text color="purple.900" textStyle="lg">
        Sorry! There was an error while loading this section
      </Text>
      <Icon
        as={WavesIcon}
        bottom={0}
        fill="transparent"
        h={20}
        pos="absolute"
        right={0}
        stroke="gray.400"
        w="xl"
      />
    </Center>
  )
}

export default ErrorBoundary
