import type { Scope } from "@sentry/nextjs"
import { createStandaloneToast } from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"
import type { GetServerSidePropsContext } from "next/types"
import { destroyCookie } from "nookies"

import { getSessionToken } from "common/helpers/cookies"
import theme from "theme"
import { ENDPOINTS } from "common/constants/endpoints"
import { jsonStringify } from "common/helpers/serialization"
import {
  getErrorMessage,
  getErrorMessageFromRestRequest,
  isFatalError,
  renderGqlErrorMessages,
  renderHttpErrorMessages,
} from "common/helpers/error"
import type {
  GqlError,
  HttpError,
  RestError,
} from "common/components/ErrorBoundary"
import {
  addGqlContexts,
  addHttpContexts,
  addRestContexts,
  ErrorType,
  Severity,
  toFormattedString,
} from "common/components/ErrorBoundary"
import type { AsyncError } from "common/hooks/useAsyncError"

export enum HttpErrorType {
  Unauthorized = 401,
  Internal = 500,
}

export enum GrpcErrorType {
  OK = 0,
  Canceled = 1,
  Unknown = 2,
  InvalidArgument = 3,
  DeadlineExceeded = 4,
  NotFound = 5,
  AlreadyExists = 6,
  PermissionDenied = 7,
  ResourceExhausted = 8,
  FailedPrecondition = 9,
  Aborted = 10,
  OutOfRange = 11,
  Unimplemented = 12,
  Internal = 13,
  Unavailable = 14,
  DataLoss = 15,
  Unauthenticated = 16,
}

type Extensions = {
  code: GrpcErrorType
  description?: string
  link?: string
  type?: string
}

type Error = {
  message: string
  path: string[]
  extensions?: Extensions
}

export type GqlContextError = Omit<Error, "extensions"> & {
  extensions: Extensions
  isFatalError: boolean
  message: string
}

export type HttpContextError = {
  response: Response
  isFatalError: boolean
}

export type GqlContext<Variables = Record<string, unknown>> = {
  query: string
  errors: GqlContextError[]
  variables?: Variables
}

export type TransactionContext = {
  error: Record<string, unknown>
}

export type ParsingContext = {
  error: Record<string, unknown>
  variables?: Record<string, unknown>
}

export type HttpContext<Variables = Record<string, unknown>> = {
  query: string
  error: HttpContextError
  variables?: Variables
}

export type GqlMessages = Partial<{
  titles: Partial<Record<GrpcErrorType, string>>
  descriptions: Partial<Record<GrpcErrorType, string>>
}>

export type HttpMessages = Partial<{
  titles: Partial<Record<HttpErrorType, string>>
  descriptions: Partial<Record<HttpErrorType, string>>
}>

type Json<Data> = {
  data: Data | null
  errors: Error[]
}

type Messages = { gql?: GqlMessages; http?: HttpMessages }

export type GqlRequestParams<Variables> = {
  query: string
  variables?: Variables
  onError?: (error: GqlError | HttpError) => void
  omittedErrors?: (GrpcErrorType | HttpErrorType)[]
  messages?: Messages
  context?: GetServerSidePropsContext
  endpoint?: string
  optionalHeaders?: object
  error?: {
    fatal?: {
      isSilent?: boolean
    }
    warning?: {
      isSilent?: boolean
    }
  }
}
const gqlRequest = <
  Data,
  Variables extends Record<string, unknown> = Record<string, unknown>,
>({
  query,
  variables,
  onError,
  messages,
  omittedErrors = [GrpcErrorType.InvalidArgument],
  context,
  endpoint = ENDPOINTS.dataPipeline(),
  error: errorConfig,
  optionalHeaders,
}: GqlRequestParams<Variables>): Promise<Data | null> => {
  const fatalErrors = [
    GrpcErrorType.ResourceExhausted,
    GrpcErrorType.Aborted,
    GrpcErrorType.OutOfRange,
    GrpcErrorType.Unimplemented,
    GrpcErrorType.Internal,
    GrpcErrorType.Unavailable,
    GrpcErrorType.DataLoss,
    GrpcErrorType.Unauthenticated,
    HttpErrorType.Internal,
  ]

  const getFormData = (query: string, variables: Variables) => {
    const formData = new FormData()
    const operations = {
      query,
      variables: { file: null },
    }
    const map = {
      1: ["variables.file"],
    }

    formData.append("operations", jsonStringify(operations))
    formData.append("map", jsonStringify(map))
    formData.append("1", variables?.file as File)

    return formData
  }

  const isFile = variables?.file && query.includes("mutation UploadFile")
  const apiKey = process.env.NEXT_PUBLIC_TALLY_API_KEY
  const sessionToken = getSessionToken(context)

  const headers =
    endpoint === ENDPOINTS.dataPipeline()
      ? {
          ...(isFile ? {} : { "Content-Type": "application/json" }),
          ...(sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}),
          ...optionalHeaders,
          "Api-Key": apiKey,
        }
      : { "Content-Type": "application/json" }

  return fetch(endpoint, {
    method: "POST",
    headers,
    body: isFile
      ? getFormData(query, variables)
      : jsonStringify({
          query,
          variables,
        }),
  })
    .then((response) => {
      if (!response.ok) {
        const isFatalError = (response: Response) => {
          return fatalErrors.some(
            (fatalError) => fatalError === response.status,
          )
        }

        const error: HttpError = {
          type: ErrorType.Http,
          context: {
            query,
            variables,
            error: {
              response,
              isFatalError: isFatalError(response),
            },
          },
        }

        // If 401 status code, then remove cookie and do a hard reload page
        if (error?.context?.error?.response?.status === 401) {
          destroyCookie(null, "token", { path: "/" })

          if (typeof window !== "undefined") {
            window.location.reload()
          }
        }

        onError?.(error)
        renderHttpErrorMessages(error, messages?.http)

        if (isFatalError(response)) {
          throw new Error(toFormattedString(error))
        } else {
          Sentry.captureException(
            new Error(toFormattedString(error)),
            (scope) => {
              scope.setLevel(Severity.Warning)
              addHttpContexts(scope as Scope, error)

              return scope
            },
          )
        }

        return
      }

      return response.json()
    })
    .then((json: Json<Data>) => {
      if (json?.errors && !json?.data) {
        const getErrors = (errors: Error[]): GqlContextError[] => {
          const isFatalError = (error: Error) => {
            if (error.extensions === undefined) return true
            const { extensions } = error

            return fatalErrors.some(
              (fatalError) => fatalError === extensions.code,
            )
          }

          const byFatalError = (a: GqlContextError, b: GqlContextError) => {
            // "soft errors" should come last
            return Number(a.isFatalError) - Number(b.isFatalError)
          }

          const byOmittedErrors = (error: GqlContextError) => {
            return !omittedErrors.includes(error.extensions.code)
          }

          return errors
            .map((error) => {
              const { extensions, message } = error

              // if no extension is found, we add it manually
              // with the value of internal server error
              // as discused here and proposed by Chris
              // agreed with Nico and Nicolas
              // https://tally-network.slack.com/archives/C019T4D2EQP/p1664380742015459?thread_ts=1664379185.556829&cid=C019T4D2EQP

              if (extensions === undefined) {
                return {
                  ...error,
                  isFatalError: true,
                  message: "internal server error",
                  extensions: {
                    code: GrpcErrorType.Internal,
                  },
                }
              }

              return {
                ...error,
                isFatalError: isFatalError(error),
                message,
                extensions,
              }
            })
            .sort(byFatalError)
            .filter(byOmittedErrors)
        }

        const errors = getErrors(json.errors)
        const error: GqlError = {
          type: ErrorType.Gql,
          context: {
            query,
            errors,
            variables,
          },
        }

        onError?.(error)

        // if all errors are filtered out, then we early return the data
        if (errors.length === 0) return json.data

        if (isFatalError(error)) {
          const isSilent = errorConfig?.fatal?.isSilent ?? false

          if (!isSilent && typeof window !== "undefined") {
            renderGqlErrorMessages(error, messages?.gql)
          }

          throw new Error(toFormattedString(error))
        } else {
          const isSilent = errorConfig?.warning?.isSilent ?? false

          if (!isSilent && typeof window !== "undefined") {
            renderGqlErrorMessages(error, messages?.gql)
          }

          Sentry.captureException(
            new Error(toFormattedString(error)),
            (scope) => {
              scope.setLevel(Severity.Warning)
              addGqlContexts(scope as Scope, error)

              return scope
            },
          )
        }
      }

      return json?.data
    })
}

export type RestContext<
  Variables = Record<string, unknown>,
  Headers = Record<string, unknown>,
> = {
  error: string
  endpoint: string
  variables?: Variables
  headers?: Headers
}

const restRequest = async <
  Data,
  Variables extends Record<string, unknown> = Record<string, unknown>,
  Headers extends Record<string, unknown> = Record<string, unknown>,
>({
  method = "GET",
  endpoint,
  headers,
  variables,
  onError,
  error: errorConfig,
}: {
  method?: "GET" | "POST" | "PUT" | "DELETE"
  endpoint: string
  variables?: Variables
  headers?: Headers
  onError?: (error: RestError) => void
  error?: {
    shouldOmit?: boolean
    fatal?: {
      isFatal: boolean
      asyncError: AsyncError
    }
    warning?: {
      isSilent?: boolean
    }
  }
}): Promise<Data> => {
  return (
    fetch(endpoint, {
      method,
      headers: {
        ...(headers ? headers : {}),
        "Content-Type": "application/json",
      },
      ...(variables
        ? {
            body: jsonStringify({
              ...variables,
            }),
          }
        : {}),
    })
      .then(async (res) => {
        if (!res.ok) {
          const json = await res.json()
          const message = json.message as any

          if (message) {
            throw new Error(message)
          }

          // TODO: Get the message (string) from different responses
          // This case is using OZ Relayer format but should be
          // flexible to adapt to other cases
          const errorMessage = getErrorMessageFromRestRequest(json)
          throw new Error(errorMessage)
        }

        return res
      })
      .then((res) => res.json())
      // https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
      // https://github.com/facebook/react/issues/14981
      .catch((_error: Error) => {
        // TODO(@niconahi): move toast styling to theme so that we have
        //                  aligned styles on toasts
        const { toast } = createStandaloneToast({
          theme,
        })

        const errorMessage = getErrorMessage(_error)
        const error: RestError = {
          type: ErrorType.Rest,
          context: {
            error: errorMessage,
            endpoint,
            variables,
          },
        }

        // we early return if it's ommited
        if (errorConfig?.shouldOmit) return

        // if we want the error to bubble to the next ErrorBoundary
        // you need to set "errorConfig.fatal.isFatal" to "true"
        if (errorConfig?.fatal?.isFatal) {
          // for the bubble to work correctly, we need the
          // "asyncError" helpers
          const { asyncError } = errorConfig.fatal
          asyncError(toFormattedString(error))
        } else {
          // by default, we show a toast for the warning
          const isSilent = errorConfig?.warning?.isSilent ?? false

          Sentry.captureException(
            new Error(toFormattedString(error)),
            (scope) => {
              scope.setLevel(Severity.Warning)

              addRestContexts(scope as Scope, error)

              if (!isSilent && typeof window !== "undefined") {
                toast({
                  status: "warning",
                  title: "Error",
                  description: errorMessage,
                })
              }

              return scope
            },
          )
        }

        onError?.(error)
      })
  )
}

export const fetcher = {
  gql: gqlRequest,
  rest: restRequest,
}
