import { stringify } from "query-string"

import { getChainByReference } from "web3/helpers/findChain"

export const ENDPOINTS = {
  auth: {
    register: (): string => "/auth/register",
    login: (): string => "/auth/login",
    forgot: (): string => "/auth/forgot",
    reset: (userId: string, token: string): string =>
      `/auth/reset/${userId}/${token}`,
  },

  twitter: {
    auth: (redirectUrl: string): string => {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl)

      return `/auth/twitter?next=${encodedRedirectUrl}`
    },
    link: (redirectUrl: string, authToken: string): string => {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl)

      return `/link/twitter?token=${authToken}&next=${encodedRedirectUrl}`
    },
  },
  user: {
    delegate: (userId: string): string => `/user/${userId}/delegate`,
    avatar: (): string => "/user/profile/avatar",
    address: (): string => "/user/address",
    profile: {
      own: (): string => "/user/profile",
      id: (tallyId: string): string => `/user/profile/${tallyId}`,
      list: (): string => "/user/profiles/by/address",
      single: (address: string): string =>
        `/user/profiles/by/address?addresses[]=${address.toLocaleLowerCase()}`,
    },
  },
  analytics: {
    action: (): string =>
      `${process.env.NEXT_PUBLIC_TALLY_API_URL}/analytics/action`,
  },
  notifications: {
    all: (): string => "/notifications/subscriptions",
    governance: (governance: string): string =>
      `/notifications/subscription/${governance}`,
    settings: (): string => "/notifications/settings",
  },
  link: {
    address: (): string => "/link/address",
    legacy: (): string => "/link/address/legacy",
    discord: (redirectUrl: string, authToken: string): string => {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl)

      return `/link/discord?token=${authToken}&next=${encodedRedirectUrl}`
    },
  },
  export: {
    members: (organizationId: string): string =>
      `/export/organization/${organizationId}/members`,
  },
  graphCms: (): string => process.env.NEXT_PUBLIC_TALLY_GRAPH_CMS,
  dataPipeline: (): string => `${process.env.NEXT_PUBLIC_TALLY_API_URL}/query`,
}

export const EXTERNAL_ENDPOINTS = {
  uniswap: (): string =>
    "https://subgraph.satsuma-prod.com/e417bc3329b1/tally/community/uniswap-v3-mainnet/version/0.0.1/api",
  medium: (): string =>
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/tally-blog",
  mirror: (): string =>
    "https://api.rss2json.com/v1/api.json?rss_url=https://tally.mirror.xyz/feed/atom/",
  poap: (address: string): string =>
    `https://api.poap.tech/actions/scan/${address}`,
  graphCms: (): string =>
    "https://api-ca-central-1.graphcms.com/v2/ckk4nk975w13c01zdg8lzehvr/master",
  coinGecko: (contractAddress: string): string => {
    const query = stringify({
      // eslint-disable-next-line camelcase
      contract_addresses: contractAddress,
      // eslint-disable-next-line camelcase
      vs_currencies: "usd",
    })

    return `/simple/token_price/ethereum?${query}`
  },
  fourByte: (hexSignature: string): string =>
    `https://www.4byte.directory/api/v1/signatures/?hex_signature=${hexSignature}`,
  etherscan: (chainReference: number): string => {
    const foundChain = getChainByReference(chainReference)

    if (foundChain?.blockExplorer?.url) {
      return foundChain?.blockExplorer?.url
    }

    return "https://etherscan.io"
  },
}
