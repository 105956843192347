import type {
  ContractReceipt,
  ContractTransaction,
} from "@ethersproject/contracts"

import type { MetamaskError } from "governance/types/metamask"

export type TransactionStateNone = {
  state: TransactionState.None
}

export type TransactionStateBegin = {
  state: TransactionState.Begin
}

export type TransactionStateMining<Metadata> = {
  state: TransactionState.Mining
  metadata: Metadata
  transaction: ContractTransaction
}

export type TransactionStateSuccess<Metadata> = {
  state: TransactionState.Success
  receipt: ContractReceipt
  metadata: Metadata
  transaction: ContractTransaction
}

export type TransactionStateFail<Metadata> = {
  state: TransactionState.Fail
  error: string | MetamaskError
  metadata: Metadata
}

export enum TransactionState {
  Begin = "Begin",
  None = "None",
  Fail = "Fail",
  Mining = "Mining",
  Success = "Success",
  Exception = "Exception",
  PendingSafe = "PendingSafe", // New state for waiting on Safe transactions
}

type TransactionStatePendingSafe<Metadata> = {
  state: TransactionState.PendingSafe
  metadata: Metadata
  safeTxHash: string // You might want to include the Safe transaction hash here
}

export type TransactionStatus<Metadata> =
  | TransactionStateNone
  | TransactionStateBegin
  | TransactionStateMining<Metadata>
  | TransactionStateSuccess<Metadata>
  | TransactionStateFail<Metadata>
  | TransactionStatePendingSafe<Metadata>

type TransactionOnFail<Metadata> = (
  state: TransactionStateFail<Metadata>,
) => void
export type TransactionOnMining<Metadata> = (
  state: TransactionStateMining<Metadata>,
) => void
export type TransactionOnSuccess<Metadata> = (
  state: TransactionStateSuccess<Metadata>,
) => void

export type TransactionOn<Metadata> = Partial<{
  [TransactionToastState.Fail]: TransactionOnFail<Metadata>
  [TransactionToastState.Mining]: TransactionOnMining<Metadata>
  [TransactionToastState.Success]: TransactionOnSuccess<Metadata>
}>

export type TransactionFunction = () => Promise<ContractTransaction>

export enum TransactionToastState {
  Fail = "Fail",
  Mining = "Mining",
  Success = "Success",
  PendingSafe = "PendingSafe",
}

type Titles = {
  [TransactionToastState.Fail]: string
  [TransactionToastState.Mining]: string
  [TransactionToastState.Success]: string
}

export type TransactionToastMessages = {
  titles: Titles
}
