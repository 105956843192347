import type { GetServerSidePropsContext } from "next"
import nookies, { parseCookies } from "nookies"

enum Cookie {
  Token = "token",
  Features = "features",
  NewLayout = "newlayout",
}

const getCookie = (
  context: GetServerSidePropsContext,
  key: Cookie,
): undefined | string => {
  const cookies = nookies.get(context)
  const cookie = cookies?.[key]

  if (!cookie) return undefined

  return cookie
}

export const getSessionToken = (
  context?: GetServerSidePropsContext,
): string | undefined => {
  if (context) {
    return context ? getCookie(context, Cookie.Token) : undefined
  } else {
    const cookies = parseCookies()

    return cookies?.token ?? undefined
  }
}
