const alert = {
  // TODO(@nicolas): It is suggested to use StyleProps type but it doesn't recognize the `status` field
  baseStyle: (props: { status: string }) => {
    const { status } = props

    const infoBase = status === "info" && {
      container: {
        background: "#F7F4FF",
        border: "1px solid #BEADFF",
        borderRadius: "10px",
        color: "#5243D9",
      },
    }

    return {
      ...infoBase,
    }
  },
}

export default alert
