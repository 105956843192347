import type { Signer } from "@ethersproject/abstract-signer"
import type { Provider } from "@ethersproject/providers"

import type { Multicall2 } from "contracts/bindings/Multicall2"
import { Multicall2__factory as MulticallFactory } from "contracts/bindings/factories/Multicall2__factory"

export const getMulticall = (
  address: string,
  signerOrProvider: Signer | Provider,
): Multicall2 => MulticallFactory.connect(address, signerOrProvider)
